import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failurelistStudentExamPractice,
  successlistStudentExamPractice,
} from "./ListStudentExamPracticeSlice";
import {
  failureaddStudentExamPractice,
  successaddStudentExamPractice,
} from "./AddStudentExamPracticeSlice";
import {
  failureEditStudentExamPractice,
  successEditStudentExamPractice,
} from "./EditStudentExamPracticeSlice";
import { IStudentPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";

// list
const ListStudentExamPracticeApi = () => {
  return api.get("/v1/api/exam/get");
};

export function* ListStudentExamPracticeCall() {
  try {
    const response: AxiosResponse = yield call(ListStudentExamPracticeApi);
    yield put(successlistStudentExamPractice(response.data));
  } catch (error) {
    yield put(failurelistStudentExamPractice(error));
  }
}

// add
const AddStudentExamPracticeApi = (
  action: PayloadAction<IStudentPracticeExam>
) => {
  return api.post("/v1/api/exam/studettest", action.payload);
};

export function* AddStudentExamPracticeCall(
  action: PayloadAction<IStudentPracticeExam>
) {
  try {
    const response: AxiosResponse = yield call(
      AddStudentExamPracticeApi,
      action
    );
    yield put(successaddStudentExamPractice(response.data));
  } catch (error) {
    yield put(failureaddStudentExamPractice(error));
  }
}

// edit
const EditStudentExamPracticeApi = (
  action: PayloadAction<IStudentPracticeExam>
) => {
  return api.put("/v1/api/exam/skiptest", action.payload);
};

export function* EditStudentExamPracticeCall(
  action: PayloadAction<IStudentPracticeExam>
) {
  try {
    const response: AxiosResponse = yield call(
      EditStudentExamPracticeApi,
      action
    );
    yield put(successEditStudentExamPractice(response.data));
  } catch (error) {
    yield put(failureEditStudentExamPractice(error));
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";

export const DeletePracticeExamSlice = createSlice({
  name: "DeletePracticeExam",
  initialState: {} as IReduxState<IPracticeExam>,
  reducers: {
    deletePracticeExam: (state, _) => {
      state.isLoading = true;
    },
    successdeletePracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeletePracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeletePracticeExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deletePracticeExam,
  failuredeletePracticeExam,
  successdeletePracticeExam,
  clearDeletePracticeExamResponse,
} = DeletePracticeExamSlice.actions;

export default DeletePracticeExamSlice.reducer;

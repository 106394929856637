import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStudentPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";

export const PopupStudentExamPracticeSlice = createSlice({
  name: "PopupStudentExamPracticeSlice",
  initialState: {} as IReduxState<IStudentPracticeExam>,
  reducers: {
    PopupStudentExamPractice: (
      state,
      _: PayloadAction<IStudentPracticeExam>
    ) => {
      state.isLoading = true;
    },
    successPopupStudentExamPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurePopupStudentExamPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },

    clearPopupStudentExamPracticeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  PopupStudentExamPractice,
  failurePopupStudentExamPractice,
  successPopupStudentExamPractice,
  clearPopupStudentExamPracticeResponse,
} = PopupStudentExamPracticeSlice.actions;

export default PopupStudentExamPracticeSlice.reducer;

import { FunctionComponent, memo, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { IQuestions } from "./IQuestions";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addQuestions } from "../../Redux/Slices/Questions/AddQuestionsSlice";
import { EditQuestions } from "../../Redux/Slices/Questions/EditQuestionsSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { method } from "lodash";
import { DevTool } from "@hookform/devtools";
import { error } from "console";
import { listReadingPrompt } from "../../Redux/Slices/ReadingPrompt/ListReadingPromptSlice";
import Autocompleted from "../../Components/Autocomplete/Autocompleted";
interface QuestionsFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IQuestions | {};
  setInitialValues: React.Dispatch<React.SetStateAction<IQuestions>>;
}

const QuestionsForm: FunctionComponent<QuestionsFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { data: listQuestions } = useAppSelector(
    (state) => state.ListQuestionTypeSlice
  );

  const { data: listReadingPrompts } = useAppSelector(
    (state) => state.ReadingPromptSlice
  );
  const QuestionList = Array.isArray(listQuestions?.data)
    ? listQuestions?.data
        ?.filter((role) => role.status !== false) // Filter out roles where status is false
        .map((listQuestions) => ({
          label: listQuestions?.name!,
          value: listQuestions.id,
        }))
    : [];

  const ReadingPromptList = useMemo(() => {
    return Array.isArray(listReadingPrompts?.data)
      ? listReadingPrompts?.data?.map((listQuestions) => ({
          label: listQuestions.prompt,
          value: listQuestions.id,
        }))
      : [];
  }, [listReadingPrompts]); // Dependency ensures recalculation when Redux state changes

  // const ReadingPromptList = Array.isArray(listReadingPrompts?.data)
  //   ? listReadingPrompts?.data?.map((listQuestions) => ({
  //       label: listQuestions?.prompt!,
  //       value: listQuestions.id,
  //     }))
  //   : [];

  useEffect(() => {
    console.log("Updated ReadingPromptList:", ReadingPromptList);
  }, [ReadingPromptList]);

  console.log("ReadingPromptList", ReadingPromptList);

  const optionSchema = z.object({
    optionname: z.string(),
    optionstatus: z.boolean().optional(),
    id: z.number().optional(),
  });
  // Define allowed image MIME types
  const allowedMimeTypes = ["image/jpeg", "image/png", "image/gif"];

  // Define the maximum file size (e.g., 5MB)
  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 5 MB in bytes

  const optionSchemaurl = z.object({
    optionurlname: z.any(),
    optionurlstatus: z.boolean().optional(),
    id: z.number().optional(),
  });

  const userSchema = z
    .object({
      id: z.number().optional(),
      question_url: z
        .union([
          z
            .instanceof(File)
            .refine((file) => !!file, {
              message: "File is required.",
            })
            .refine((file) => allowedMimeTypes.includes(file.type), {
              message:
                "Invalid file type. Only JPEG, PNG, and GIF files are allowed.",
            })
            .refine((file) => file.size <= MAX_FILE_SIZE, {
              message: "File size should not exceed 1MB.",
            }),

          z.string().url().optional(),
        ])
        .optional(),
      questionType: z.number({
        required_error: "Please select question type",
        invalid_type_error: "Please select question type",
      }),
      grouped_question: z.number().optional().nullable(),
      prompt: z.string().optional().nullable(),

      question: z
        .string({
          required_error: "Please enter question",
          invalid_type_error: "Please enter question",
        })
        .min(1, { message: "Please enter question" }),

      instruction: z.string().optional().nullable(),
      explanation: z.string().optional().nullable(),

      difficulty_level: z
        .string({
          required_error: "Please select difficulty level",
          invalid_type_error: "Please select difficulty level",
        })
        .min(1, { message: "Please select difficulty level" }),

      option_type: z.string().nullable().optional(),

      main: z.boolean().optional(),

      option: z
        .array(optionSchema)
        // .min(4, { message: "Please add minimum four options" })
        .optional(),

      option_url: z
        .array(optionSchemaurl)
        // .min(4, { message: "Please add minimum four option images" })
        .optional(),
      Status: z.boolean({
        message: "Please select the status",
      }),
    })
    .superRefine((data, ctx) => {
      if (
        foundQuestion?.label?.toLowerCase() === "writing" &&
        !data.question_url
      ) {
        ctx.addIssue({
          path: ["question_url"],
          code: z.ZodIssueCode.custom,
          message: "Please upload question url",
        });
      }

      if (
        foundQuestion?.label?.toLowerCase() !== "writing" &&
        !data.option_type
      ) {
        ctx.addIssue({
          path: ["option_type"],
          code: z.ZodIssueCode.custom,
          message: "Please select option type",
        });
      }
      // Validate options if option_type is Text
      if (data.option_type?.toLowerCase() === "text") {
        if (!data.option || data.option.length < 4) {
          ctx.addIssue({
            path: ["option"],
            code: z.ZodIssueCode.custom,
            message: "Please add minimum four options",
          });
        }
        if (!data.option?.some((val) => !!val.optionstatus)) {
          ctx.addIssue({
            path: ["option"],
            code: z.ZodIssueCode.custom,
            message: "please select atleast one correct answer",
          });
        }
      }
      // Validate option_url if option_type is not Text
      else if (data.option_type && data.option_type?.toLowerCase() !== "text") {
        if (!data.option_url || data.option_url.length < 4) {
          ctx.addIssue({
            path: ["option_url"],
            code: z.ZodIssueCode.custom,
            message: "Please add minimum four option images",
          });
        }
        if (!data.option_url?.some((val) => !!val.optionurlstatus)) {
          ctx.addIssue({
            path: ["option_url"],
            code: z.ZodIssueCode.custom,
            message: "please select atleast one correct answer",
          });
        }
      }

      if (
        foundQuestion?.label?.toLowerCase() === "reading comprehension" &&
        data.main === undefined
      ) {
        ctx.addIssue({
          path: ["main"],
          code: z.ZodIssueCode.custom,
          message: "Please select prompt type",
        });
      }
      if (data?.main === false && !data.grouped_question) {
        ctx.addIssue({
          path: ["grouped_question"],
          code: z.ZodIssueCode.custom,
          message: "Please select the prompt",
        });
      }
    });

  // edit value to set

  const transformInitialValues = (initialValues: any) => {
    // Extract the option_type from the first option (assuming all have the same type)
    const option_type =
      initialValues?.options?.length > 0
        ? initialValues?.options[0].option_type
        : null;

    // Transform the options array to match the IOption interface
    const option = initialValues?.options?.map((opt: any) => ({
      optionname: opt.option || "",
      optionstatus: opt.answer,
      id: opt.id,
    }));

    // Transform the options array to match the IOptionurl interface for option_url
    const option_url = initialValues?.options?.map((opt: any) => ({
      optionurlname: opt.option_url || "",
      optionurlstatus: opt.answer,
      id: opt.id,
    }));

    // Return the transformed structure
    return {
      id: initialValues.id,
      prompt: initialValues.prompt,
      questionType: initialValues.questionType,
      main: initialValues?.main,
      grouped_question: initialValues?.grouped_question,
      question_url:
        initialValues.question_url != null
          ? initialValues.question_url
          : undefined,
      question: initialValues.question,
      instruction: initialValues.instruction,
      explanation: initialValues.explanation,
      difficulty_level: initialValues.difficulty_level,
      option_type, // Extracted option_type
      option, // Transformed options array
      option_url, // Transformed option_url array
      Status: initialValues.Status,
    };
  };

  // Usage

  const transformedData = transformInitialValues(props.initialValues);

  const methods = useForm<IQuestions>({
    defaultValues: {
      ...transformedData,
      main: transformedData.main ?? undefined,
      Status: transformedData.Status ?? true,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const selectedOptionType = useWatch({
    control: methods.control,
    name: "option_type",
  });
  const questionTypereading = useWatch({
    control: methods.control,
    name: "questionType",
  });

  const foundQuestion = QuestionList?.find(
    (item) => item.value === questionTypereading
  );

  console.log(foundQuestion);

  const grouped_questionReading = useWatch({
    control: methods.control,
    name: "main",
  });

  const onSubmit: SubmitHandler<IQuestions> = (val) => {
    const transformedVal: any = {
      questionType: val.questionType,
      question_url: val.question_url,
      prompt: val.prompt,
      question: val.question,
      main: val?.main,
      grouped_question: val?.grouped_question,
      instruction: val.instruction,
      explanation: val.explanation,
      difficulty_level: val.difficulty_level,
      source_type: "manual",
      Status: val.Status,
      option_url:
        val.option_type?.toLowerCase() === "text"
          ? val?.option?.map((opt) => ({
              option_url: null, // Set to null, adjust based on requirements
            }))
          : val?.option_url?.map((opt) => ({
              option_url: opt.optionurlname, // Use option URL for URL-type options
            })), // Conditionally set options based on option_type
      options:
        val.option_type?.toLowerCase() === "text"
          ? val?.option?.map((opt) => ({
              // id: opt.id,
              option_type: val.option_type, // Set option_type to "Text"
              option: opt.optionname,
              answer: opt.optionstatus,
            }))
          : val?.option_url?.map((opt) => ({
              // id: opt.id,
              option_type: val.option_type, // Assuming option_type is set to "URL" or another type
              option: null, // Set option to null for non-text options
              answer: opt.optionurlstatus,
            })),
    };
    const transformedValedit: any = {
      id: val.id,
      questionType: val.questionType,
      question_url: val.question_url,
      prompt: val.prompt,
      question: val.question,
      main: val?.main,
      grouped_question: val?.main === true ? null : val?.grouped_question,
      instruction: val.instruction,
      explanation: val.explanation,
      difficulty_level: val.difficulty_level,
      source_type: "manual",
      Status: val.Status,

      option_url:
        val.option_type?.toLowerCase() === "text"
          ? val?.option?.map((opt) => ({
              option_url: null, // Set to null, adjust based on requirements
            }))
          : val?.option_url?.map((opt) => ({
              option_url: opt.optionurlname, // Use option URL for URL-type options
            })), // Conditionally set options based on option_type

      // Conditionally set options based on option_type
      options:
        val.option_type?.toLowerCase() === "text"
          ? val?.option?.map((opt) => {
              if (opt.id !== 0) {
                return {
                  id: opt.id,
                  option_type: val.option_type, // Set option_type to "Text"
                  option: opt.optionname,
                  answer: opt.optionstatus,
                };
              } else {
                return {
                  option_type: val.option_type, // Set option_type to "Text"
                  option: opt.optionname,
                  answer: opt.optionstatus,
                };
              }
            })
          : val?.option_url?.map((opt) => {
              if (opt.id !== 0) {
                return {
                  id: opt.id,
                  option_type: val.option_type, // Assuming option_type is set to "URL" or another type
                  option: null, // Set option to null for non-text options
                  answer: opt.optionurlstatus,
                };
              } else {
                return {
                  option_type: val.option_type, // Assuming option_type is set to "URL" or another type
                  option: null, // Set option to null for non-text options
                  answer: opt.optionurlstatus,
                };
              }
            }),
    };

    if (val.id) {
      dispatch(
        EditQuestions({
          ...transformedValedit,
          option_url:
            val.option_type?.toLowerCase() === "image"
              ? transformedValedit.option_url
              : null,
          options:
            foundQuestion?.label?.toLowerCase() === "writing"
              ? null
              : transformedValedit.options,
        })
      );
    } else {
      dispatch(
        addQuestions({
          ...transformedVal,
          option_url:
            val.option_type?.toLowerCase() === "image"
              ? transformedVal.option_url
              : null,

          options:
            foundQuestion?.label?.toLowerCase() === "writing"
              ? null
              : transformedVal.options,
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, []);

  // Status list
  const DifficultyLevel = [
    {
      label: "Easy",
      value: "easy",
    },
    {
      label: "Medium",
      value: "moderate",
    },

    {
      label: "Hard",
      value: "difficult",
    },
  ];

  // text
  const [instructionInput, setInstructionInput] = useState<string>("");
  const [instructions, setInstructions] = useState<
    { optionname: string; optionstatus: boolean; id: number | null }[]
  >([]);
  const [deletedIds, setDeletedIds] = useState<number[]>([]); // New state to store deleted IDs
  const { setValue, getValues } = methods;

  const handleAddInstruction = () => {
    const currentInstructions = getValues("option") || []; // Ensure it's an array

    if (instructionInput.trim() && currentInstructions.length < 5) {
      // Check if there are deleted IDs to reuse, otherwise generate a new ID
      const newId = deletedIds.length > 0 ? deletedIds.pop()! : 0; // Ensure newId is always a number

      const updatedInstructions = [
        ...currentInstructions,
        { optionname: instructionInput, optionstatus: false, id: newId },
      ];

      setInstructions(updatedInstructions); // Update local state
      setValue("option", updatedInstructions, { shouldValidate: true }); // Update instructions field
      setInstructionInput(""); // Clear input field

      setDeletedIds([...deletedIds]); // Update deleted IDs state after reusing one
    }
  };

  const handleDeleteInstruction = (index: number) => {
    const currentInstructions = getValues("option")!;
    const instructionToDelete = currentInstructions[index];

    // Store the deleted ID
    setDeletedIds((prevIds: any) => [...prevIds, instructionToDelete.id]);

    const updatedInstructions = currentInstructions?.filter(
      (_, i) => i !== index
    );
    setInstructions(updatedInstructions); // Update local state
    setValue("option", updatedInstructions); // Update instructions field
    methods.trigger("option"); // Force re-render to reflect changes
  };

  const handleCheckboxChange = (index: number) => {
    const currentInstructions = getValues("option")!;
    const updatedInstructions = currentInstructions?.map((instruction, i) => ({
      ...instruction,
      optionstatus: i === index ? true : false, // Set clicked option to true, others to false
    }));
    setInstructions(updatedInstructions); // Update local state
    setValue("option", updatedInstructions, { shouldValidate: true }); // Update instructions field
  };

  // image

  const [imageInput, setImageInput] = useState<File | null>(null); // Store the file object
  const [tempImg, setTempImg] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  // const [image, setImage] = useState<
  //   { optionurlname: string; optionurlstatus: boolean; id: number | null }[]
  // >([]);
  const [deletedImageIds, setDeletedImageIds] = useState<number[]>([]); // Store deleted IDs

  const handleAddImage = () => {
    const currentUrl = getValues("option_url") || [];
    if (imageInput && currentUrl.length < 5) {
      // If there are deleted IDs, reuse the last one; otherwise, create a new ID
      const newId = deletedImageIds.length > 0 ? deletedImageIds.pop()! : 0;

      const updatedImages = [
        ...currentUrl,
        {
          option_url: imageInput,
          optionurlname: imageInput,
          optionurlstatus: false,
          id: newId, // Set the id, either reused or newly generated
        },
      ];

      // setImage(updatedImages); // Update local state with image URLs
      setValue("option_url", updatedImages, { shouldValidate: true }); // Update form field
      setImageInput(null); // Clear input field
      setDeletedImageIds(deletedImageIds); // Update the deleted IDs array after reusing one
      setTempImg(null);
    }
  };

  const handleDeleteImage = (index: number) => {
    const currentUrl = getValues("option_url")!;
    const deletedImage = currentUrl[index];

    const updatedImages = currentUrl?.filter((_, i) => i !== index);

    // setImage(updatedImages); // Update local state
    setValue("option_url", updatedImages); // Update form field
    methods.trigger("option_url"); // Force re-render to reflect changes

    // Check if deletedImage.id is not null before adding it to deletedImageIds
    if (deletedImage.id !== null) {
      setDeletedImageIds([...deletedImageIds, deletedImage.id]);
    }
  };

  const handleCheckboxChangeUrl = (index: number) => {
    const currentUrl = getValues("option_url")!;
    const updatedImages = currentUrl?.map((instructionUrl, i) => ({
      ...instructionUrl,
      optionurlstatus: i === index ? true : false, // Set clicked option to true, others to false
    }));

    // setImage(updatedImages); // Update local state
    setValue("option_url", updatedImages, { shouldValidate: true }); // Update form field
  };

  const Optionstype = [
    {
      label: "Text",
      value: "Text",
    },

    {
      label: "Image",
      value: "Image",
    },
  ];

  const imageFile: any = methods.watch("question_url");

  const QuestionTypeSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  const ReadingComprehensionQuestionSatus = [
    {
      label: "Main Prompt",
      value: true,
    },

    {
      label: "Sub Prompt",
      value: false,
    },
  ];

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_double_input"
      >
        <DevTool control={methods.control} />
        <TextInputFieldC name="question" label="Question" type="text" />
        <TextField
          fullWidth
          type="file"
          // size="small"
          label="Question Image"
          InputLabelProps={{ shrink: true }} // Correct way to shrink label
          onChange={(e: any) => {
            const file = e.target.files ? e.target.files[0] : null;

            methods.setValue("question_url", file, {
              shouldValidate: true,
            });
          }}
          error={!!methods.formState.errors.question_url}
          helperText={
            !!methods.formState.errors.question_url
              ? String(
                  methods.formState.errors.question_url.message ===
                    "Input not instance of File"
                    ? "Please upload question image"
                    : methods.formState.errors.question_url.message
                )
              : ""
          }
        />
        <Select
          name="difficulty_level"
          label="Difficulty Level"
          options={DifficultyLevel}
          value={
            DifficultyLevel?.find(
              (role) => role.value! === methods.getValues("difficulty_level")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("difficulty_level", val?.value!, {
              shouldValidate: true,
            });
          }}
        />
        <TextInputFieldC name="explanation" label="Explanation" type="text" />
        <Select
          name="questionType"
          label="Question Type"
          options={QuestionList ?? []}
          value={
            QuestionList?.find(
              (list) => list.value === methods.getValues("questionType")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("questionType", val?.value, {
              shouldValidate: true,
            });
          }}
        />
        {foundQuestion?.label?.toLowerCase() !== "writing" ? (
          <Select
            name="option_type"
            label="Select Option Type"
            options={Optionstype ?? []}
            value={
              Optionstype?.find(
                (list) =>
                  list.value.toLowerCase() ===
                  methods.getValues("option_type")?.toLowerCase()
              ) ?? null
            }
            onChange={(_, value) => {
              const val = value as AutocompleteOption;
              methods.setValue("option_type", val?.value, {
                shouldValidate: true,
              });
              if (methods.getValues("id")) {
                const selectedOption = methods
                  .watch("option_type")
                  ?.toLowerCase(); // Normalize case

                if (selectedOption === "text") {
                  methods.setValue("option", [], { shouldValidate: false });
                } else if (selectedOption === "image") {
                  methods.setValue("option_url", [], { shouldValidate: false });
                }
              }
            }}
          />
        ) : null}

        {/* {questionTypereading === 10 && (
          <div style={{ width: "100%" }}>
            <ToggleButtonGroup
              value={methods.getValues("main")}
              sx={{
                width: "100%",
                height: "70%",
                border: methods.formState.errors.main ? "1px soild red" : "0px",
              }}
              exclusive
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                newAlignment: boolean
              ) => {
                if (newAlignment !== null) {
                  methods.setValue("main", newAlignment);
                  methods.clearErrors("main"); // Clear validation errors for the "main" field
                }
              }}
              aria-label="Platform"
            >
              <ToggleButton
                value={true}
                sx={{
                  width: "100%",
                }}
              >
                Main Prompt
              </ToggleButton>
              <ToggleButton value={false} sx={{ width: "100%" }}>
                Sub Prompt
              </ToggleButton>
            </ToggleButtonGroup>
            <FormHelperText error={!!methods.formState.errors.main}>
              <div style={{ marginLeft: "5px", fontSize: "10px" }}>
                {methods.formState.errors.main?.message}
              </div>
            </FormHelperText>
          </div>
        )} */}
        {foundQuestion?.label?.toLowerCase() === "reading comprehension" && (
          <div style={{ width: "100%" }}>
            <FormControl
              component="fieldset"
              error={!!methods.formState.errors.main}
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between", // Optional for spacing
                }}
              >
                {/* Label */}
                <FormLabel
                  component="legend"
                  sx={{ marginRight: "16px", fontWeight: "bold" }}
                >
                  Prompt Type:
                </FormLabel>

                {/* Radio Buttons */}
                <RadioGroup
                  row // Arranges radio buttons in a row
                  value={methods.getValues("main")}
                  onChange={(event) => {
                    const newValue = event.target.value === "true"; // Convert string to boolean
                    methods.setValue("main", newValue);
                    methods.clearErrors("main"); // Clear validation errors for the "main" field
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Main"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Sub"
                  />
                </RadioGroup>
              </Box>

              {/* Error Message */}
              <FormHelperText sx={{ marginLeft: "0px" }}>
                <div style={{ marginLeft: "5px", fontSize: "10px" }}>
                  {methods.formState.errors.main?.message}
                </div>
              </FormHelperText>
            </FormControl>
          </div>
        )}

        {grouped_questionReading === false && (
          // <Select
          //   name="grouped_question"
          //   label="Select the prompt"
          //   options={ReadingPromptList ?? []}
          //   value={
          //     ReadingPromptList?.find(
          //       (list) => list.value === methods.getValues("grouped_question")
          //     ) ?? null
          //   }
          //   onChange={(_, value) => {
          //     const val = value as AutocompleteOption;
          //     methods.setValue("grouped_question", val?.value, {
          //       shouldValidate: true,
          //     });
          //     methods.setValue("prompt", val?.label, {
          //       shouldValidate: true,
          //     });
          //   }}
          // />

          <Autocompleted
            name="grouped_question"
            label="Select the prompt"
            options={ReadingPromptList ?? []}
            value={
              ReadingPromptList?.find(
                (list) => list.value === methods.getValues("grouped_question")
              ) ?? null
            }
            onChange={(selected) => {
              methods.setValue("grouped_question", selected?.value, {
                shouldValidate: true,
              });
              console.log(selected?.value);

              methods.setValue("prompt", selected?.label, {
                shouldValidate: true,
              });
            }}
          ></Autocompleted>
        )}

        <TextAreaInputField
          name="prompt"
          label={grouped_questionReading === false ? "View Prompt" : "Prompt"}
          type="text"
          rows={3}
          readOnly={grouped_questionReading === false ? true : false}
        />

        <TextAreaInputField
          name="instruction"
          label="Instruction"
          type="text"
          rows={3}
        />
        <Select
          name="Status"
          label="Status"
          options={QuestionTypeSatus}
          value={
            QuestionTypeSatus?.find(
              (role) => role.value === methods.getValues("Status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("Status", val?.value, { shouldValidate: true });
          }}
        />
        {/* <TextInputFieldC name="source_type" label="Source Type" type="text" /> */}

        {/* Instruction Input Field with TextArea */}
        {selectedOptionType?.toLowerCase() === "text" && (
          <>
            <Box display="flex">
              <TextField
                label="option"
                value={instructionInput}
                onChange={(e) => setInstructionInput(e.target.value)}
                fullWidth
                // size="small"
                error={!!methods.formState.errors.option}
                helperText={
                  !!methods.formState.errors.option
                    ? String(methods.formState.errors.option.message)
                    : ""
                }
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddInstruction}
                sx={{
                  ml: 1,
                  width: "10px",
                  height: "50px",
                  bgcolor: "primary.main", // Set the background color to primary
                  ":hover": {
                    bgcolor: "primary.main", // Keep the hover background color the same
                  },
                }}
              >
                <AddIcon sx={{ color: "white" }} />
              </Button>
            </Box>

            <Box>
              <span style={{ color: "#009DFF" }}>Options</span>

              {getValues("option")?.map((instruction, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgcolor="lightgrey"
                  p={0.3}
                  my={1}
                  borderRadius="4px"
                  sx={{
                    ":hover button": { visibility: "visible" }, // Visibility for smoother transitions
                  }}
                >
                  <Checkbox
                    checked={instruction.optionstatus}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <Typography
                    sx={{
                      flexGrow: 1, // Make text take available space
                      wordWrap: "break-word", // Ensure long text wraps
                      overflow: "hidden",
                    }}
                  >
                    {instruction.optionname}
                  </Typography>
                  <IconButton onClick={() => handleDeleteInstruction(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              {}
            </Box>
          </>
        )}
        {selectedOptionType?.toLowerCase() === "image" && (
          <>
            <Box display="flex">
              {/* Create a custom file input using a TextField */}
              <TextField
                fullWidth
                type="file"
                // size="small"
                value={tempImg ?? ""}
                label="Option Image"
                InputLabelProps={{ shrink: true }}
                onChange={(e: any) => {
                  setTempImg(e.target.value);

                  const file = e.target.files ? e.target.files[0] : null;
                  if (file) {
                    setImageInput(file); // Set the image input file
                  }
                }}
                error={!!methods.formState.errors.option_url}
                helperText={
                  !!methods.formState.errors.option_url
                    ? String(methods.formState.errors.option_url.message)
                    : ""
                }
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddImage}
                sx={{
                  ml: 1,
                  width: "10px",
                  height: "50px",
                  bgcolor: "primary.main",
                  ":hover": {
                    bgcolor: "primary.main", // Keep hover background color the same
                  },
                }}
              >
                <AddIcon sx={{ color: "white" }} />
              </Button>
            </Box>

            {getValues("option_url")?.map((option, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor="lightgrey"
                p={0.3}
                my={1}
                borderRadius="4px"
              >
                <Checkbox
                  checked={option.optionurlstatus}
                  onChange={() => handleCheckboxChangeUrl(index)}
                />
                <img
                  src={
                    typeof option.optionurlname === "string"
                      ? option.optionurlname
                      : URL.createObjectURL(option.optionurlname)
                  }
                  alt={`Uploaded ${index}`}
                  style={{
                    maxHeight: "100px",
                    maxWidth: "55%",
                    marginRight: "10px",
                  }}
                />
                {/* Display image */}
                <IconButton onClick={() => handleDeleteImage(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </>
        )}
        {imageFile && (
          <div>
            <span style={{ color: "#009DFF" }}>Preview for Question Image</span>
            <img
              src={
                typeof imageFile === "string"
                  ? imageFile
                  : URL.createObjectURL(imageFile)
              }
              alt="Uploaded preview"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            {/* <img
              src={imageFile}
              alt="Uploaded preview"
              style={{ maxWidth: "100%", height: "auto" }}
            /> */}
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default memo(QuestionsForm);

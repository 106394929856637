import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
} from "@mui/material";
import "./TotalExamList.scss"; // Import SCSS styles
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { clearListExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import { listUserExamList } from "../../Redux/Slices/UserPackage/ListUserExamListSlice";
import { listInstructionExamID } from "../../Redux/Slices/Exam/ListinstructionExamIDSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { listUserPackage } from "../../Redux/Slices/UserPackage/ListUserPackageSlice";

const TotalExamList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: listUserExamListSlice } = useAppSelector(
    (state) => state.ListUserExamListSlice
  );

  const { data: listUserPackageSlice } = useAppSelector(
    (state) => state.ListUserPackageSlice
  );
  const Packages = Array.isArray(listUserPackageSlice?.data)
    ? listUserPackageSlice?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];
  const Package = Array.isArray(listUserExamListSlice?.data)
    ? listUserExamListSlice?.data
        .filter((user) => user.expiry === true) // Filter out courses where expiry is false
        .map((user, index) => ({
          ...user,
          index: index + 1,
        }))
    : [];

  useEffect(() => {
    dispatch(listUserPackage());
    dispatch(listUserExamList());
    const userDetails = decode(localStorage.getItem("details"));
    setDetails(userDetails);
  }, []);

  const [details, setDetails] = useState<ILoginResponse>({});

  return (
    <>
      <div className="Myenrollments">My Enrollments</div>
      <div className="TotalExamListmain">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div className="courseslist">Enrolled Courses</div>
          <Button
            className="buttonnamenextresportwrite"
            sx={{ zIndex: 1111 }}
            onClick={() => {
              navigate("/home"); // Replace with your target route
            }}
          >
            Back to Home
          </Button>
        </div>
        {Package?.length === 0 ? (
          <>
            <div style={{ marginBottom: "20px" }}>
              <div className="customCardpending">
                <Card
                  className="dashboard_cardspending"
                  sx={{ bgcolor: "#f5f5f5" }}
                >
                  <Typography className="heading_name" variant="h6">
                    Courses Not Available
                  </Typography>
                </Card>
              </div>
            </div>
          </>
        ) : (
          Package?.map((pkg, index) => (
            <div key={index}>
              <div
                style={{ marginBottom: "20px", cursor: "pointer" }}
                // onClick={() => {
                //   navigate("/purchasedexam", { state: pkg?.package?.id }); // Replace with your target route
                // }}
              >
                <div className="customCardpendingtotal">
                  <Card
                    className="dashboard_cardspending"
                    sx={{ bgcolor: "#f5f5f5" }}
                  >
                    <Typography className="heading_name" variant="h6">
                      {pkg?.course_name}
                    </Typography>
                  </Card>
                  <div className="textContent">
                    <Typography
                      variant="h5"
                      sx={{ color: "#0088ff" }}
                      className="textsytleheading"
                    >
                      {pkg?.course_name}
                    </Typography>

                    <Typography variant="body2" className="textsytle">
                      {pkg?.course_description}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ color: "#0088ff" }}
                      className="textsytleheading"
                    >
                      {pkg?.exams?.length === 0
                        ? "Exams Not Available"
                        : "Exams"}
                    </Typography>
                    {/* Use parentheses to directly return the JSX from the map function */}
                    {pkg?.exams?.map((listexam, examIndex) => (
                      <Accordion key={examIndex} sx={{ width: "100%" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id={`panel-${index}-header`}
                          sx={{
                            color: "#0088ff",
                          }}
                        >
                          {listexam?.exam_name}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>{listexam?.exam_description}</div>

                          <div className="Buttonlist">
                            <button
                              className="card-buttonexam"
                              onClick={() => {
                                navigate("/examresult", {
                                  state: listexam?.student_exam_id,
                                });
                              }}
                            >
                              Report
                            </button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div className="courseslist">Packages on Everest Tutorting</div>
        </div>

        <Box className="dashboard_cards_container">
          {Packages?.length === 0 ? (
            // Render this when the Package array is empty
            <Card className="dashboard_card">
              <div className="dashboard_cards">
                <Typography className="heading_name" variant="h6">
                  Packages Not Available
                </Typography>
              </div>
            </Card>
          ) : (
            // Render this when the Package array has data
            Packages?.map((pkg, index) => (
              <Card className="dashboard_card" key={index}>
                <div className="dashboard_cards">
                  <div className="heading_name">{pkg?.package_name}</div>
                  <div className="concentname">{pkg?.package_name}</div>
                  <div className="examcount">
                    {" "}
                    Total Course : {pkg?.course_count}
                  </div>
                  {/* <div className="costoflist"> A$ {pkg?.cost}</div> */}

                  <button
                    className="card-buttonlist"
                    onClick={() => {
                      navigate("/subscribedPages", { state: pkg?.package_id }); // Replace with your target route
                    }}
                  >
                    {pkg?.subscribed === true ? "Enrolled" : "Enroll"}
                  </button>
                </div>
              </Card>
            ))
          )}
        </Box>
      </div>
    </>
  );
};

export default TotalExamList;

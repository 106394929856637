import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import { IPendingPracticeExam } from "../../../Pages/DashboardUser/IDashboardUser";
import {
  failurependingPracticeExam,
  successpendingPracticeExam,
} from "./PendingPracticeExamSlice";

// list exam id

const PendingPracticeExamApi = (
  action: PayloadAction<IPendingPracticeExam>
) => {
  return api.get("/v1/api/exam/ResumeTest", { params: action.payload });
};

export function* PendingPracticeExamCall(
  action: PayloadAction<IPendingPracticeExam>
) {
  try {
    const response: AxiosResponse = yield call(PendingPracticeExamApi, action);
    yield put(successpendingPracticeExam(response.data));
  } catch (error) {
    yield put(failurependingPracticeExam(error));
  }
}

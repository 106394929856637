import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureaddExamAnswerPractice,
  successaddExamAnswerPractice,
} from "./AddExamAnswerPracticeSlice";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  failurelistExamAnswerPractice,
  successlistExamAnswerPractice,
} from "./ListExamAnswerPracticeSlice";

import { IExamAnswerPractice } from "../../../Pages/Exam participation/IExam_participation";
import {
  failurelistExamsubmitPractice,
  successlistExamsubmitPractice,
} from "./ListExamsubmitPracticeSlice";
import { IExamlistsubmitPractice } from "../../../Pages/Exam/IExam";

// list
const ListExamAnswerPracticeApi = (
  action: PayloadAction<IExamAnswerPractice>
) => {
  return api.get("/v1/api/exam/answer", { params: action.payload });
};

export function* ListExamAnswerPracticeCall(
  action: PayloadAction<IExamAnswerPractice>
) {
  try {
    const response: AxiosResponse = yield call(
      ListExamAnswerPracticeApi,
      action
    );
    yield put(successlistExamAnswerPractice(response.data));
  } catch (error) {
    yield put(failurelistExamAnswerPractice(error));
  }
}

// list exam submit

const ListExamsubmitPracticeApi = (
  action: PayloadAction<IExamlistsubmitPractice>
) => {
  return api.get("/v1/api/exam/testsubmission", { params: action.payload });
};

export function* ListExamsubmitPracticeCall(
  action: PayloadAction<IExamlistsubmitPractice>
) {
  try {
    const response: AxiosResponse = yield call(
      ListExamsubmitPracticeApi,
      action
    );
    yield put(successlistExamsubmitPractice(response.data));
  } catch (error) {
    yield put(failurelistExamsubmitPractice(error));
  }
}

// add
const AddExamAnswerPracticeApi = (
  action: PayloadAction<IExamAnswerPractice>
) => {
  return api.post("/v1/api/exam/Testanswer", action.payload);
};

export function* AddExamAnswerPracticeCall(
  action: PayloadAction<IExamAnswerPractice>
) {
  try {
    const response: AxiosResponse = yield call(
      AddExamAnswerPracticeApi,
      action
    );
    yield put(successaddExamAnswerPractice(response.data));
  } catch (error) {
    yield put(failureaddExamAnswerPractice(error));
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReViewExamlistIDPractice } from "../../../Pages/Exam/IExam";

export const ListReViewExamPracticeSlice = createSlice({
  name: "ListReViewExamIDPracticeSlice",
  initialState: {} as IReduxState<IReViewExamlistIDPractice[]>,
  reducers: {
    listReViewExamIDPractice: (
      state,
      _: PayloadAction<IReViewExamlistIDPractice>
    ) => {
      state.isLoading = true;
    },
    successlistReViewExamIDPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistReViewExamIDPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearListReViewExamIDPractice: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistReViewExamIDPractice,
  listReViewExamIDPractice,
  successlistReViewExamIDPractice,
  clearListReViewExamIDPractice,
} = ListReViewExamPracticeSlice.actions;

export default ListReViewExamPracticeSlice.reducer;

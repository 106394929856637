import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import PrivateRoute from "./PrivateRoute";
import Login from "../Pages/Login/Login";
import NotAuthorised from "../Pages/NotAuthorised/NotAuthorised";
import UserPermissions from "../Pages/UserPermission/UserPermissions";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import Registration from "../Pages/Registration/Registration";
import Role from "../Pages/Role/Role";
import Home from "../Home";
import Menu from "../Pages/Menu/Menu";
import Exam from "../Pages/Exam/Exam";
import Instruction from "../Pages/Instruction/Instruction";
import Options from "../Pages/Options/Options";
import Questions from "../Pages/Questions/Questions";
import QuestionType from "../Pages/QuestionType/QuestionType";
import ExamModel from "../Pages/ExamModel/ExamModel";
import DashboardUser from "../Pages/DashboardUser/DashboardUser";
import ExamHistory from "../Pages/Exam_Histroy/Exam_History";
import ExamParticipation from "../Pages/Exam participation/Exam_participation";
import SubmitExam from "../Pages/SubmitExam/SubmitExam";
import FeedBack from "../Pages/FeedBack/FeedBack";
import ExamResult from "../Pages/ExamResult/ExamResult";
import CheckoutForm from "../Pages/CheckoutForm/CheckoutForm";
import ReViewExam from "../Pages/ReViewExam/ReViewExam";
import ReExam from "../Pages/ReExam/ReExam";
import InstructionReExam from "../Pages/InstructionReExam/InstructionReExam";
import Package from "../Pages/Package/Package";
import SubscribedPages from "../Pages/SubscribedPages/SubscribedPages";
import TotalExamList from "../Pages/TotalExamLIst/TotalExamList";
import UserProfile from "../Pages/UserProfile/UserProfile";
import InstructionPending from "../Pages/InstructionPending/InstructionPending";
import PendingExam from "../Pages/PendingExam/PendingExam";
import Purchased_Exam_List from "../Pages/Purchased_Exam_List/Purchased_Exam_List";
import User from "../Pages/User/User";
import Courses from "../Pages/Courses/Courses";
import UserAdmin from "../Pages/UserAdmin/UserAdmin";
import PracticeExam from "../Pages/PracticeExam/PracticeExam";
import InstructionPractice from "../Pages/InstructionPractice/InstructionPractice";
import InstructionPendingPractice from "../Pages/InstructionPendingPractice/InstructionPendingPractice";
import ExamResultPractice from "../Pages/ExamResultPractice/ExamResultPractice";
import SubmitExamPractice from "../Pages/SubmitExamPractice/SubmitExamPractice";
import ExamParticipationPractice from "../Pages/Exam participationPractice/Exam_participationPractice";
import PendingExamPractice from "../Pages/PendingExamPractice/PendingExamPractice";
import ReViewExamPractice from "../Pages/ReViewExamPractice/ReViewExamPractice";
import FeedBackPractice from "../Pages/FeedBackPractice/FeedBackPractice";
import InstructionPageTamplete from "../Pages/PageTamplete/InstructionPageTamplete";
import OnlineExamTamplete from "../Pages/PageTamplete/OnlineExampageTamplete/OnlineExampageTamplete";

export const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  { path: "/registration", element: <Registration /> },
  { path: "/forgotPassword", element: <ForgotPassword /> },
  { path: "/resetPassword", element: <ResetPassword /> },
  { path: "/examresultpractice", element: <ExamResultPractice /> },
  { path: "/examresult", element: <ExamResult /> },

  { path: "/instructionpagetamplete", element: <InstructionPageTamplete /> },
  { path: "/onlineexamtamplete", element: <OnlineExamTamplete /> },
  {
    path: "/",
    errorElement: <ErrorPage />,
    // loader:,

    element: <PrivateRoute element={<Home />} />,
    // element: <Home />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },

      {
        path: "userprofile",
        children: [
          {
            path: "student",
            element: <User />,
          },
          {
            path: "staff",
            element: <UserAdmin />,
          },
        ],
      },

      {
        path: "masters",
        children: [
          {
            path: "exam",
            element: <Exam />,
          },
          {
            path: "Instruction",
            element: <Instruction />,
          },
          {
            path: "options",
            element: <Options />,
          },
          {
            path: "questions",
            element: <Questions />,
          },
          {
            path: "questiontype",
            element: <QuestionType />,
          },
          {
            path: "practiceexam",
            element: <PracticeExam />,
          },
          {
            path: "exammodel",
            element: <ExamModel />,
          },
          { path: "package", element: <Package /> },
          { path: "courses", element: <Courses /> },
        ],
      },

      {
        path: "settings",
        children: [
          {
            path: "role",
            element: <Role />,
          },
          {
            path: "menu",
            element: <Menu />,
          },
          {
            path: "userPermission",
            element: <UserPermissions />,
          },
        ],
      },

      { path: "/instruction", element: <Instruction /> },
      { path: "/home", element: <DashboardUser /> },
      { path: "/examhistory", element: <ExamHistory /> },
      { path: "/examparticipation", element: <ExamParticipation /> },
      { path: "/practiceexam", element: <ExamParticipationPractice /> },
      { path: "/submitexam", element: <SubmitExam /> },
      { path: "/feedback", element: <FeedBack /> },
      { path: "/feedbackpractice", element: <FeedBackPractice /> },
      { path: "/reviewexam", element: <ReViewExam /> },
      { path: "/reviewexampractice", element: <ReViewExamPractice /> },
      { path: "/reexam", element: <ReExam /> },
      { path: "/instructionreExam", element: <InstructionReExam /> },
      { path: "/subscribedPages", element: <SubscribedPages /> },
      { path: "/totalexam", element: <TotalExamList /> },
      { path: "/studentprofile", element: <UserProfile /> },
      { path: "/instructionpending", element: <InstructionPending /> },
      { path: "/pendingexam", element: <PendingExam /> },
      { path: "/submitexampractice", element: <SubmitExamPractice /> },
      { path: "/purchasedexam", element: <Purchased_Exam_List /> },
      { path: "/submitexampractice", element: <SubmitExamPractice /> },
      { path: "/instructionpractice", element: <InstructionPractice /> },
      { path: "/pendingexampractice", element: <PendingExamPractice /> },
      {
        path: "/instructionpendingpractice",
        element: <InstructionPendingPractice />,
      },

      {
        path: "NotAuthorised",
        element: <NotAuthorised />,
      },
      { path: "/payment", element: <CheckoutForm /> },
    ],
  },
]);

import "./Login.scss";

import { CircularProgress, SvgIcon } from "@mui/material";
import React, { useEffect, useState } from "react";
import Background from "../../Assets/Images/cartoon-ai-robot-scene.jpg";
import { ReactComponent as ClosedEye } from "../../Assets/Images/closedEye.svg";
import { ReactComponent as LoginLogo } from "../../Assets/Images/loginlogo.svg";
import { ReactComponent as OpenEye } from "../../Assets/Images/openEye.svg";
import { ReactComponent as Google } from "../../Assets/Images/Google Button.svg";
import { ReactComponent as Facebook } from "../../Assets/Images/Facebook Button.svg";
import { ReactComponent as Logmobile } from "../../Assets/Images/logomobile.svg";
import ButtonField from "../../Components/Button/ButtonField";
import { Link, useNavigate } from "react-router-dom";
import IconButtonField from "../../Components/Button/IconButtonField";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  clearLoginStatus,
  loginStart,
} from "../../Redux/Slices/LoginSlicer/LoginSlicer";
import { ILoginForm } from "./ILogin";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import Snackbar from "../../Components/Snackbar/Snackbar";
import { FacebookAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import {
  auth,
  facebookProvider,
  googleProvider,
} from "../../Components/FireBase/firebase";
import { decode } from "../../Utils/encodeDecode";
import {
  clearGoogleLoginStatus,
  GoogleloginStart,
} from "../../Redux/Slices/LoginSlicer/GoogleLoginSlicer";
import { EditStudentExamPractice } from "../../Redux/Slices/StudentExamPractice/EditStudentExamPracticeSlice";
import { EditStudentExam } from "../../Redux/Slices/StudentExam/EditStudentExamSlice";
// dialog
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

const Login: React.FC = () => {
  // dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearLoginStatus();
  };
  // ---

  const [show, setShow] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const loginResponse = useAppSelector((state) => state.LoginSlicer);
  const GoogleloginResponse = useAppSelector(
    (state) => state.GoogleLoginSlicer
  );

  const loginSchema = z.object({
    email: z
      .string({
        required_error: "Please enter a valid email ID",
        message: "Please enter a valid email ID",
      })
      .email({
        message: "Please enter a valid email ID",
      }),
    password: z.string({
      required_error: "Please enter password",
      message: "Please enter password",
    }),
  });
  const methods = useForm<ILoginForm>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(loginSchema),
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<ILoginForm> = (val) => {
    setIsSubmit(true);
    dispatch(loginStart(val));
    // localStorage.setItem("token", JSON.stringify(val));
    // nav("/dashboard");
  };
  useEffect(() => {
    const getToken = localStorage.getItem("token");
    const userDetails = decode(localStorage.getItem("details"));
    if (
      (loginResponse.data?.status && getToken) ||
      (GoogleloginResponse.data?.status && getToken)
    ) {
      if (userDetails.role === "ADMIN") {
        setTimeout(() => {
          nav("/dashboard");
        }, 1000);
      } else {
        setTimeout(() => {
          nav("/home");
        }, 1000);
      }
    }
  }, [loginResponse, GoogleloginResponse, nav]);

  // dialog
  useEffect(() => {
    if (loginResponse.data?.status === false) {
      setOpen(true);
    }
  }, [loginResponse]);
  // ---

  // useEffect(() => {
  //   return () => {
  //     if (!isSubmit) {
  //       dispatch(clearLoginStatus());
  //       dispatch(clearGoogleLoginStatus());
  //     }
  //   };
  // }, []);

  const PendingTime = localStorage.getItem("pending_time");
  const Response = localStorage.getItem("response");
  const question_id = Number(localStorage.getItem("question_id"));
  const Question_Type = Number(localStorage.getItem("question_type"));
  const StudentExamId = Number(localStorage.getItem("student_exam_id"));

  useEffect(() => {
    dispatch(
      EditStudentExamPractice({
        pending_time: PendingTime,
        student_test_id: StudentExamId,
        question_type_id: Question_Type,
        question_id: question_id,
        response: Response,
      })
    );
    dispatch(
      EditStudentExam({
        pending_time: PendingTime,
        student_exam_id: StudentExamId,
        question_type_id: Question_Type,
        question_id: question_id,
        sentence: Response,
      })
    );
    localStorage.removeItem("pending_time");
    localStorage.removeItem("response");
    localStorage.removeItem("question_id");
    localStorage.removeItem("question_type");
    localStorage.removeItem("student_exam_id");
    localStorage.clear();
  }, [dispatch]);

  // Google login function
  const googleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user: any = result.user;

      if (user) {
        dispatch(GoogleloginStart({ token: user?.accessToken, islogin: true }));
      }
    } catch (error: any) {
      console.error("Login failed:", error);

      // Extract error message
      const errorMessage =
        error.code === "auth/unauthorized-domain"
          ? "Login failed: Unauthorized domain. Please contact support."
          : "Login failed: " +
            (error.message || "An unexpected error occurred.");
    }
  };

  // facebook
  const signInWithFacebook = () => {
    signInWithPopup(auth, facebookProvider)
      .then((result) => {
        const user = result.user;
        // if (user) {
        //   dispatch(
        //     GoogleloginStart({ token: user?.accessToken, islogin: true })
        //   );
        // }
      })
      .catch((error) => {
        console.error("Error during Facebook login:", error);
      });
  };
  return (
    <>
      <div className="parent-div">
        <Snackbar
          titleSx={{ color: "white" }}
          response={
            Object.keys(loginResponse.data || {}).length === 0
              ? GoogleloginResponse.data
              : loginResponse.data
          }
        />

        {/* <div className="child-div1s"></div> */}
        <div className="child-div1s">
          <img
            src={Background}
            alt="AI Robot Scene"
            className="responsive-image"
          />
        </div>

        <div className="child-div2s">
          <div className="form-content">
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="main">
                  <div>
                    <div className="heading">
                      <SvgIcon
                        sx={{
                          width: "70%",
                          height: "70%",
                          padding: "15px",
                        }}
                        component={LoginLogo}
                        inheritViewBox
                      />
                    </div>
                    <div className="subHeading">Sign In To Your Account </div>
                  </div>
                  <div className="textFields">
                    <div>Email</div>
                    <TextInputFieldC
                      name="email"
                      type="text"
                      className="textField"
                      // placeholder="Email"
                      variant="outlined"
                    />
                    <div>Password</div>
                    <TextInputFieldC
                      name="password"
                      className="textField"
                      type={show ? "text" : "password"}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <IconButtonField onClick={() => setShow(!show)}>
                            <SvgIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                color: "white",
                              }}
                              component={show ? OpenEye : ClosedEye}
                              inheritViewBox
                            />
                          </IconButtonField>
                        ),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link
                      to={"/forgotPassword"}
                      style={{ color: "black", width: "40%" }}
                      className="forgetPassword"
                    >
                      Forgot Password?
                    </Link>
                  </div>

                  <div className="textFields">
                    <ButtonField
                      className="buttonfield"
                      disabled={loginResponse.isLoading}
                      type="submit"
                    >
                      Sign In
                      {loginResponse.isLoading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </ButtonField>
                  </div>
                  <div style={{ textAlign: "center" }}>Or</div>
                  {/* <div
                    style={{ textAlign: "center", margin: "5px 0px 5px 0px" }}
                  >
                    <SvgIcon
                      sx={{
                        width: "25%",
                        height: "60px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      component={Google}
                      onClick={googleLogin}
                      inheritViewBox
                    />
                    <SvgIcon
                      sx={{
                        width: "25%",
                        height: "60px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      component={Facebook}
                      onClick={signInWithFacebook}
                      inheritViewBox
                    />
                  </div> */}
                  {/* <div className="forgetPasswordfield">
                    Don't have an account yet?
                    <Link
                      to={"/registration"}
                      style={{
                        color: "rgba(0, 118, 192, 1)",
                        paddingLeft: "10px",
                      }}
                    >
                      Sign Up
                    </Link>
                  </div> */}
                  <div className="textFields">
                    <ButtonField className="buttonfieldregistration">
                      <Link
                        to={"https://everesttutoring.com.au/contact/"}
                        style={{
                          color: "rgba(0, 118, 192, 1)",
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                      >
                        For Registration
                      </Link>
                    </ButtonField>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>

      <div className="backGroundbackground">
        {/* <Snackbar response={loginResponse.data} /> */}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mains">
              <div>
                <div className="heading">
                  <SvgIcon
                    sx={{
                      width: "70%",
                      height: "70%",
                      padding: "15px",
                    }}
                    component={Logmobile}
                    inheritViewBox
                  />
                </div>
                <div className="subHeading">Sign In To Your Account </div>
              </div>
              <div className="textFields">
                <div>Email</div>
                <TextInputFieldC
                  name="email"
                  type="text"
                  // placeholder="Email"
                  variant="outlined"
                />
                <div>Password</div>

                <TextInputFieldC
                  name="password"
                  type={show ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButtonField onClick={() => setShow(!show)}>
                        <SvgIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: "white",
                          }}
                          component={show ? OpenEye : ClosedEye}
                          inheritViewBox
                        />
                      </IconButtonField>
                    ),
                  }}
                />
              </div>

              <Link to={"/forgotPassword"} className="forgetPassword">
                Forgot Password?
              </Link>

              <div className="textFields">
                <ButtonField
                  className="buttonfield"
                  disabled={loginResponse.isLoading}
                  type="submit"
                >
                  Sign In
                  {loginResponse.isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </ButtonField>
              </div>
              <div style={{ textAlign: "center" }}>Or</div>
              {/* <div style={{ textAlign: "center", margin: "5px 0px 5px 0px" }}>
                <SvgIcon
                  sx={{
                    width: "25%",
                    height: "60px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  component={Facebook}
                  inheritViewBox
                />
                <SvgIcon
                  sx={{
                    width: "25%",
                    height: "60px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  component={Google}
                  onClick={googleLogin}
                  inheritViewBox
                />
              </div> */}
              {/* <div className="forgetPasswordfield">
                Don't have an account yet?
                <Link
                  to={"/registration"}
                  style={{
                    color: "rgba(0, 118, 192, 1)",
                    paddingLeft: "10px",
                  }}
                >
                  Sign Up
                </Link>
              </div> */}
              <div className="textFields">
                <ButtonField className="buttonfieldregistration">
                  <Link
                    to={"https://everesttutoring.com.au/contact/"}
                    style={{
                      color: "rgba(0, 118, 192, 1)",
                      paddingLeft: "10px",
                      textDecoration: "none",
                    }}
                  >
                    For Registration
                  </Link>
                </ButtonField>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import {
  Button,
  Pagination,
  Typography,
  Grid,
  SvgIcon,
  Box,
  Drawer,
  Divider,
  PaginationItem,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import "./ReViewExamPractice.scss"; // For additional styling
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Chatbot from "../../Components/Chatbot/Chatbot";
import Correctanswer from "../../Assets/Images/Correctanswer.svg";
import Wronganswer from "../../Assets/Images/Wronganswer.svg";
import { ReactComponent as Chatbotboticon } from "../../Assets/Images/chatbot 1.svg";
import { successChatbot } from "../../Redux/Slices/Chatbot/ChatbotSlicer";
import { listReViewExamID } from "../../Redux/Slices/ReViewExam/ListReViewExamSlice";
import AlterDialog from "../../Components/AlterDialog/AlterDialog";
import ButtonField from "../../Components/Button/ButtonField";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearListReViewExamIDPractice,
  listReViewExamIDPractice,
} from "../../Redux/Slices/ReViewExamPractice/ListReViewExamPracticeSlice";
import { listListWritingExamAIPracticeSlice } from "../../Redux/Slices/Writing_Exam_Practice/ListWriting_Exam_AI_PracticeSlice";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "quill";
import {
  clearListWritingExamPracticeSlice,
  listListWritingExamPracticeSlice,
} from "../../Redux/Slices/Writing_Exam_Practice/ListWriting_Exam_PracticeSlice";
import InfoIcon from "@mui/icons-material/Info";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

interface Question {
  id: number;
  question_type: string;
  question: string;
  question_url: string;
  prompt: string;
  response: string;
  rewritten_response?: string;
  instruction: string;
  answer_explanation: string;
  options: {
    optionname: string;
    option_id: number;
    is_correct: boolean;
    chosen: boolean;
    comment: string | null;
  }[];
  optionsurl: {
    optionname: string;
    option_id: number;
    is_correct: boolean;
    chosen: boolean;
    comment: string | null;
  }[];
}

// Register necessary modules
const FontSize = ReactQuill.Quill.import("formats/size");
FontSize.whitelist = ["small", false, "large", "huge"];
ReactQuill.Quill.register(FontSize, true);

const Align = ReactQuill.Quill.import("formats/align");
Quill.register(Align, true);

const modules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, { font: [] }], // Paragraph styles (headers) and font
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }], // Text and background color
    [{ script: "sub" }, { script: "super" }], // Subscript/superscript
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    [{ direction: "rtl" }], // Right-to-left (if needed)
    ["blockquote", "code-block"],
    // ["link", "image", "formula"], // Link, image, formula
    ["clean"], // Clear formatting
    [{ undo: { icon: "undo" } }, { redo: { icon: "redo" } }], // Correct undo/redo
  ],
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "list",
  "bullet",
  "indent",
  "direction",
  "blockquote",
  "code-block",
  "align",
  // "link",
  // "image",
  // "formula",
];

const ReViewExamPractice: React.FC = () => {
  const navigate = useNavigate();
  const { state: view_id } = useLocation();

  // overall data list
  const { data: listReViewExamIDSlice } = useAppSelector(
    (state) => state.ListReViewExamIDPracticeSlice
  );

  const { data: WritingExamPracticeSlice } = useAppSelector(
    (state) => state.ListWritingExamPracticeSlice
  );

  const List: any = view_id?.id
    ? WritingExamPracticeSlice
    : listReViewExamIDSlice;

  const { data: WritingExamAIPracticeSlice } = useAppSelector(
    (state) => state.ListWritingExamAIPracticeSlice
  );
  const AI: any = WritingExamAIPracticeSlice?.data;

  //   dialog box
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  function handleListItemClick(question_type_id: number) {
    const questionIndex = List?.data?.questions?.findIndex(
      (question: any) => question.question_type_id === question_type_id
    );
    setCurrentQuestion(questionIndex + 1);
  }

  // Text Editor
  // const [editorValue, setEditorValue] = useState<string>(
  //   List?.data?.questions[0]?.ai_response
  // );

  const [aiwrite, writeexam] = useState<string>("aiwriting");
  const [aiwritere, writeexamre] = useState<string>("aiwritingre");

  useEffect(() => {
    const filteredQuestionType = List?.data?.questions?.map(
      (question: any) => ({
        id: question?.question_id,
        question_type: question?.question_type_name,
        question_url: question?.question_url,
        question: question?.question_text,
        prompt: question?.prompt,
        instruction: question?.instruction,
        answer_explanation: question?.answer_explanation,
        rewritten_response: question?.rewritten_response,
        response: question?.ai_response,
        options: question?.options?.map((opt: any) => ({
          optionname: opt?.option_text,
          option_id: opt?.option_id,
          is_correct: opt?.is_correct,
          chosen: opt?.chosen,
          comment: opt?.comment,
        })),
        optionsurl: question?.options?.map((opt: any) => ({
          optionname: opt?.option_url,
          option_id: opt?.option_id,
          is_correct: opt?.is_correct,
          chosen: opt?.chosen,
          comment: opt?.comment,
        })),
      })
    );

    if (filteredQuestionType?.length > 0) {
      // setEditorValue(questions[currentQuestion - 1]?.response);
      setQuestions(filteredQuestionType);
    }
  }, [List]);

  const [currentQuestion, setCurrentQuestion] = useState<number>(1);

  const [questions, setQuestions] = useState<Question[]>([]); // Initialize with predefined data
  const [isExamOver, setIsExamOver] = useState<boolean>(false);

  const submitExam = () => {
    setIsExamOver(true);
  };

  // Disable spellcheck as component is mounted
  const ref = React.useRef<ReactQuill & { editor: QuillEditor }>(null);
  React.useEffect(() => {
    ref.current?.editor.root.setAttribute("spellcheck", "false");
  }, []);

  const renderQuestionOptions = (
    questionId: number,
    answer_explanation: string,
    question_type: string,
    options: {
      optionname: string;
      option_id: number;
      is_correct: boolean;
      chosen: boolean;
      comment: string | null;
    }[],
    optionsurl: {
      optionname: string;
      option_id: number;
      is_correct: boolean;
      chosen: boolean;
      comment: string | null;
    }[]
  ) => {
    const hasNullUrl = optionsurl?.some(
      (option) => option?.optionname === null
    );

    return (
      <>
        <Grid
          container
          spacing={question_type === "Reading Comprehension" ? 3 : 1}
          sx={{
            ...(question_type === "Reading Comprehension"
              ? {
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }
              : {
                  backgroundColor: "white",
                  marginBottom: "40px",
                  position: "fixed",
                  bottom: "0",
                  padding: "0% 8% 4% 8%",
                }),
          }}
        >
          {hasNullUrl
            ? options?.map((option, index) => {
                let message = null;
                let messageColor = "";

                if (option?.chosen && option?.is_correct) {
                  message = (
                    <>
                      <img
                        src={Correctanswer}
                        alt="Correct answer"
                        style={{
                          // width: "15px",
                          marginRight: "8px",
                          marginTop: "2px",
                        }}
                      />
                      Your Answer is Correct
                    </>
                  );
                  messageColor = "#88B61E";
                } else if (option.chosen && !option.is_correct) {
                  message = (
                    <>
                      <img
                        src={Wronganswer}
                        alt="Wrong answer"
                        style={{
                          // width: "20px",
                          marginTop: "2px",
                          marginRight: "8px",
                          height:
                            question_type === "Reading Comprehension"
                              ? "15px"
                              : "auto",
                        }}
                      />
                      Your Answer is Wrong
                    </>
                  );
                  messageColor = "#BB2B31";
                } else if (!option.chosen && option.is_correct) {
                  message = (
                    <>
                      <img
                        src={Correctanswer}
                        alt="Correct answer"
                        style={{
                          // width: "20px",
                          marginRight: "8px",
                          height:
                            question_type === "Reading Comprehension"
                              ? "15px"
                              : "auto",
                          marginTop: "2px",
                        }}
                      />
                      This is Correct Answer
                    </>
                  );
                  messageColor = "#88B61E";
                }

                return (
                  <Grid item xs={6} key={index} className="gridwidth">
                    <div className="Optionnameflex">
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "12px",
                          fontWeight: "400",
                          textTransform: "capitalize",
                          color: "blue",
                        }}
                      >
                        {`${String.fromCharCode(97 + index)})`}
                      </span>
                      <Box
                        sx={{
                          border: "1px solid #CDCDCD",
                          borderRadius: "6px",
                          padding:
                            question_type === "Reading Comprehension"
                              ? "10px"
                              : "3px",
                          textAlign: "left",
                          width: "100%",

                          color: option.chosen
                            ? "white"
                            : option.is_correct
                            ? "white"
                            : "black",
                          backgroundColor: option.chosen
                            ? option.is_correct
                              ? "#88B61E"
                              : "#BB2B31"
                            : option.is_correct
                            ? "#88B61E"
                            : "white",
                          "&:hover": {
                            backgroundColor: option.chosen
                              ? option.is_correct
                                ? "#88B61E"
                                : "#BB2B31"
                              : option.is_correct
                              ? "#88B61E"
                              : "white",
                          },
                          fontSize: "13px",
                          display: "flex", // Makes the content centered
                          alignItems: "center", // Vertically centers content
                          height:
                            question_type === "Reading Comprehension"
                              ? "100%"
                              : "auto",
                        }}
                      >
                        {option?.optionname}
                      </Box>
                    </div>
                    {message && (
                      <span style={{ color: messageColor, marginLeft: "20px" }}>
                        {message}
                      </span>
                    )}
                  </Grid>
                );
              })
            : optionsurl.map((option, index) => {
                let message = null;
                let messageColor = "";

                if (option?.chosen && option?.is_correct) {
                  message = (
                    <>
                      <img
                        src={Correctanswer}
                        alt="Correct answer"
                        style={{
                          // width: "15px",
                          marginRight: "8px",
                          marginTop: "2px",
                        }}
                      />
                      Your Answer is Correct
                    </>
                  );
                  messageColor = "#88B61E";
                } else if (option.chosen && !option.is_correct) {
                  message = (
                    <>
                      <img
                        src={Wronganswer}
                        alt="Wrong answer"
                        style={{
                          // width: "20px",
                          marginTop: "2px",

                          marginRight: "8px",
                        }}
                      />
                      Your Answer is Wrong
                    </>
                  );
                  messageColor = "#BB2B31";
                } else if (!option.chosen && option.is_correct) {
                  message = (
                    <>
                      <img
                        src={Correctanswer}
                        alt="Correct answer"
                        style={{
                          // width: "20px",
                          marginRight: "8px",
                          marginTop: "2px",
                        }}
                      />
                      This is Correct Answer
                    </>
                  );
                  messageColor = "#88B61E";
                }

                return (
                  <Grid item xs={6} key={index}>
                    <Box
                      sx={{
                        border: "1px solid #CDCDCD",
                        borderRadius: "6px",
                        padding: "3px",
                        textAlign: "center",
                        color: option.chosen
                          ? "white"
                          : option.is_correct
                          ? "white"
                          : "black",
                        backgroundColor: option.chosen
                          ? option.is_correct
                            ? "#88B61E"
                            : "#BB2B31"
                          : option.is_correct
                          ? "#88B61E"
                          : "white",
                        "&:hover": {
                          backgroundColor: option.chosen
                            ? option.is_correct
                              ? "#88B61E"
                              : "#BB2B31"
                            : option.is_correct
                            ? "#88B61E"
                            : "white",
                        },
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <span
                          style={{
                            textAlign: "left",
                            fontSize: "12px",
                            fontWeight: "400",
                            textTransform: "capitalize",
                          }}
                        >
                          {String.fromCharCode(97 + index)}
                        </span>
                        <img
                          src={option?.optionname}
                          alt={`Uploaded ${index}`}
                          style={{ maxWidth: "100%", height: "50px" }}
                        />{" "}
                      </div>
                    </Box>
                    {/* Render message if available */}
                    {message && (
                      <span style={{ color: messageColor }}>{message}</span>
                    )}
                  </Grid>
                );
              })}
          <div
            style={{
              display: "block",
              marginTop:
                question_type === "Reading Comprehension" ? "20px" : "15px",
            }}
          >
            <div
              className="Explanation"
              style={{
                marginLeft:
                  question_type === "Reading Comprehension" ? "30px" : "10px",
              }}
            >
              Explanation
            </div>
            <div
              className="planation"
              style={{
                marginLeft:
                  question_type === "Reading Comprehension" ? "30px" : "10px",
                fontSize: "15px",
              }}
            >
              {answer_explanation}
            </div>
          </div>
        </Grid>
      </>
    );
  };

  // chatbot
  const [chatbotOpen, setChatbotOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (view_id?.id) {
      dispatch(
        listListWritingExamPracticeSlice({ student_test_id: view_id?.id })
      );
    } else {
      dispatch(listReViewExamIDPractice({ id: view_id }));
    }
  }, [view_id?.id]);

  // question image show
  const imageFile = questions[currentQuestion - 1]?.question_url;

  // is pending exam list popup
  const [openreview, setOpenreview] = React.useState(false);

  useEffect(() => {
    if (List?.status === false) {
      setOpenreview(true); // Open only if both conditions are true
    } else {
      setOpenreview(false); // Close if any condition is false
    }
  }, [List]); // Dependencies
  console.log(List?.status);

  const handleClosereview = () => {
    setOpenreview(false);
  };

  return (
    <>
      <div style={{ height: "50px" }} />
      {questions[currentQuestion - 1]?.question_type === "Writing" ? (
        <div className="unselectable">
          <div className="onlineexamdiv">
            <div className="Questiontype">
              <div className="examtext">{List?.data?.exam_name}</div> &nbsp; :
              &nbsp;
              <div className="Questiontypeheading">
                {questions[currentQuestion - 1]?.question_type}
              </div>
              <div className="onlineexmcount">
                Q. {currentQuestion} of {questions?.length}
              </div>
            </div>
          </div>
          <div className="onlineexamdivs">
            <Pagination
              count={questions?.length}
              variant="outlined"
              siblingCount={6} // Display all sibling pages
              boundaryCount={1} // Show all boundary pages
              page={currentQuestion} // Set the current page to be visible
              onChange={(event, value) => setCurrentQuestion(value)} // Update `currentQuestion` on change
              renderItem={(item: any) => {
                if (item.type === "previous" || item.type === "next") {
                  return null;
                }
                if (item.type === "page") {
                  return (
                    <Button
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        color: "black",
                        backgroundColor:
                          item?.page === currentQuestion ? "blue" : "white",
                        minWidth: "unset",
                        padding: 0,
                        marginLeft: "5px",
                        marginTop: "3px",
                      }}
                      onClick={() => setCurrentQuestion(item?.page)}
                    >
                      {item.page}
                    </Button>
                  );
                }
                return <PaginationItem {...item} />;
              }}
            />
          </div>
          <div className="overonlinediv">
            <Grid item xs={12} md={6}>
              <Typography
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  overflow: "auto", // Enable scrolling
                  paddingRight: "10px", // Optional padding for scrollbar space
                }}
              >
                <div style={{ width: "90%" }}>
                  <span
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontWeight: 600,
                    }}
                  >
                    Use this idea as the basis for a piece of writing. You may
                    write in any style.{" "}
                  </span>
                  {questions[currentQuestion - 1]?.question_url && (
                    <Typography
                      variant="h5"
                      sx={{
                        margin: "20px 0px",
                        textAlign: "left",
                        "& img": {
                          maxWidth: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={imageFile} alt="Uploaded preview" />
                    </Typography>
                  )}
                  {aiwritere === "rewriting" && (
                    <ReactQuill
                      className="customsss-quill"
                      style={{
                        width: "100%",
                        marginBottom: "2rem",
                        height: "500px",
                      }}
                      theme="snow"
                      value={questions[currentQuestion - 1]?.rewritten_response}
                      // onChange={(content) => setEditorValue(content)}
                      modules={modules}
                      formats={formats}
                      ref={ref}
                      tabIndex={0} // Ensure it's focusable
                      placeholder="Write something here..."
                      preserveWhitespace
                      data-gramm="false"
                      readOnly={true} // Enable read-only mode
                    />
                  )}
                  {aiwritere === "aiwritingre" && (
                    <>
                      {aiwrite === "aiwriting" && (
                        <ReactQuill
                          className="customsss-quill"
                          style={{
                            width: "100%",
                            marginBottom: "2rem",
                            height: "250px",
                          }}
                          theme="snow"
                          value={questions[currentQuestion - 1]?.response}
                          // onChange={(content) => setEditorValue(content)}
                          modules={modules}
                          formats={formats}
                          ref={ref}
                          tabIndex={0} // Ensure it's focusable
                          placeholder="Write something here..."
                          preserveWhitespace
                          data-gramm="false"
                          readOnly={true} // Enable read-only mode
                        />
                      )}

                      {aiwrite === "aiwriting" && (
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "20px",
                          }}
                        >
                          <Card
                            sx={{
                              width: "100%",
                              boxShadow: 3,
                              marginTop: "40px",
                              p: 2,
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <CardContent>
                              <Typography
                                variant="h5"
                                sx={{
                                  mb: 2,
                                  textAlign: "left",
                                  color: "#009DFF",
                                  fontSize: "20px",
                                }}
                              >
                                Explanation of Highlighted Mistakes
                              </Typography>
                              <Grid container spacing={2}>
                                {List?.data?.questions[0]?.explanation?.map(
                                  (item: any, index: number) => (
                                    <Grid item xs={12} key={index}>
                                      <Box>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color: "#009DFF",
                                              width: "20%",
                                              fontSize: "20px",
                                            }}
                                          >
                                            Highlighted Mistakes
                                          </div>
                                          :
                                          <Typography
                                            variant="body1"
                                            component="div"
                                            sx={{
                                              color: "red",
                                              fontWeight: "bold",
                                              width: "80%",
                                              fontSize: "20px",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: item?.highlight,
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color: "#009DFF",
                                              width: "20%",
                                              fontSize: "20px",
                                            }}
                                          >
                                            Explanation
                                          </div>
                                          :
                                          <Typography
                                            variant="body2"
                                            component="p"
                                            sx={{
                                              color: "#555",
                                              width: "80%",
                                              fontSize: "20px",
                                            }}
                                          >
                                            {item?.explanation}
                                          </Typography>
                                        </div>
                                      </Box>
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Box>
                      )}
                      {aiwrite === "backwriting" && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              marginTop: "20px",
                            }}
                          >
                            <Card
                              sx={{
                                width: "100%",
                                boxShadow: 3,
                                marginTop: "40px",
                                p: 2,
                                backgroundColor: "#f9f9f9",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    mb: 2,
                                    textAlign: "left",
                                    color: "#009DFF",
                                    fontSize: "20px",
                                  }}
                                >
                                  AI-Crafted Refined Version
                                </Typography>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Box>
                                      <div
                                        style={{
                                          fontSize: "20px",
                                          textAlign: "justify",
                                          whiteSpace: "break-spaces",
                                        }}
                                      >
                                        {AI?.questions[0]?.ai_response}
                                      </div>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              color: "#009DFF",
                              gap: "10px",
                              padding: "10px 20px 20px 10px",
                            }}
                          >
                            <div>
                              <InfoIcon sx={{ fontSize: "20px" }} />
                            </div>
                            <div style={{ fontSize: "20px" }}>
                              The AI-generated result provides a completely
                              reimagined version of the text, tailored to the
                              prompt and designed to meet high evaluation
                              criteria.
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                {List?.data?.questions?.length > 0 ? (
                  <div
                    title="Smart Tutor "
                    style={{
                      cursor: "pointer",
                      textAlign: "end",
                      width: "10%",
                    }}
                  >
                    <div
                      style={{
                        color: "#009DFF",
                        padding: "10px 0px 3px 10px",
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      Smart Tutor
                    </div>
                    <div
                      className={`ai-button ${
                        [
                          "Quantitative Reasoning",
                          "Abstract Reasoning",
                        ].includes(
                          questions[currentQuestion - 1]?.question_type
                        )
                          ? "disabled-button"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          [
                            "Quantitative Reasoning",
                            "Abstract Reasoning",
                          ].includes(
                            questions[currentQuestion - 1]?.question_type
                          )
                        ) {
                          return;
                        }
                        setChatbotOpen(true);
                        dispatch(
                          successChatbot(
                            questions[currentQuestion - 1]?.question
                          )
                        );
                      }}
                      title={
                        [
                          "Quantitative Reasoning",
                          "Abstract Reasoning",
                        ].includes(
                          questions[currentQuestion - 1]?.question_type
                        )
                          ? "AI not available, Please check the answer in the explanation."
                          : ""
                      }
                    >
                      <SvgIcon
                        component={Chatbotboticon}
                        sx={{ fontSize: "50px" }}
                        inheritViewBox
                      />
                    </div>
                    <Chatbot
                      open={chatbotOpen}
                      setOpen={setChatbotOpen}
                      questiondata={questions[currentQuestion - 1]?.question}
                      imagedata={questions[currentQuestion - 1]?.question_url}
                      passage={questions[currentQuestion - 1]?.prompt}
                      instruction={questions[currentQuestion - 1]?.instruction}
                    />
                  </div>
                ) : null}
              </Typography>
            </Grid>
          </div>

          <div className="navigation-wrapper"></div>
          <div className="navigation-buttons">
            <div style={{ display: "flex", gap: "10px" }}>
              {aiwritere === "aiwritingre" ? (
                <Button
                  className="buttonnamenextresport"
                  onClick={() => {
                    writeexamre("rewriting");
                    writeexam("aiwriting");
                  }}
                >
                  AI Rewrite My Text
                </Button>
              ) : (
                <Button
                  className="buttonnamenextresport"
                  onClick={() => {
                    // setEditorValue(questions[currentQuestion - 1]?.response);
                    writeexam("aiwriting");
                    writeexamre("aiwritingre");
                  }}
                >
                  Back to review
                </Button>
              )}

              {aiwrite === "aiwriting" ? (
                <Button
                  className="buttonnamenextresport"
                  title="Click to generate AI's own version of the writing for reference"
                  onClick={() => {
                    dispatch(
                      listListWritingExamAIPracticeSlice({
                        student_test_id: view_id?.id,
                      })
                    );
                    // setEditorValue(AI?.questions[0]?.ai_response);
                    writeexam("backwriting");
                    writeexamre("aiwritingre");
                  }}
                >
                  AI Generate New Text
                </Button>
              ) : (
                <Button
                  className="buttonnamenextresport"
                  onClick={() => {
                    // setEditorValue(questions[currentQuestion - 1]?.response);
                    writeexam("aiwriting");
                    writeexamre("aiwritingre");
                  }}
                >
                  Back to review
                </Button>
              )}

              {currentQuestion === questions?.length ? (
                <Button
                  className="buttonnamenextresportwrite"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsExamOver(false);
                    navigate("/examresultpractice", { state: view_id }); // Replace with your target route
                  }}
                >
                  Proceed to Report
                </Button>
              ) : (
                <Button
                  className="buttonnamenext"
                  sx={{ zIndex: 1111 }}
                  onClick={() => {
                    setCurrentQuestion((prev) =>
                      Math.min(prev + 1, questions?.length)
                    );
                  }}
                  disabled={currentQuestion === questions?.length}
                >
                  Next
                </Button>
              )}
              <Button
                className="buttonnamenextresportwrite"
                sx={{ zIndex: 1111 }}
                onClick={() => {
                  setIsExamOver(false);
                  navigate("/home"); // Replace with your target route
                }}
              >
                Back to Home
              </Button>
            </div>
          </div>

          <AlterDialog
            alterheading={"View Solution"}
            alterableName={"Get Back to Report Page"}
            open={isExamOver}
            setOpen={setIsExamOver}
            buttonname={
              <>
                <ButtonField
                  className="alter_button"
                  onClick={() => {
                    setIsExamOver(false);
                    navigate("/examresult", { state: view_id }); // Replace with your target route
                  }}
                >
                  Ok
                </ButtonField>
              </>
            }
          />
        </div>
      ) : (
        <div className="unselectable">
          <div className="onlineexamdiv">
            <div className="Questiontype">
              <div className="examtext">{List?.data?.exam_name}</div> &nbsp; :
              &nbsp;
              <div className="Questiontypeheading">
                {questions[currentQuestion - 1]?.question_type}
              </div>
              <div className="onlineexmcount">
                Q. {currentQuestion} of {questions?.length}
              </div>
            </div>
          </div>
          <div className="onlineexamdivs">
            <Pagination
              count={questions?.length}
              variant="outlined"
              siblingCount={6} // Display all sibling pages
              boundaryCount={1} // Show all boundary pages
              page={currentQuestion} // Set the current page to be visible
              onChange={(event, value) => setCurrentQuestion(value)} // Update `currentQuestion` on change
              renderItem={(item: any) => {
                if (item.type === "previous" || item.type === "next") {
                  return null;
                }
                if (item.type === "page") {
                  return (
                    <Button
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        color: "black",
                        backgroundColor:
                          item?.page === currentQuestion ? "blue" : "white",
                        minWidth: "unset",
                        padding: 0,
                        marginLeft: "5px",
                        marginTop: "3px",
                      }}
                      onClick={() => setCurrentQuestion(item?.page)}
                    >
                      {item.page}
                    </Button>
                  );
                }
                return <PaginationItem {...item} />;
              }}
            />
          </div>
          {questions[currentQuestion - 1]?.question_type ===
          "Reading Comprehension" ? (
            <div className="overonlinediv">
              <Grid item xs={12} md={6} sx={{ width: "50%" }}>
                <div className="examscrollreading">
                  <Typography
                    variant="h5"
                    style={{ padding: "0px 15px 0px 0px" }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "20px",
                        textAlign: "justify",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {questions[currentQuestion - 1]?.prompt}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "20px",
                        textAlign: "justify",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {questions[currentQuestion - 1]?.instruction}
                    </Typography>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} sx={{ width: "50%", padding: "10px" }}>
                <div className="examscrollreading">
                  <Typography variant="h5">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          textAlign: "justify",
                          fontWeight: 600,
                        }}
                      >
                        {questions[currentQuestion - 1]?.question}
                      </Typography>
                      {List?.data?.questions?.length > 0 ? (
                        <div
                          title="Smart Tutor "
                          style={{
                            cursor: "pointer",
                            textAlign: "end",
                            width: "10%",
                            marginBottom: "30px",
                          }}
                        >
                          <div
                            className={`ai-button ${
                              [
                                "Quantitative Reasoning",
                                "Abstract Reasoning",
                              ].includes(
                                questions[currentQuestion - 1]?.question_type
                              )
                                ? "disabled-button"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                [
                                  "Quantitative Reasoning",
                                  "Abstract Reasoning",
                                ].includes(
                                  questions[currentQuestion - 1]?.question_type
                                )
                              ) {
                                return;
                              }
                              setChatbotOpen(true);
                              dispatch(
                                successChatbot(
                                  questions[currentQuestion - 1]?.question
                                )
                              );
                            }}
                            title={
                              [
                                "Quantitative Reasoning",
                                "Abstract Reasoning",
                              ].includes(
                                questions[currentQuestion - 1]?.question_type
                              )
                                ? "AI not available, Please check the answer in the explanation."
                                : ""
                            }
                          >
                            <SvgIcon
                              component={Chatbotboticon}
                              sx={{ fontSize: "50px" }}
                              inheritViewBox
                            />
                          </div>

                          <Chatbot
                            open={chatbotOpen}
                            setOpen={setChatbotOpen}
                            questiondata={
                              questions[currentQuestion - 1]?.question
                            }
                            imagedata={
                              questions[currentQuestion - 1]?.question_url
                            }
                            passage={questions[currentQuestion - 1]?.prompt}
                            instruction={
                              questions[currentQuestion - 1]?.instruction
                            }
                          />
                        </div>
                      ) : null}
                    </div>

                    {questions[currentQuestion - 1]?.question_url && (
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "left",
                          position: "relative", // Ensure proper positioning of the image
                          overflow: "visible", // Ensure the enlarged image is not clipped
                          "& img": {
                            maxWidth: "100%",
                            height:
                              List?.data?.question_by_type[0]
                                ?.question_type_name === "Writing"
                                ? "30rem"
                                : "100%",
                            cursor: "pointer",
                            transition: "transform 0.3s ease",
                            position: "relative",
                            transformOrigin: "left center", // Keep the hover scaling aligned to the left
                          },
                          "& img:hover": {
                            transform: "scale(1.5)",
                          },
                        }}
                      >
                        <img src={imageFile} alt="Uploaded preview" />
                      </Typography>
                    )}
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "20px",
                        textAlign: "justify",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {List?.data?.questions?.length === 0 ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "larger",
                            marginTop: "30%",
                          }}
                        >
                          No Question Found
                        </div>
                      ) : (
                        renderQuestionOptions(
                          currentQuestion,
                          questions[currentQuestion - 1]?.answer_explanation,
                          questions[currentQuestion - 1]?.question_type,
                          questions[currentQuestion - 1]?.options || [],
                          questions[currentQuestion - 1]?.optionsurl || []
                        )
                      )}{" "}
                    </Typography>
                  </Typography>
                </div>
              </Grid>
            </div>
          ) : (
            <>
              <div className="overonlinediv">
                <Grid item xs={12} md={6}>
                  <div className="examscroll">
                    <Typography
                      style={{
                        marginBottom: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        overflow: "auto", // Enable scrolling
                        paddingRight: "10px", // Optional padding for scrollbar space
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "justify",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {questions[currentQuestion - 1]?.prompt}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "justify",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {questions[currentQuestion - 1]?.instruction}
                        </Typography>
                        <span
                          style={{
                            fontSize: "20px",
                            textAlign: "justify",
                            fontWeight: 600,
                          }}
                        >
                          {questions[currentQuestion - 1]?.question}
                        </span>

                        {questions[currentQuestion - 1]?.question_url && (
                          <Typography
                            variant="h5"
                            sx={{
                              marginBottom: "20px",
                              textAlign: "left",
                              position: "relative", // Ensure proper positioning of the image
                              overflow: "visible", // Ensure the enlarged image is not clipped
                              "& img": {
                                maxWidth: "100%",
                                height: "100%",
                                cursor: "pointer",
                                transition: "transform 0.3s ease",
                                position: "relative",
                                transformOrigin: "left center", // Keep the hover scaling aligned to the left
                              },
                              "& img:hover": {
                                transform: "scale(1.5)",
                              },
                            }}
                          >
                            <img src={imageFile} alt="Uploaded preview" />
                          </Typography>
                        )}
                      </div>
                      {List?.data?.questions?.length > 0 ? (
                        <div
                          title="Smart Tutor "
                          style={{
                            cursor: "pointer",
                            textAlign: "end",
                            width: "10%",
                          }}
                        >
                          <div
                            style={{
                              color: "#009DFF",
                              padding: "10px 0px 3px 10px",
                              textAlign: "center",
                              fontWeight: 600,
                              fontSize: "15px",
                            }}
                          >
                            Smart Tutor
                          </div>
                          <div
                            className={`ai-button ${
                              [
                                "Quantitative Reasoning",
                                "Abstract Reasoning",
                              ].includes(
                                questions[currentQuestion - 1]?.question_type
                              )
                                ? "disabled-button"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                [
                                  "Quantitative Reasoning",
                                  "Abstract Reasoning",
                                ].includes(
                                  questions[currentQuestion - 1]?.question_type
                                )
                              ) {
                                return;
                              }
                              setChatbotOpen(true);
                              dispatch(
                                successChatbot(
                                  questions[currentQuestion - 1]?.question
                                )
                              );
                            }}
                            title={
                              [
                                "Quantitative Reasoning",
                                "Abstract Reasoning",
                              ].includes(
                                questions[currentQuestion - 1]?.question_type
                              )
                                ? "AI not available, Please check the answer in the explanation."
                                : ""
                            }
                          >
                            <SvgIcon
                              component={Chatbotboticon}
                              sx={{ fontSize: "50px" }}
                              inheritViewBox
                            />
                          </div>
                          <Chatbot
                            open={chatbotOpen}
                            setOpen={setChatbotOpen}
                            questiondata={
                              questions[currentQuestion - 1]?.question
                            }
                            imagedata={
                              questions[currentQuestion - 1]?.question_url
                            }
                            passage={questions[currentQuestion - 1]?.prompt}
                            instruction={
                              questions[currentQuestion - 1]?.instruction
                            }
                          />
                        </div>
                      ) : null}
                    </Typography>
                  </div>
                </Grid>
              </div>

              {List?.data?.questions?.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "larger",
                    marginTop: "30%",
                  }}
                >
                  No Question Found
                </div>
              ) : (
                renderQuestionOptions(
                  currentQuestion,
                  questions[currentQuestion - 1]?.answer_explanation,
                  questions[currentQuestion - 1]?.question_type,
                  questions[currentQuestion - 1]?.options || [],
                  questions[currentQuestion - 1]?.optionsurl || []
                )
              )}
            </>
          )}
          <div className="navigation-wrapper"></div>
          <div className="navigation-buttons">
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                className="buttonnameprevious"
                sx={{ color: "#009DFF" }}
                onClick={() =>
                  setCurrentQuestion((prev) => Math.max(prev - 1, 1))
                }
                disabled={currentQuestion === 1}
              >
                Previous
              </Button>

              {currentQuestion === questions?.length ? (
                <Button
                  className="buttonnamenextresport"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsExamOver(false);
                    navigate("/examresultpractice", { state: view_id }); // Replace with your target route
                  }}
                >
                  Proceed to Report
                </Button>
              ) : (
                <Button
                  className="buttonnamenext"
                  sx={{ zIndex: 1111 }}
                  onClick={() => {
                    setCurrentQuestion((prev) =>
                      Math.min(prev + 1, questions?.length)
                    );
                  }}
                  disabled={currentQuestion === questions?.length}
                >
                  Next
                </Button>
              )}
              <Button
                className="buttonnamenextresport"
                sx={{ zIndex: 1111 }}
                onClick={() => {
                  setIsExamOver(false);
                  navigate("/home"); // Replace with your target route
                }}
              >
                Back to Home
              </Button>
            </div>
          </div>

          <AlterDialog
            alterheading={"View Solution"}
            alterableName={"Get Back to Report Page"}
            open={isExamOver}
            setOpen={setIsExamOver}
            buttonname={
              <>
                <ButtonField
                  className="alter_button"
                  onClick={() => {
                    setIsExamOver(false);
                    navigate("/examresult", { state: view_id }); // Replace with your target route
                  }}
                >
                  Ok
                </ButtonField>
              </>
            }
          />
        </div>
      )}
      <React.Fragment>
        <Dialog
          open={openreview}
          onClose={handleClosereview}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ReportGmailerrorredIcon
              sx={{ fontSize: "50px", color: "#009dff" }}
            />
            <div style={{ fontSize: "20px", color: "#009dff" }}>Warning</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ fontSize: "15px" }}
            >
              {List?.message}{" "}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonField
              className="form_save_button"
              onClick={() => {
                setOpenreview(false);
                navigate("/examresultpractice", { state: view_id }); // Replace with your target route
                if (view_id?.id) {
                  dispatch(clearListWritingExamPracticeSlice());
                } else {
                  dispatch(clearListReViewExamIDPractice());
                }
              }}
            >
              Back to Report
            </ButtonField>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default ReViewExamPractice;

import "./AlterDialog.scss";
import { FunctionComponent, useEffect, useState } from "react";
import { MessageDialogProps } from "./IAlterDialog";
import Dialog from "../Dialog/Dialog";
import ButtonField from "../Button/ButtonField";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listExamsubmit } from "../../Redux/Slices/ExamAnswer/ListExamsubmitSlice";
import { clearlistEvaluationPracticewritingExam } from "../../Redux/Slices/PracticeExam/ListEvaluationPracticewritingExamSlice";

const AlterDialog: FunctionComponent<MessageDialogProps> = (props) => {
  const {
    open,
    setOpen,
    alterableName,
    altermessage,
    alterheading,
    valueofdata,
    buttonname,
  } = props;
  const [responseDialog, setResponseDialog] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
    setResponseDialog(false);
  };
  const navigate = useNavigate();
  const final: any = valueofdata;
  const dispatch = useAppDispatch();
  const { data: EvaluationPracticewritingExam } = useAppSelector(
    (state) => state.ListEvaluationPracticewritingExamSlice
  );

  return (
    <>
      <Dialog
        open={open}
        dialogClassName="alter_dialog"
        dialogTitleClassName="alter_dialog_title"
        Dialog_Title={<>{alterheading}</>}
        dialogContentClassName="alter_dialog_content"
        Dialog_Content={
          <>
            {alterableName}
            <br />
            {altermessage}
          </>
        }
        dialogActionClassName="alter_dialog_action"
        Dialog_Actions={buttonname}
      />
    </>
  );
};

export default AlterDialog;

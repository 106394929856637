import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReExamlistID } from "../../../Pages/Exam/IExam";
import { IPendingPracticeExam } from "../../../Pages/DashboardUser/IDashboardUser";

export const PendingPracticeExamSlice = createSlice({
  name: "PendingPracticeExamSlice",
  initialState: {} as IReduxState<IPendingPracticeExam[]>,
  reducers: {
    pendingPracticeExam: (state, _: PayloadAction<IPendingPracticeExam>) => {
      state.isLoading = true;
    },
    successpendingPracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurependingPracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearpendingPracticeExam: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurependingPracticeExam,
  pendingPracticeExam,
  successpendingPracticeExam,
  clearpendingPracticeExam,
} = PendingPracticeExamSlice.actions;

export default PendingPracticeExamSlice.reducer;

import { FunctionComponent, useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star"; // Import a star icon from MUI
import "./InstructionPending.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import { pendingExam } from "../../Redux/Slices/PendingExam/PendingExamSlice";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { addOpenSection } from "../../Redux/Slices/OpenSection/AddOpenSectionSlice";
import { Button } from "@mui/material";

interface InstructionPendingProps {}

const InstructionPending: FunctionComponent<InstructionPendingProps> = () => {
  // Create an array of instruction objects
  const navigate = useNavigate();
  const { state: exam_id } = useLocation();

  const { data: listexamlist, isLoading: listexamlistLoading } = useAppSelector(
    (state) => state.PendingExamSlice
  );
  const InstructionPendinglist: any = listexamlist?.data;
  const dispatch = useAppDispatch();

  const finalInstructionPending = InstructionPendinglist?.exam_instructions;

  const [details, setDetails] = useState<ILoginResponse>({});

  useEffect(() => {
    dispatch(pendingExam({ id: exam_id }));
  }, []);

  useEffect(() => {
    const userDetails = decode(localStorage.getItem("details"));
    setDetails(userDetails);
  }, []);
  const handleButtonClick = () => {
    // dispatch(
    //   addStudentExam({
    //     exam_id: InstructionPendinglist?.exam_id,
    //     student_id: details?.id,
    //     closed: false,
    //     questions: InstructionPendinglist?.questions,
    //   })
    // );
    localStorage.setItem("student_id", details?.id);
    navigate("/pendingexam", {
      state: { examID: exam_id },
    }); // Replace with your target route
    dispatch(
      addOpenSection({
        student_exam_id: exam_id,
        remaining_time: null,
        closed: false,
        question_type_id:
          InstructionPendinglist?.questions_by_type[0]?.question_type_id,
      })
    );
  };

  return (
    <div className="instruction-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className="instructionheading">
          {InstructionPendinglist?.exam_name}
        </div>
      </div>

      <div className="instructiondescription">
        {InstructionPendinglist?.exam_description}
      </div>

      <div className="instruction-list-container">
        <div style={{ flex: 1 }}>
          <div className="instructionheadingbold">
            Please read instructions carefully
          </div>
          <div className="instruction-liststo">
            {finalInstructionPending?.map((item: any, index: any) => (
              <div key={index} className="instructionitem">
                <FiberManualRecordIcon
                  sx={{ fontSize: "10px", color: "black", marginTop: "3px" }}
                />

                <div style={{ fontSize: "15px" }}>{item?.instruction}</div>
              </div>
            ))}
          </div>
        </div>

        {!!!listexamlistLoading && (
          <div style={{ flex: 0.3 }}>
            <div className="instructionheadingbold">Exam Includes</div>

            <div className="sections-type">
              {InstructionPendinglist?.questions_by_type.map(
                (type: any, index: number) => (
                  <div key={index} className="section-items">
                    <div className="centermultiple">
                      {type?.question_type_name}
                    </div>
                    <div>
                      Question type :{" "}
                      {type?.question_type_name === "Writing"
                        ? "Written"
                        : "MCQ"}
                    </div>

                    <div>
                      {" "}
                      Question count :{" "}
                      {type.question_count === null
                        ? "-"
                        : type.question_count}{" "}
                    </div>
                    <div>
                      {" "}
                      Duration : {type.duration === null ? "-" : type.duration}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <button
          className="proceed-button"
          style={{
            backgroundColor:
              listexamlist?.status === false ? "gray" : "#009DFF",
            cursor: listexamlist?.status === false ? "not-allowed" : "pointer",
          }}
          onClick={handleButtonClick}
        >
          Resume Test
        </button>
        <Button
          className="buttonnamenextresportwriteexam"
          sx={{ zIndex: 1111 }}
          onClick={() => {
            navigate("/home"); // Replace with your target route
          }}
        >
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default InstructionPending;

import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failureDashboardDataCard,
  successDashboardDataCard,
} from "./DashboardDataCard";

// cards data
const DashboardDataCardApi = () => {
  return api.get("/v1/api/dashboard/examCount");
};

export function* DashboardDataCardCall() {
  try {
    const response: AxiosResponse = yield call(DashboardDataCardApi);
    yield put(successDashboardDataCard(response.data));
  } catch (error) {
    yield put(failureDashboardDataCard(error));
  }
}

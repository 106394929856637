"use client";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import IconButtonField from "../Button/IconButtonField";
import Menus from "../Menu/Menu";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { clearLoginStatus } from "../../Redux/Slices/LoginSlicer/LoginSlicer";
import Avatars from "../Avatar/Avatars";
import "./AppBar.scss";
import { ReactComponent as Logo } from "../../Assets/Images/loginlogo.svg";
import { SvgIcon } from "@mui/material";
import AlterDialog from "../AlterDialog/AlterDialog";
import ButtonField from "../Button/ButtonField";
import { useState } from "react";
import { EditStudentExam } from "../../Redux/Slices/StudentExam/EditStudentExamSlice";
import { EditStudentExamPractice } from "../../Redux/Slices/StudentExamPractice/EditStudentExamPracticeSlice";

export default function AppBarnav() {
  const nav = useNavigate();
  const { state: exam_id } = useLocation();
  console.log(exam_id, "ddd");

  const [ishome, setIshome] = useState<boolean>(false);
  const PendingTime = localStorage.getItem("pending_time");
  const Response = localStorage.getItem("response");
  const question_id = Number(localStorage.getItem("question_id"));
  const Question_Type = Number(localStorage.getItem("question_type"));
  const StudentExamId = Number(localStorage.getItem("student_exam_id"));
  const { data: PopupStudentExamSlice } = useAppSelector(
    (state) => state.PopupStudentExamSlice
  );
  const dispatch = useAppDispatch();
  const Popupdisable = PopupStudentExamSlice;

  const { data: listexamlist } = useAppSelector(
    (state) => state.ListExamIDSlice
  );

  const List: any = listexamlist?.data?.exam_name;
  const Listdata: any = listexamlist?.data;

  return (
    <>
      <Box sx={{ display: "flex", height: "1px" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#FFFFFF",
            display: "flex",
            boxShadow: "0px 4px 40px 0px #0000000D",
          }}
        >
          <Toolbar className="appbarname">
            <Typography variant="h6" noWrap component="div">
              <SvgIcon
                sx={{
                  width: "123px",
                  height: "50px",
                  marginTop: "10px",
                  cursor: exam_id?.examID ? "pointer" : "default",
                }}
                color="primary"
                component={Logo}
                inheritViewBox
                onClick={() => {
                  if (exam_id?.examID) {
                    if (List === "Mini Mock Test") {
                      nav("/home");
                    } else {
                      setIshome(true);
                    }
                  }

                  // if (Listdata && List === "Mini Mock Test") {
                  //   // If Listdata exists and exam_name is "Mini Mock Test"
                  //   nav("/home");
                  // } else if (exam_id?.examID) {
                  //   // If Listdata exists and exam_id is present
                  //   setIshome(true);
                  // } else if (!Listdata) {
                  //   // If Listdata is missing or empty, do nothing (avoid navigation)
                  //   console.log(
                  //     "No data available, staying on the current page"
                  //   );
                  // } else {
                  //   // Default case: Navigate to /home
                  //   nav("/home");
                  // }
                }}
              />
            </Typography>
            <Menus
              menuItems={[
                {
                  menu: "Profile",
                  onClick: () => {
                    nav("/studentprofile");
                  },
                },
                {
                  menu: "Logout",
                  onClick: () => {
                    dispatch(clearLoginStatus());

                    nav("/login");
                  },
                },
              ]}
            >
              <IconButtonField onClick={() => {}}>
                <Avatars sx={{ height: "2rem", width: "2rem" }} />
              </IconButtonField>
            </Menus>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{ flexGrow: 1, height: "100vh", width: "100%" }}
        >
          <Outlet />
        </Box>
      </Box>
      <AlterDialog
        alterheading={"Warning"}
        alterableName={
          "You are currently in the middle of the examination. Please complete it and move forward, or resume the exam from the 'Exams in Progress' section until time is up"
        }
        open={ishome}
        setOpen={setIshome}
        buttonname={
          <>
            <ButtonField
              className="form_save_button"
              onClick={() => {
                if (exam_id?.Practice) {
                  dispatch(
                    EditStudentExamPractice({
                      pending_time: PendingTime,
                      student_test_id: StudentExamId,
                      question_type_id: Question_Type,
                      question_id: question_id,
                      response: Response,
                    })
                  );
                } else {
                  dispatch(
                    EditStudentExam({
                      pending_time: PendingTime,
                      student_exam_id: StudentExamId,
                      question_type_id: Question_Type,
                      question_id: question_id,
                      sentence: Response,
                    })
                  );
                }
                localStorage.removeItem("pending_time");
                localStorage.removeItem("response");
                localStorage.removeItem("question_id");
                localStorage.removeItem("question_type");
                localStorage.removeItem("student_exam_id");

                nav("/home");
                setIshome(false);
              }}
            >
              Yes
            </ButtonField>
            <ButtonField
              className="form_cancel_button"
              onClick={() => {
                setIshome(false);
              }}
            >
              No
            </ButtonField>
          </>
        }
      />
    </>
  );
}

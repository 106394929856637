import { useEffect, useLayoutEffect, useState } from "react";
import "./App.scss";
import SessionExpiredDialog from "./Components/SessionExpired/SessionExpired";
import Sidnavbar from "./Components/Sidnavbar/Sidnavbar";
import { useAppDispatch, useAppSelector } from "./Redux/Store/Hooks";
import api from "./Redux/Axios/middleware";
import { sessionExpired } from "./Redux/Slices/SessionExpired/SessionExpired";
import Loading from "./Components/Loading/Loading";
import NetworkStatus from "./Components/NetworkStatus/NetworkStatus";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { setTheme } from "./Utils/setTheme";
import { decode } from "./Utils/encodeDecode";
import { ILoginResponse } from "./Pages/Login/ILogin";
// import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import { Outlet } from "react-router-dom";
import AppBarnav from "./Components/AppBar/AppBar";

function Home() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { data } = useAppSelector((state) => state.Theme);
  const [details, setDetails] = useState<ILoginResponse>({});

  const skipedURLs = [
    "/v1/api/exam/start",
    "/v1/api/question/prompt",
    "/v1/api/exam_answer/exam-answer",
    "/v1/api/exam/start",
    "/v1/api/exam/startTest",
    "/v1/api/exam/answer",
    "/v1/api/exam/Testanswer",
    "v1/api/question",
  ];

  https: useLayoutEffect(() => {
    // request

    api.interceptors.request.use(
      (config) => {
        if (config.url && skipedURLs.includes(config.url)) {
          setLoading(false);
        } else {
          setLoading(true);
        }

        return config;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    // response
    api.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        // expired token
        if (error.response.status === 401) {
          dispatch(sessionExpired(true));
          return;
        }

        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  useEffect(() => {
    const userDetails = decode(localStorage.getItem("details"));

    setDetails(userDetails);
  }, [data.primary_color]);

  return (
    <>
      <CssBaseline />
      <div className="App">
        {/* <AppBarnav /> */}
        {/* {details.role === null && } */}
        {details.role === "ADMIN" ? <Sidnavbar /> : <AppBarnav />}
        <NetworkStatus />
        <SessionExpiredDialog />
        {loading && <Loading isLoading={loading} />}
      </div>
    </>
  );
}

export default Home;

import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import "./Questions.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IQuestions } from "./IQuestions";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import QuestionsForm from "./QuestionsForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import TableSearch from "../../Components/Table/components/TableSearch";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import IconButtonField from "../../Components/Button/IconButtonField";
import { Button, ButtonGroup, SvgIcon, TextField } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as RefreshButton } from "../../Assets/Images/RefreshButton.svg";
import { listQuestions } from "../../Redux/Slices/Questions/ListQuestionsSlice";
import { clearAddQuestionsResponse } from "../../Redux/Slices/Questions/AddQuestionsSlice";
import {
  clearDeleteQuestionsResponse,
  deleteQuestions,
} from "../../Redux/Slices/Questions/DeleteQuestionsSlice";
import { clearEditQuestionsResponse } from "../../Redux/Slices/Questions/EditQuestionsSlice";
import { listQuestionType } from "../../Redux/Slices/QuestionType/ListQuestionTypeSlice";
import { listSideMenuPermission } from "../../Redux/Slices/SideMenuPermission/ListSideMenuPermissionSlice";
import {
  clearReadingPrompt,
  listReadingPrompt,
} from "../../Redux/Slices/ReadingPrompt/ListReadingPromptSlice";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";

interface QuestionsProps {}
interface Question {
  label: string;
  value: number;
}
interface DifficultyOption {
  label: string;
  value: string;
}

const DifficultyLevel: DifficultyOption[] = [
  { label: "Easy", value: "easy" },
  { label: "Moderate", value: "moderate" },
  { label: "Hard", value: "difficult" },
];
const Questions: FunctionComponent<QuestionsProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IQuestions>({
    Status: true,
  });
  const dispatch = useAppDispatch();
  const { data: listQuestionss } = useAppSelector(
    (state) => state.ListQuestionsSlice
  );
  const { data: addQuestions } = useAppSelector(
    (state) => state.AddQuestionsSlice
  );
  const { data: EditQuestions } = useAppSelector(
    (state) => state.EditQuestionsSlice
  );
  const { data: deleteQuestionss } = useAppSelector(
    (state) => state.DeleteQuestions
  );

  // user permisssion
  const { data: userPermissionlist } = useAppSelector(
    (state) => state.SideMenuPermission
  );
  const navigate = useNavigate();

  const to = "masters";

  const filteredList = userPermissionlist.Dashboard.filter(
    (item: any) => item.to === to
  );
  const fliterdata: any = filteredList[0]?.items;
  const finaldata = fliterdata[1];

  const formRef = useRef<HTMLFormElement>(null);
  const columns: Columns = [
    {
      field: "index",
      headerName: "S.No",

      // renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "question",
      headerName: "Question",
      // renderHeader: () => <b>Questions</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "Question_type_name",
      headerName: "Question Type Name",

      // renderHeader: () => <b>Question Type</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "prompt",
      headerName: "Prompt",

      // renderHeader: () => <b>Prompt</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div>
              {params?.row?.prompt === null ? "-" : params?.row?.prompt}
            </div>
          </>
        );
      },
    },
    {
      field: "instruction",
      headerName: "Instruction",

      // renderHeader: () => <b>Instruction</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div>
              {params?.row?.instruction === null
                ? "-"
                : params?.row?.instruction}
            </div>
          </>
        );
      },
    },
    {
      field: "explanation",
      headerName: "Explanation",
      // renderHeader: () => <b>Explanation</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <div>
              {params?.row?.explanation === null
                ? "-"
                : params?.row?.explanation}
            </div>
          </>
        );
      },
    },

    {
      field: "difficulty_level",
      headerName: "Difficulty Level",
      // renderHeader: () => <b>Difficulty Level</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },

    {
      field: "source_type",
      headerName: "Source Type",
      // renderHeader: () => <b>Source Type</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "state",
      headerName: "Status",
      // renderHeader: () => <b>Status</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",

      cellClassName: (params) =>
        `activename ${params.row.state === "active" ? "active" : "inactive"}`,

      valueFormatter: (params) =>
        params.value === "active" ? "Active" : "Inactive",
    },
    {
      field: "action",
      headerName: "Action",
      // renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IQuestions>) => {
        return (
          <>
            <RemoveRedEyeIcon
              titleAccess="Review Exam question page"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/onlineexamtamplete", {
                  state: params.row,
                });
              }}
            ></RemoveRedEyeIcon>
            <IconButtonField
              disabled={finaldata?.permissions?.edit === false}
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
                dispatch(clearReadingPrompt());
                dispatch(listReadingPrompt({}));
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              disabled={finaldata?.permissions?.delete === false}
              onClick={() => {
                setDeleteData({
                  id: params.id,
                  // name: params?.row?.explanation,
                });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const Questions = Array.isArray(listQuestionss?.data)
    ? listQuestionss?.data.map((user, index) => ({ ...user, index: index + 1 }))
    : [];
  const [isActive, setIsActive] = useState(true);
  const filteredfortrue = Questions?.filter((item) => item.Status === true);
  const [filteredData, setFilteredData] = useState(filteredfortrue);
  useEffect(() => {
    const filteredfortrue = Questions?.filter((item) => item.Status === true);
    setIsActive(true);
    setFilteredData(filteredfortrue);
  }, [listQuestionss?.data]);

  const handleFilter = (status: boolean) => {
    const filtered = Questions?.filter((item) => item.Status === status);
    setFilteredData(filtered);
  };

  useEffect(() => {
    dispatch(listQuestionType());
    // dispatch(listQuestions({}));
    dispatch(listReadingPrompt({}));
    dispatch(listSideMenuPermission());
  }, [dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddQuestionsResponse());
      dispatch(clearEditQuestionsResponse());
      dispatch(clearDeleteQuestionsResponse());
    };
  }, [dispatch]);

  const { data: listQuestionstype } = useAppSelector(
    (state) => state.ListQuestionTypeSlice
  );

  const QuestionList: any = Array.isArray(listQuestionstype?.data)
    ? listQuestionstype?.data
        ?.filter((role) => role.status !== false) // Filter out roles where status is false
        .map((listQuestions) => ({
          label: listQuestions?.name!,
          value: listQuestions.id,
        }))
    : [];
  const [selectedValue, setSelectedValue] = useState<Question | null>(() =>
    JSON.parse(localStorage.getItem("selectedValue") || "null")
  );

  const [selectedLevel, setSelectedLevel] = useState<DifficultyOption | null>(
    () => JSON.parse(localStorage.getItem("selectedLevel") || "null")
  );

  const [formValues, setFormValues] = useState(() => {
    return (
      JSON.parse(localStorage.getItem("formValues") || "{}") || {
        question: "",
        prompt: "",
        instruction: "",
      }
    );
  });

  // Function to trigger API call with proper conditions
  const triggerApiCall = () => {
    const payload: any = {};

    if (selectedLevel) {
      payload.difficulty_level = selectedLevel.value;
    }
    if (selectedValue) {
      payload.questionType = selectedValue.value;
    }
    if (formValues.question || formValues.prompt || formValues.instruction) {
      payload.question = formValues.question;
      payload.prompt = formValues.prompt;
      payload.instruction = formValues.instruction;
    }

    // If at least one field is filled, dispatch API call
    dispatch(listQuestions(Object.keys(payload).length > 0 ? payload : {}));
  };

  // Call API when dependencies change
  useEffect(() => {
    triggerApiCall();
  }, [
    selectedValue,
    selectedLevel,
    formValues,
    addQuestions,
    EditQuestions,
    deleteQuestionss,
  ]);

  const handleChange =
    (field: "question" | "prompt" | "instruction") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setFormValues((prev: any) => {
        const updated = { ...prev, [field]: value };
        localStorage.setItem("formValues", JSON.stringify(updated));
        return updated;
      });
    };
  return (
    <>
      <Table
        rows={filteredData ?? []}
        columns={columns}
        disableColumnMenu={false}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      >
        <CustomToolbar sx={{ display: "block" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <h6>Questions</h6>
            {/* Active & Inactive Button */}

            <ButtonGroup
              variant="contained"
              className="active_Btn"
              sx={{
                border: "none", // Ensures no border around the ButtonGroup
                "& .MuiButton-root": {
                  // Targets individual Button components inside the ButtonGroup
                  border: "none", // Removes the border from buttons
                },
              }}
            >
              <Button
                className="active_Btn"
                onClick={() => {
                  handleFilter(true);
                  setIsActive(true);
                }}
                sx={{
                  backgroundColor: isActive === true ? "#1976d2" : "#fff",
                  color: isActive === true ? "#fff" : "#000",
                  "&:hover": {
                    backgroundColor: isActive === true ? "#1976d2" : "#f5f5f5",
                  },
                }}
              >
                Active
              </Button>

              <Button
                onClick={() => {
                  handleFilter(false);
                  setIsActive(false);
                }}
                sx={{
                  backgroundColor: isActive === false ? "#1976d2" : "#fff",
                  color: isActive === false ? "#fff" : "#000",
                  "&:hover": {
                    backgroundColor: isActive === false ? "#1976d2" : "#f5f5f5",
                  },
                }}
              >
                Inactive
              </Button>
            </ButtonGroup>
            <TableSearch />
            <ButtonField
              disabled={finaldata?.permissions?.add === false}
              className="tabel_add_new_button"
              onClick={() => setDialogOpen(true)}
              toolTip="Add new"
            >
              Add new
            </ButtonField>
            <IconButtonField
              className="refresh_btn"
              toolTip="Refresh"
              onClick={() => dispatch(listQuestions({}))}
            >
              <SvgIcon
                className="refresh_svg_icon"
                component={RefreshButton}
                inheritViewBox
              />
            </IconButtonField>
          </div>
          <br></br>
          <div style={{ display: "flex", gap: "10px" }}>
            <FormControl
              size="small"
              variant="outlined"
              sx={{ width: "200px", marginRight: "10px" }}
            >
              <InputLabel id="question-select-label">
                Select Question Type
              </InputLabel>
              <Select
                label="Select Question Type"
                labelId="question-select-label"
                value={selectedValue ? selectedValue.value : ""}
                onChange={(e) => {
                  const selected =
                    QuestionList?.find(
                      (q: any) => q?.value === e.target.value
                    ) || null;
                  setSelectedValue(selected);
                  localStorage.setItem(
                    "selectedValue",
                    JSON.stringify(selected)
                  );
                  setSelectedLevel(null);
                  setFormValues({ question: "", prompt: "", instruction: "" });
                  localStorage.setItem(
                    "formValues",
                    JSON.stringify({
                      question: "",
                      prompt: "",
                      instruction: "",
                    })
                  );
                }}
              >
                {QuestionList?.length > 0 ? (
                  QuestionList?.map((question: any) => (
                    <MenuItem key={question.value} value={question.value}>
                      {question.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Questions Available</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl
              size="small"
              variant="outlined"
              sx={{ width: "200px", marginRight: "10px" }}
            >
              <InputLabel id="difficulty-select-label">
                Select Difficulty Level
              </InputLabel>
              <Select
                // size="small"
                // variant="outlined"
                label="Select Difficulty Level"
                labelId="difficulty-select-label"
                value={selectedLevel ? selectedLevel.value : ""}
                onChange={(e) => {
                  const selected =
                    DifficultyLevel?.find(
                      (q: any) => q?.value === e.target.value
                    ) || null;
                  setSelectedLevel(selected);
                  localStorage.setItem(
                    "selectedLevel",
                    JSON.stringify(selected)
                  );
                }}
              >
                {DifficultyLevel?.length > 0 ? (
                  DifficultyLevel?.map((question: any) => (
                    <MenuItem key={question.value} value={question.value}>
                      {question.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Questions Available</MenuItem>
                )}
              </Select>
            </FormControl>

            <TextField
              sx={{ marginRight: "10px" }}
              size="small"
              id="question-field"
              label="Question"
              variant="outlined"
              value={formValues.question}
              onChange={handleChange("question")}
            />
            <TextField
              size="small"
              sx={{ marginRight: "10px" }}
              id="prompt-field"
              label="Prompt"
              variant="outlined"
              value={formValues.prompt}
              onChange={handleChange("prompt")}
            />
            <TextField
              size="small"
              id="instruction-field"
              label="Instruction"
              variant="outlined"
              value={formValues.instruction}
              onChange={handleChange("instruction")}
            />
          </div>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={`${initialValues?.id ? "Update" : "Add"} Questions`}
        Dialog_Content={
          <QuestionsForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={addQuestions} parentDialog={setDialogOpen} />
      <ResponseDialog response={EditQuestions} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteQuestions(deleteDialogData.id));
        }}
        response={deleteQuestionss}
      />
    </>
  );
};

export default Questions;

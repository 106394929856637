import { AxiosResponse } from "axios";
import { call, delay, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import { IOpenSection } from "../../../Pages/Exam/IExam";
import {
  failureaddOpenSection,
  successaddOpenSection,
} from "./AddOpenSectionSlice";

// addOpenSection
const AddOpenSectionApi = (action: PayloadAction<IOpenSection>) => {
  return api.post("/v1/api/exam/openSection", action.payload);
};

export function* AddOpenSectionCall(action: PayloadAction<IOpenSection>) {
  try {
    const response: AxiosResponse = yield call(AddOpenSectionApi, action);
    yield put(successaddOpenSection(response.data));
  } catch (error) {
    yield put(failureaddOpenSection(error));
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";

export const EditPracticeExamSlice = createSlice({
  name: "EditPracticeExamSlice",
  initialState: {} as IReduxState<IPracticeExam>,
  reducers: {
    EditPracticeExam: (state, _: PayloadAction<IPracticeExam>) => {
      state.isLoading = true;
    },
    successEditPracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditPracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditPracticeExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditPracticeExam,
  failureEditPracticeExam,
  successEditPracticeExam,
  clearEditPracticeExamResponse,
} = EditPracticeExamSlice.actions;

export default EditPracticeExamSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReadingPrompt } from "../../../Pages/DashboardUser/IDashboardUser";

export const ReadingPromptSlice = createSlice({
  name: "ReadingPromptSlice",
  initialState: {} as IReduxState<IReadingPrompt>,
  reducers: {
    listReadingPrompt: (state, _: PayloadAction<IReadingPrompt>) => {
      state.isLoading = true;
    },
    successlistReadingPrompt: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistReadingPrompt: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearReadingPrompt: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistReadingPrompt,
  listReadingPrompt,
  clearReadingPrompt,
  successlistReadingPrompt,
} = ReadingPromptSlice.actions;

export default ReadingPromptSlice.reducer;

import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Pagination,
  Typography,
  Grid,
  SvgIcon,
  Box,
  Drawer,
  Divider,
  Snackbar,
  PaginationItem,
} from "@mui/material";
import "./ReExam.scss"; // For additional styling
import { ReactComponent as Deleteicon } from "../../Assets/Images/delete.svg";
import { IListInterface, IQuestionType } from "./IReExam";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addExamAnswer } from "../../Redux/Slices/ExamAnswer/AddExamAnswerSlice";
import { listEvaluation } from "../../Redux/Slices/Exam/ListEvaluationSlice";
import { listExamAnswer } from "../../Redux/Slices/ExamAnswer/ListExamAnswerSlice";
import { EditExamAnswer } from "../../Redux/Slices/ExamAnswer/EditExamAnswerSlice";
import { ILoginResponse } from "../Login/ILogin";
import { decode, encode } from "../../Utils/encodeDecode";
import { listExamsubmit } from "../../Redux/Slices/ExamAnswer/ListExamsubmitSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import MessageDialog from "../../Components/MessageDialog/MessageDialog";
import AlterDialog from "../../Components/AlterDialog/AlterDialog";
import ButtonField from "../../Components/Button/ButtonField";
import { listReExamID } from "../../Redux/Slices/ReExam/ListReExamSlice";
// dialog message
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ZodNullable } from "zod";
import { listStudentExam } from "../../Redux/Slices/StudentExam/ListStudentExamSlice";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { addCloseSection } from "../../Redux/Slices/Exam/AddCloseSectionSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill CSS for styling
import QuillEditor from "quill";
import InfoIcon from "@mui/icons-material/Info";
import { addOpenSection } from "../../Redux/Slices/OpenSection/AddOpenSectionSlice";

interface Question {
  id: number;
  question_type: string;
  question: string;
  prompt: string;
  instruction: string;
  question_url: string;
  question_type_id?: number;
  options: { optionname: string; option_id: number }[];
  optionsurl: { optionname: string; option_id: number }[];
}

// Add font size options dynamically
const FontSize = ReactQuill.Quill.import("formats/size");
FontSize.whitelist = ["small", false, "large", "huge"];
ReactQuill.Quill.register(FontSize, true);

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: ["small", false, "large", "huge"] }], // Added font size options
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    // ["link", "image", "video"],
    // ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  // "link",
  // "image",
  // "video",
];

const ReExam: React.FC = () => {
  // overall data list
  const { data: listReExamIDSlice } = useAppSelector(
    (state) => state.ListReExamIDSlice
  );
  const List: any = listReExamIDSlice;
  const ListExam: any = listReExamIDSlice;

  const navigate = useNavigate();
  const { state: exam_id } = useLocation();

  // data list  option
  const { data: listExamAnswerSlice, isLoading: answerSliceLoading } =
    useAppSelector((state) => state.ListExamAnswerSlice);
  const Listoption: any = listExamAnswerSlice?.data;

  const dispatch = useAppDispatch();

  const { data: listStudentExamSlice } = useAppSelector(
    (state) => state.ListStudentExamSlice
  );
  const { data: AddStudentExamSlice } = useAppSelector(
    (state) => state.AddStudentExamSlice
  );
  const AddStudentExam: any = AddStudentExamSlice;

  const { data: addExamAnswerRes, isLoading: addExamAnswerLoading } =
    useAppSelector((state) => state.AddExamAnswerSlice);

  const { data: editExamAnswerRes, isLoading: editExamAnswerLoading } =
    useAppSelector((state) => state.EditExamAnswerSlice);

  const ListStudentExam: any = listStudentExamSlice?.data;

  const { data: ListExamAnswerSlice } = useAppSelector(
    (state) => state.ListExamAnswerSlice
  );
  const StudentExamList: any = ListExamAnswerSlice?.data;

  // dialog  list
  const [openmessage, setOpenmessage] = React.useState(false);
  //   dialog box
  // const [open, setOpen] = React.useState(false);
  // const toggleDrawer = (newOpen: boolean) => () => {
  //   setOpen(newOpen);
  // };

  // const handleClose = () => {
  //   setOpenmessage(false);
  //   setOpen(true);
  //   setIsSubmitted(false);
  // };

  function handleListItemClick(question_type_id: number) {
    // Find the index of the first question with the matching question_type_id
    const questionIndex = List?.data?.questions.findIndex(
      (question: any) => question.question_type_id === question_type_id
    );
    setCurrentQuestion(questionIndex + 1);
  }

  // useEffect(() => {
  //   if (AddStudentExam && AddStudentExam.status) {
  //     // Call listStudentExam only if addStudentExam response is successful
  //     dispatch(listStudentExam());
  //   }
  // }, [AddStudentExam, dispatch]);

  useEffect(() => {
    dispatch(listReExamID({ id: exam_id?.examID }));
    dispatch(listStudentExam());
  }, []);

  const [questions, setQuestions] = useState<Question[]>([]); // Initialize with predefined data
  const [selectedIndex, setSelectedIndex] = useState(0); // Start with the first question type (0-based index)

  const [currentQuestionTypeIndex, setCurrentQuestionTypeIndex] =
    useState<number>(
      parseInt(localStorage.getItem("sectionreexamId") ?? "0") ?? 0
    ); // Tracks current question type

  useEffect(() => {
    // setSearchParams({ id: encode(currentQuestionTypeIndex) });
    localStorage.setItem(
      "sectionreexamId",
      currentQuestionTypeIndex.toString()
    );
  }, [currentQuestionTypeIndex]);

  useEffect(() => {
    !!localStorage.getItem("sectionreexamId") &&
      setCurrentQuestionTypeIndex(
        parseInt(localStorage.getItem("sectionreexamId")!)
      );
  }, []);

  function formatTime(timeInSeconds: number) {
    const hours = Math.floor(timeInSeconds / 3600); // Calculate total hours
    const minutes = Math.floor((timeInSeconds % 3600) / 60); // Remaining minutes after hours

    let result = "";
    if (hours > 0) {
      result += `${hours} hour${hours !== 1 ? "s" : ""}`;
    }
    if (minutes > 0) {
      if (result) result += " and "; // Add "and" if both hours and minutes are present
      result += `${minutes} minute${minutes !== 1 ? "s" : ""}`;
    }

    return result || "0 minutes"; // Default to "0 minutes" if no time
  }

  const currentType = List?.data?.questions_by_type?.[currentQuestionTypeIndex];
  const [timeget, Settimeget] = useState(
    currentType?.duration ||
      formatTime(parseInt(localStorage.getItem("remain_time") ?? "5 minutes"))
  );

  function convertToSeconds(time: string): number {
    if (!time || typeof time !== "string") {
      throw new Error("Time input is undefined or not a string");
    }

    const timeParts = time.split(" ");

    // Ensure we have both a number and a unit
    if (timeParts.length < 2) {
      throw new Error(
        "Invalid time format. Expected format: '<value> <unit>' (e.g., '5 minutes')"
      );
    }

    const value = parseInt(timeParts[0]);
    const unit = timeParts[1].toLowerCase();

    if (isNaN(value)) {
      throw new Error(`Invalid number format in time input: '${timeParts[0]}'`);
    }

    // Supported units
    const validUnits = ["minute", "minutes", "hour", "hours"];

    if (!validUnits.some((validUnit) => unit.includes(validUnit))) {
      throw new Error(
        `Invalid time unit '${unit}'. Supported units are 'minutes' or 'hours'.`
      );
    }

    if (unit.includes("minute")) {
      return value * 60; // Convert minutes to seconds
    } else if (unit.includes("hour")) {
      return value * 60 * 60; // Convert hours to seconds
    }

    // Default fallback (shouldn't reach here due to earlier checks)
    throw new Error("Unexpected error in time conversion");
  }

  const [timeLeft, setTimeLeft] = useState<number>(convertToSeconds(timeget));

  useEffect(() => {
    const currentType =
      List?.data?.questions_by_type?.[currentQuestionTypeIndex];

    if (currentType?.questions) {
      const firstQuestionType = currentType?.questions?.map(
        (question: any) => ({
          id: question?.question_id,
          question_type: question?.question_type_name,
          question_type_id: question?.question_type_id,
          question_url: question?.question_url,
          question: question?.question_text,
          prompt: question?.prompt,
          instruction: question?.instruction,
          options: question?.options.map((opt: any) => ({
            optionname: opt?.option_text,
            option_id: opt?.option_id,
          })),
          optionsurl: question?.options.map((opt: any) => ({
            optionname: opt?.option_url === null ? null : opt?.option_url,
            option_id: opt?.option_id,
          })),
        })
      );

      setQuestions(firstQuestionType);
    } else {
      console.warn(
        `No questions found for question type index: ${currentQuestionTypeIndex}`
      );
      setQuestions([]); // Reset questions state if no data
    }
    // time set
    if (currentType?.duration) {
      const minutes = currentType?.duration || "3 minutes";
      Settimeget(minutes);
    }
  }, [List, currentQuestionTypeIndex]);

  const [currentQuestion, setCurrentQuestion] = useState<number>(1);
  const [selectedAnswersOption, setSelectedAnswersOption] = useState<
    Record<number, Record<number, string>>
  >({});

  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: number]: string | null;
  }>({});

  const [isExamOver, setIsExamOver] = useState<boolean>(false);
  const [istimeOver, setIstimeOver] = useState<boolean>(false);
  const [istimeOvers, setIstimeOvers] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // Section List
  const [isSection, setIsSection] = useState<boolean>(false);
  const [isSectionnext, setIsSectionnext] = useState<boolean>(false);

  useEffect(() => {
    // Reset time when question type changes
    if (currentType?.duration) {
      const newTimeLeft = convertToSeconds(currentType.duration);
      setTimeLeft(newTimeLeft);
    }
  }, [currentQuestionTypeIndex]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    const handleTabChange = () => {
      if (document.hidden) {
        setIstimeOvers(true);
      }
    };
    document.addEventListener("visibilitychange", handleTabChange);

    const preventCopyPaste = (e: ClipboardEvent) => {
      e.preventDefault();
    };
    document.addEventListener("copy", preventCopyPaste);
    document.addEventListener("cut", preventCopyPaste);
    document.addEventListener("paste", preventCopyPaste);

    // Disable spellcheck, autocorrect, autocapitalize, and Grammarly
    const disableTextFieldAttributes = () => {
      const inputs = document.querySelectorAll("input, textarea");
      inputs.forEach((input) => {
        input.setAttribute("spellcheck", "false");
        input.setAttribute("autocorrect", "off");
        input.setAttribute("autocapitalize", "off");
        input.setAttribute("data-gramm", "false"); // Grammarly
      });
    };
    disableTextFieldAttributes();

    if (timeLeft === 0) {
      setIstimeOver(true);
    }
    localStorage.setItem("remain_time", timeLeft.toString());
    return () => {
      clearInterval(timer);
      document.removeEventListener("visibilitychange", handleTabChange);
      document.removeEventListener("copy", preventCopyPaste);
      document.removeEventListener("cut", preventCopyPaste);
      document.removeEventListener("paste", preventCopyPaste);
    };
  }, [timeLeft]);

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  // is for section pagenation
  useEffect(() => {
    setCurrentQuestion(1); // Reset to the first question in the new section
  }, [currentQuestionTypeIndex]);

  const handleAnswerSelect = (questionId: number, option: string) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: option,
    }));
  };

  const clearSelection = () => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion]: "",
    }));

    setSelectedAnswersOption((prev) => ({
      ...prev,
      [currentQuestionTypeIndex]: {
        ...(prev[currentQuestionTypeIndex] || {}),
        [currentQuestion]: "",
      },
    }));
  };

  const [wordCount, setWordCount] = useState(0);

  const handleChange = (content: any) => {
    setEditorValue(content);

    // Calculate word count (ignoring HTML tags)
    const textContent = content.replace(/<[^>]*>/g, ""); // Remove HTML tags
    const words = textContent.trim().split(/\s+/); // Split by whitespace
    setWordCount(words.filter((word: any) => word.length > 0).length);
  };
  const quillRef = useRef<ReactQuill | null>(null); // Type the ref as ReactQuill or null

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor(); // Access the Quill editor instance
      const editableDiv = editor.root; // Get the contentEditable div
      if (editableDiv) {
        editableDiv.setAttribute("spellcheck", "false"); // Disable spellcheck
      }
    }
  }, []);

  const renderQuestionOptions = (
    questionId: number,
    question_type: string,
    options: { optionname: string; option_id: number }[],
    optionsurl: {
      optionname: string;
      option_id: number;
    }[] // Assuming you want to access option_url
  ) => {
    const hasNullUrl = optionsurl.some((option) => option.optionname === null);

    return (
      <Grid
        container
        spacing={
          ["Reading Comprehension"].includes(
            List?.data?.questions_by_type?.[currentQuestionTypeIndex]
              ?.question_type_name
          )
            ? 3
            : 1
        }
        sx={{
          ...(["Reading Comprehension", "Writing"].includes(
            List?.data?.questions_by_type?.[currentQuestionTypeIndex]
              ?.question_type_name
          )
            ? {
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }
            : {
                backgroundColor: "white",
                marginBottom: "40px",
                position: "fixed",
                bottom: "0",
                padding: "0% 8% 4% 8%",
              }),
        }}
      >
        {["Writing"].includes(
          List?.data?.questions_by_type?.[currentQuestionTypeIndex]
            ?.question_type_name
        ) ? (
          <>
            <ReactQuill
              className="custom-quill"
              style={{
                width: "100%",
                padding: "20px 0px 20px 8px",
                height: "50vh",
              }}
              theme="snow"
              value={editorValue}
              onChange={handleChange}
              modules={modules}
              formats={formats}
              tabIndex={0} // Ensure it's focusable without enabling spellcheck
              placeholder="Write something here..."
              preserveWhitespace
              data-gramm="false"
              onFocus={() => {
                // Ensure the underlying contenteditable disables spellcheck and autocorrect
                const editorElement = document.querySelector(".ql-editor");
                if (editorElement) {
                  editorElement.setAttribute("spellcheck", "false");
                  editorElement.setAttribute("autocorrect", "off");
                  editorElement.setAttribute("autocapitalize", "off");
                  editorElement.setAttribute("data-gramm", "false"); // Disable Grammarly
                }
              }}
            />
          </>
        ) : hasNullUrl ? (
          options.map((option, index) => (
            <Grid item xs={6} key={index} className="gridwidth">
              <div className="Optionnameflex">
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    fontWeight: "400",
                    textTransform: "capitalize",
                    color: "blue",
                  }}
                >
                  {`${String.fromCharCode(97 + index)})`}
                </span>
                <Box
                  onClick={() => {
                    // clearSelection();
                    setSelectedAnswersOption((prev) => ({
                      ...prev,
                      [currentQuestionTypeIndex]: {
                        ...(prev[currentQuestionTypeIndex] || {}),
                        [currentQuestion]: option.optionname,
                      },
                    }));
                    handleAnswerSelect(questionId, option.optionname);
                  }}
                  sx={{
                    border: "1px solid #CDCDCD",
                    borderRadius: "6px",
                    padding:
                      question_type === "Reading Comprehension"
                        ? "10px"
                        : "3px",
                    cursor: "pointer",
                    textAlign: "left",
                    fontSize: "13px",
                    width: "100%",

                    color:
                      selectedAnswers[questionId] === option.optionname ||
                      selectedAnswersOption[currentQuestionTypeIndex]?.[
                        currentQuestion
                      ] === option.optionname
                        ? "white"
                        : "black",
                    backgroundColor:
                      selectedAnswers[questionId] === option.optionname ||
                      selectedAnswersOption[currentQuestionTypeIndex]?.[
                        currentQuestion
                      ] === option.optionname
                        ? "#88B61E"
                        : "white",
                    "&:hover": {
                      backgroundColor:
                        selectedAnswers[questionId] === option.optionname ||
                        selectedAnswersOption[currentQuestionTypeIndex]?.[
                          currentQuestion
                        ] === option.optionname
                          ? "#88B61E"
                          : "white",
                    },
                    display: "flex", // Makes the content centered
                    alignItems: "center", // Vertically centers content
                    height: "100%", // Fills the available height
                  }}
                >
                  {option.optionname}
                </Box>
              </div>
            </Grid>
          ))
        ) : (
          optionsurl.map((option, index) => (
            <Grid item xs={6} key={index}>
              <Box
                onClick={() => {
                  setSelectedAnswersOption((prev) => ({
                    ...prev,
                    [currentQuestionTypeIndex]: {
                      ...(prev[currentQuestionTypeIndex] || {}),
                      [currentQuestion]: option.optionname,
                    },
                  }));
                  handleAnswerSelect(questionId, option.optionname);
                }}
                sx={{
                  border: "1px solid #CDCDCD",
                  borderRadius: "6px",
                  padding: "3px",
                  cursor: "pointer",
                  textAlign: "center",
                  color:
                    // option.chosen ||
                    selectedAnswers[questionId] === option.optionname ||
                    selectedAnswersOption[currentQuestionTypeIndex]?.[
                      currentQuestion
                    ] === option.optionname
                      ? "white"
                      : "black",
                  backgroundColor:
                    // option.chosen ||
                    selectedAnswers[questionId] === option.optionname ||
                    selectedAnswersOption[currentQuestionTypeIndex]?.[
                      currentQuestion
                    ] === option.optionname
                      ? "#88B61E"
                      : "white",
                  "&:hover": {
                    backgroundColor:
                      // option.chosen ||
                      selectedAnswers[questionId] === option.optionname ||
                      selectedAnswersOption[currentQuestionTypeIndex]?.[
                        currentQuestion
                      ] === option.optionname
                        ? "#88B61E"
                        : "white",
                  },
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      fontWeight: "400",
                      textTransform: "capitalize",
                    }}
                  >
                    {String.fromCharCode(97 + index)}
                  </div>
                  <img
                    src={option.optionname}
                    alt={`Uploaded ${index}`}
                    style={{ maxWidth: "100%", height: "50px" }}
                  />
                </div>
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    );
  };

  // Function to count occurrences of each question type in StudentExamList
  // const countQuestionsByType = (questionTypeId: any) => {
  //   return StudentExamList?.filter(
  //     (q: any) => q?.questionType_id === questionTypeId
  //   ).length;
  // };

  // To get the selected option for the current question
  const selectedOptionForCurrentQuestion = selectedAnswers[currentQuestion];
  const filterquestionlist = Listoption?.filter((data: any) => {
    return data?.question_id === questions[currentQuestion - 1]?.id;
  });

  // Check if selectedOptionForCurrentQuestion is a URL (blob or regular URL)

  const isUrl =
    selectedOptionForCurrentQuestion?.startsWith("https://") ||
    selectedOptionForCurrentQuestion?.startsWith("http://");

  // Use the appropriate filter based on whether it's a URL or not
  const selectedOptionFilterValue = isUrl
    ? questions[currentQuestion - 1]?.optionsurl?.filter(
        (option) => selectedOptionForCurrentQuestion === option?.optionname
      )
    : questions[currentQuestion - 1]?.options?.filter(
        (option) => selectedOptionForCurrentQuestion === option?.optionname
      );

  // question image show
  const imageFile = questions[currentQuestion - 1]?.question_url;

  useEffect(() => {
    dispatch(
      listExamAnswer({
        student_exam_id: ListStudentExam?.id,
      })
    );
  }, [addExamAnswerRes, editExamAnswerRes]);
  useEffect(() => {
    if (
      !answerSliceLoading &&
      !addExamAnswerLoading &&
      !editExamAnswerLoading &&
      isSubmitted
    ) {
      const sectionlist = List?.data?.questions_by_type?.map(
        (questionType: any) => {
          const { question_type_id, question_type_name, question_count } =
            questionType;

          // Filter data to count and check for skipped questions for this question type
          const matchingQuestions = StudentExamList?.filter(
            (item: any) => item?.questionType_id === question_type_id
          );
          const actualCount = matchingQuestions?.length;
          const skippedCount = matchingQuestions?.filter(
            (item: any) => item?.skipped
          ).length;

          // Determine if this type has pending questions
          const hasPending = actualCount !== question_count || skippedCount > 0;
          return hasPending;
        }
      );

      if (sectionlist.includes(true)) {
        setOpenmessage(true);
      } else {
        setIsExamOver(true);
        setOpenmessage(false);
      }
    }
  }, [addExamAnswerRes, editExamAnswerRes, listExamAnswerSlice, isSubmitted]);

  // timeing method
  const timing = `${Math.floor(timeLeft / 60)}:${String(timeLeft % 60).padStart(
    2,
    "0"
  )}`;
  const [lastMinters, setLastMinters] = useState<boolean>(false);
  const [anchorPosition, setAnchorPosition] = useState<{
    vertical: "top" | "bottom";
    horizontal: "left" | "center" | "right";
  }>({
    vertical: "top",
    horizontal: "right",
  });

  useEffect(() => {
    localStorage.setItem(
      "question_type",
      questions[currentQuestion - 1]?.question_type_id?.toString() ?? ""
    );
    localStorage.setItem("response", editorValue);
    localStorage.setItem("pending_time", timing);
    localStorage.setItem("student_exam_id", ListStudentExam?.id);
    if (timing === "3:00") {
      // This function should be called when timing is "5:00"
      const newPosition: any = { vertical: "top", horizontal: "right" };
      setAnchorPosition(newPosition); // Set the position for the Snackbar
      setLastMinters(true); // Open the Snackbar
    }
  }, [timing]);

  // Reload  page

  const [showModal, setShowModal] = useState(false);
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    // Prevent the default unload action and show the modal
    setShowModal(true);
    event.preventDefault();
    // Return empty string for some browsers (like Chrome) to display the confirmation dialog
    // event.returnValue = "";
  };
  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) =>
      handleBeforeUnload(event);

    window.addEventListener("beforeunload", beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, []);

  // Text Editor
  const [editorValue, setEditorValue] = useState<string>(""); // State to store editor value

  return (
    <>
      <div style={{ height: "50px" }} />
      <div className="unselectable">
        <div className="onlineexamdiv">
          <div className="Questiontype">
            <div className="examtext">{List?.data?.exam_name}</div> &nbsp; :
            &nbsp;
            <div className="Questiontypeheading">
              {questions[currentQuestion - 1]?.question_type}
            </div>
            <div className="onlineexmcount">
              Q. {currentQuestion} of {questions?.length}
            </div>
          </div>
          <div className="Questiontype">
            {/* <div className="">
              <Button onClick={toggleDrawer(true)} className="buttonsection">
                Section
              </Button>
            </div> */}
            <div className="onlineexamtime">
              {Math.floor(timeLeft / 60)}:
              {String(timeLeft % 60).padStart(2, "0")}
            </div>
          </div>
        </div>
        {["Reading Comprehension", "Writing"].includes(
          List?.data?.questions_by_type?.[currentQuestionTypeIndex]
            ?.question_type_name
        ) ? (
          <div className="overonlinediv">
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                width: "50%",
                borderRight: "1px solid #cecece",
              }}
            >
              <div className="examscrollreading">
                <Typography variant="h5" sx={{ paddingRight: "10px" }}>
                  {questions[currentQuestion - 1]?.question_type ===
                  "Writing" ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          textAlign: "justify",
                          fontWeight: 600,
                        }}
                      >
                        Use this idea as the basis for a piece of writing. You
                        may write in any style.
                      </Typography>
                      {questions[currentQuestion - 1]?.question_url && (
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "left",
                            position: "relative", // Ensure proper positioning of the image
                            overflow: "visible", // Ensure the enlarged image is not clipped
                            "& img": {
                              maxWidth: "100%",
                              height:
                                questions[currentQuestion - 1]
                                  ?.question_type === "Writing"
                                  ? "50rem"
                                  : "100%",
                              cursor: "pointer",
                              transition: "transform 0.3s ease",
                              position: "relative",
                              transformOrigin: "left center", // Keep the hover scaling aligned to the left
                            },
                            "& img:hover": {
                              transform: "scale(1.5)",
                            },
                          }}
                        >
                          <img src={imageFile} alt="Uploaded preview" />
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "justify",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {questions[currentQuestion - 1]?.prompt}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "justify",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {questions[currentQuestion - 1]?.instruction}
                      </Typography>
                    </>
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{ width: "50%", padding: "10px" }}>
              <div className="examscrollreading">
                <Typography variant="h5">
                  {questions[currentQuestion - 1]?.question_type ===
                    "Reading Comprehension" && (
                    <>
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          textAlign: "justify",
                          fontWeight: 600,
                        }}
                      >
                        {questions[currentQuestion - 1]?.question}
                      </Typography>

                      {questions[currentQuestion - 1]?.question_url && (
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "left",
                            position: "relative", // Ensure proper positioning of the image
                            overflow: "visible", // Ensure the enlarged image is not clipped
                            "& img": {
                              maxWidth: "100%",
                              height:
                                questions[currentQuestion - 1]
                                  ?.question_type === "Writing"
                                  ? "30rem"
                                  : "100%",
                              cursor: "pointer",
                              transition: "transform 0.3s ease",
                              position: "relative",
                              transformOrigin: "left center", // Keep the hover scaling aligned to the left
                            },
                            "& img:hover": {
                              transform: "scale(1.5)",
                            },
                          }}
                        >
                          <img src={imageFile} alt="Uploaded preview" />
                        </Typography>
                      )}
                    </>
                  )}

                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: "20px",
                      textAlign: "justify",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {List?.data?.questions?.length === 0 ? (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "larger",
                          marginTop: "30%",
                        }}
                      >
                        No Question Found
                      </div>
                    ) : (
                      renderQuestionOptions(
                        currentQuestion,
                        questions[currentQuestion - 1]?.question_type,
                        questions[currentQuestion - 1]?.options || [],
                        questions[currentQuestion - 1]?.optionsurl || []
                      )
                    )}{" "}
                    {questions[currentQuestion - 1]?.question_type ===
                      "Writing" &&
                      wordCount < 120 && (
                        <div className="vaidationforwriting">
                          <InfoIcon />
                          <div>
                            {" "}
                            Please write a minimum of 120 words to submit the
                            exam
                          </div>
                        </div>
                      )}
                  </Typography>
                </Typography>
              </div>
            </Grid>
          </div>
        ) : (
          <>
            <div className="overonlinediv">
              <Grid item xs={12} md={6} sx={{ width: "100%" }}>
                <div className="examscroll">
                  <Typography variant="h5">
                    {questions[currentQuestion - 1]?.question_type ===
                    "Writing" ? (
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          textAlign: "justify",
                          fontWeight: 600,
                        }}
                      >
                        Use this idea as the basis for a piece of writing. You
                        may write in any style.
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "justify",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {questions[currentQuestion - 1]?.prompt}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "justify",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {questions[currentQuestion - 1]?.instruction}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1.3rem",
                            marginBottom: "20px",
                            textAlign: "justify",
                            fontWeight: 600,
                          }}
                        >
                          {questions[currentQuestion - 1]?.question}
                        </Typography>
                      </>
                    )}

                    {questions[currentQuestion - 1]?.question_url && (
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "left",
                          position: "relative", // Ensure proper positioning of the image
                          overflow: "visible", // Ensure the enlarged image is not clipped
                          "& img": {
                            maxWidth: "100%",
                            height:
                              questions[currentQuestion - 1]?.question_type ===
                              "Writing"
                                ? "30rem"
                                : "100%",
                            cursor: "pointer",
                            transition: "transform 0.3s ease",
                            position: "relative",
                            transformOrigin: "left center", // Keep the hover scaling aligned to the left
                          },
                          "& img:hover": {
                            transform: "scale(1.5)",
                          },
                        }}
                      >
                        <img src={imageFile} alt="Uploaded preview" />
                      </Typography>
                    )}
                  </Typography>
                </div>
              </Grid>
            </div>
            {List?.data?.questions?.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "larger",
                  marginTop: "30%",
                }}
              >
                No Question Found
              </div>
            ) : (
              renderQuestionOptions(
                currentQuestion,
                questions[currentQuestion - 1]?.question_type,
                questions[currentQuestion - 1]?.options || [],
                questions[currentQuestion - 1]?.optionsurl || []
              )
            )}
          </>
        )}
        <div className="navigation-wrapper"></div>
        <div className="navigation-buttons">
          <div>
            {questions[currentQuestion - 1]?.question_type ===
            "Writing" ? null : (
              <Button
                onClick={clearSelection}
                sx={{ textTransform: "capitalize", fontSize: "14px" }}
              >
                <SvgIcon
                  component={Deleteicon}
                  inheritViewBox
                  sx={{ marginRight: "5px" }}
                />
                Clear
              </Button>
            )}
          </div>
          {/* <Pagination
            count={questions?.length}
            variant="outlined"
            siblingCount={5} 
            boundaryCount={3} 
        
          /> */}
          <Pagination
            count={questions?.length}
            variant="outlined"
            siblingCount={6} // Display all sibling pages
            boundaryCount={1} // Show all boundary pages
            page={currentQuestion} // Set the current page to be visible
            onChange={(event, value) => setCurrentQuestion(value)} // Update `currentQuestion` on change
            renderItem={(item: any) => {
              // Exclude previous and next buttons
              if (item.type === "previous" || item.type === "next") return null;

              // Check if the current page has an answer
              const currentSectionAnswers =
                selectedAnswersOption[currentQuestionTypeIndex] || {};
              const isAnswered = Boolean(currentSectionAnswers[item.page]);
              const color = isAnswered ? "blue" : "#E7E7E7";

              return (
                <Button
                  key={item.page}
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    color: item.page === currentQuestion ? "black" : "white",
                    backgroundColor:
                      item.page === currentQuestion ? "blue" : color,
                    minWidth: "unset",
                    padding: 0,
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}
                  onClick={() => setCurrentQuestion(item.page)} // Navigate to the selected question
                >
                  {item.page}
                </Button>
              );
            }}
          />

          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              className="buttonnameprevious"
              sx={{ color: "#009DFF" }}
              onClick={() =>
                setCurrentQuestion((prev) => Math.max(prev - 1, 1))
              }
              disabled={currentQuestion === 1}
            >
              Previous
            </Button>

            {currentQuestion === questions?.length ? (
              currentQuestionTypeIndex <
              List?.data?.questions_by_type?.length - 1 ? (
                <Button
                  className="buttonnamenext"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      filterquestionlist?.length > 0 &&
                      filterquestionlist[0]?.id
                    ) {
                      dispatch(
                        EditExamAnswer({
                          id: filterquestionlist[0]?.id,
                          student_exam_id: ListStudentExam?.id,
                          question_id: questions[currentQuestion - 1]?.id,
                          option_id: selectedOptionFilterValue
                            ? selectedOptionFilterValue[0]?.option_id
                            : null,
                          remaining_time: timing,
                          skipped: !selectedOptionFilterValue[0]?.option_id,
                          question_type_id:
                            questions[currentQuestion - 1]?.question_type_id,
                          sentence: editorValue === "" ? null : editorValue,
                        })
                      );
                    } else {
                      dispatch(
                        addExamAnswer({
                          student_exam_id: ListStudentExam?.id,
                          question_id: questions[currentQuestion - 1]?.id,
                          option_id: selectedOptionFilterValue
                            ? selectedOptionFilterValue[0]?.option_id
                            : null,
                          remaining_time: timing,
                          skipped: !selectedOptionFilterValue[0]?.option_id,
                          question_type_id:
                            questions[currentQuestion - 1]?.question_type_id,
                          sentence: editorValue === "" ? null : editorValue,
                        })
                      );
                    }
                    setIsSection(true);
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  className="buttonnamenext"
                  variant="contained"
                  color="primary"
                  disabled={
                    questions[currentQuestion - 1]?.question_type ===
                      "Writing" && wordCount < 120
                  }
                  onClick={() => {
                    if (
                      filterquestionlist?.length > 0 &&
                      filterquestionlist[0]?.id
                    ) {
                      dispatch(
                        EditExamAnswer({
                          id: filterquestionlist[0]?.id,
                          student_exam_id: ListStudentExam?.id,
                          question_id: questions[currentQuestion - 1]?.id,
                          option_id: selectedOptionFilterValue
                            ? selectedOptionFilterValue[0]?.option_id
                            : null,
                          remaining_time: timing,
                          skipped: !selectedOptionFilterValue[0]?.option_id,
                          question_type_id:
                            questions[currentQuestion - 1]?.question_type_id,
                          sentence: editorValue === "" ? null : editorValue,
                        })
                      );
                    } else {
                      dispatch(
                        addExamAnswer({
                          student_exam_id: ListStudentExam?.id,
                          question_id: questions[currentQuestion - 1]?.id,
                          option_id: selectedOptionFilterValue
                            ? selectedOptionFilterValue[0]?.option_id
                            : null,
                          remaining_time: timing,
                          skipped: !selectedOptionFilterValue[0]?.option_id,
                          question_type_id:
                            questions[currentQuestion - 1]?.question_type_id,
                          sentence: editorValue === "" ? null : editorValue,
                        })
                      );
                    }
                    setIsSubmitted(true);
                  }}
                >
                  Submit
                </Button>
              )
            ) : (
              <Button
                className="buttonnamenext"
                sx={{ zIndex: 1111 }}
                onClick={() => {
                  setCurrentQuestion((prev) =>
                    Math.min(prev + 1, questions?.length)
                  );
                  if (
                    filterquestionlist?.length > 0 &&
                    filterquestionlist[0]?.id
                  ) {
                    dispatch(
                      EditExamAnswer({
                        id: filterquestionlist[0]?.id,
                        student_exam_id: ListStudentExam?.id,
                        question_id: questions[currentQuestion - 1]?.id,
                        option_id: selectedOptionFilterValue
                          ? selectedOptionFilterValue[0]?.option_id
                          : null,
                        remaining_time: timing,
                        skipped: !selectedOptionFilterValue[0]?.option_id,
                        question_type_id:
                          questions[currentQuestion - 1]?.question_type_id,
                        sentence: editorValue === "" ? null : editorValue,
                      })
                    );
                  } else {
                    dispatch(
                      addExamAnswer({
                        student_exam_id: ListStudentExam?.id,
                        question_id: questions[currentQuestion - 1]?.id,
                        option_id: selectedOptionFilterValue
                          ? selectedOptionFilterValue[0]?.option_id
                          : null,
                        remaining_time: timing,
                        skipped: !selectedOptionFilterValue[0]?.option_id,
                        question_type_id:
                          questions[currentQuestion - 1]?.question_type_id,
                        sentence: editorValue === "" ? null : editorValue,
                      })
                    );
                  }
                }}
                disabled={currentQuestion === questions?.length}
              >
                Next
              </Button>
            )}
          </div>
        </div>
        {/* 
        <Dialog
          open={openmessage}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ color: "#009DFF" }}>
            {"Pending Sections List"}
          </DialogTitle>
          <DialogContent sx={{ width: "35rem" }}>
            <DialogContentText id="alert-dialog-description">
              {List?.data?.questions_by_type?.map((questionType: any) => {
                const { question_type_id, question_type_name, question_count } =
                  questionType;

                // Filter data to count and check for skipped questions for this question type
                const matchingQuestions = StudentExamList?.filter(
                  (item: any) => item?.questionType_id === question_type_id
                );
                const actualCount = matchingQuestions?.length;
                const skippedCount = matchingQuestions?.filter(
                  (item: any) => item?.skipped
                ).length;

                // Determine if this type has pending questions
                const hasPending =
                  actualCount !== question_count || skippedCount > 0;

                if (hasPending) {
                  return (
                    <div key={question_type_id}>
                      <p>
                        <Brightness1Icon
                          sx={{
                            marginRight: "10px",
                            color: "#009DFF",
                            fontSize: "12px",
                          }}
                        />
                        {question_type_name}
                      </p>
                    </div>
                  );
                }
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <ButtonField className="cancel_button" onClick={handleClose}>
              Back
            </ButtonField>
            <ButtonField
              className="message_button"
              onClick={() => {
                setIsExamOver(true);
              }}
            >
              Next
            </ButtonField>
          </DialogActions>
        </Dialog> */}

        <MessageDialog
          messageheading={"Submit Exam"}
          messageableName={"When you are ready, click submit button"}
          open={isSubmitted}
          setOpen={setIsExamOver}
          exam_id={exam_id?.examID}
          valueofdata={ListStudentExam?.id}
          setIsSubmitted={setIsSubmitted}
          student_exam_id={ListStudentExam?.id}
          remaining_time={timing}
          question_type_id={questions[currentQuestion - 1]?.question_type_id}
        />

        {/* section popup */}
        <AlterDialog
          alterheading={"Section Lock Notice"}
          alterableName={
            "Once you complete a section, you will not be able to switch back to it. Please review your answers carefully before proceeding to the next section. "
          }
          altermessage={"Are you sure you want to continue?"}
          open={isSection}
          setOpen={setIsSection}
          buttonname={
            <>
              <ButtonField
                className="form_save_button"
                onClick={() => {
                  setSelectedIndex(
                    (prev) =>
                      (prev + 1) % ListExam?.data?.questions_by_type?.length
                  ); // Increment index and loop back if it exceeds the length

                  setIsSection(false);
                  setIsSectionnext(true);
                  // setCurrentQuestionTypeIndex((prev) => prev + 1);
                }}
              >
                Yes
              </ButtonField>
              <ButtonField
                className="form_cancel_button"
                onClick={() => {
                  setIsSection(false);
                }}
              >
                No
              </ButtonField>
            </>
          }
        />

        <AlterDialog
          alterheading={"Section Transition Notice"}
          alterableName={
            <span>
              You have now moved on to the{" "}
              <b>
                {ListExam?.data?.questions_by_type[selectedIndex]
                  ?.question_type_name || "Not Found"}
              </b>
              section. Your responses from the previous section have been saved
              successfully.
            </span>
          }
          altermessage={"You can continue with the next section now."}
          open={isSectionnext}
          setOpen={setIsSectionnext}
          buttonname={
            <>
              <ButtonField
                className="alter_button"
                onClick={() => {
                  setCurrentQuestionTypeIndex((prev) => prev + 1);
                  dispatch(
                    addCloseSection({
                      student_exam_id: ListStudentExam?.id,
                      remaining_time: timing,
                      closed: true,
                      question_type_id:
                        questions[currentQuestion - 1]?.question_type_id,
                    })
                  );
                  dispatch(
                    addOpenSection({
                      student_exam_id: ListStudentExam?.id,
                      remaining_time: null,
                      closed: false,
                      question_type_id:
                        ListExam?.data?.questions_by_type[selectedIndex]
                          ?.question_type_id,
                    })
                  );
                  setIsSectionnext(false);
                  // setCurrentQuestionTypeIndex((prev) => prev + 1);
                }}
              >
                OK
              </ButtonField>
            </>
          }
        />
        <AlterDialog
          alterheading={"Time’s Up!"}
          alterableName={
            currentQuestionTypeIndex < List?.data?.questions_by_type?.length - 1
              ? "The allocated time for this section has ended. You are now being moved to the next section. Your responses for this section have been saved automatically."
              : "The allocated time for this section has ended. You will now be redirected to the result page. Your responses have been saved automatically."
          }
          altermessage={
            currentQuestionTypeIndex < List?.data?.questions_by_type?.length - 1
              ? "Please proceed to the next section."
              : "Please wait while we calculate your results."
          }
          open={istimeOver}
          setOpen={setIstimeOver}
          valueofdata={ListStudentExam?.id}
          buttonname={
            <>
              <ButtonField
                className="alter_button"
                onClick={() => {
                  if (
                    currentQuestionTypeIndex <
                    List?.data?.questions_by_type?.length - 1
                  ) {
                    setCurrentQuestionTypeIndex((prev) => prev + 1);
                  } else {
                    dispatch(
                      listEvaluation({
                        student_exam_id: ListStudentExam?.id,
                      })
                    );
                    navigate("/examresult", { state: ListStudentExam?.id }); // Replace with your target route
                  }
                  dispatch(
                    addCloseSection({
                      student_exam_id: ListStudentExam?.id,
                      remaining_time: timing,
                      closed: true,
                      question_type_id:
                        questions[currentQuestion - 1]?.question_type_id,
                    })
                  );
                  setIstimeOver(false);
                }}
              >
                {currentQuestionTypeIndex <
                List?.data?.questions_by_type?.length - 1
                  ? "Continue"
                  : "Proceed"}
              </ButtonField>
            </>
          }
        />

        <AlterDialog
          alterheading={"Warning"}
          alterableName={"Your answers have not been saved."}
          open={showModal}
          setOpen={setShowModal}
          buttonname={
            <>
              <ButtonField
                className="form_save_button"
                onClick={() => {
                  setShowModal(false);
                  window.location.reload(); // Or navigate to a different page if needed
                }}
              >
                Yes
              </ButtonField>
              <ButtonField
                className="form_cancel_button"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                No
              </ButtonField>
            </>
          }
        />
        <Snackbar
          anchorOrigin={anchorPosition}
          open={lastMinters}
          onClose={() => {
            setLastMinters(false);
          }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "#4d7aa3", // Background color of the Snackbar content
              color: "white", // Text color
            },
          }}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              <WarningAmberIcon style={{ marginRight: "8px" }} />
              Last 3 Minutes
            </span>
          }
          key={`${anchorPosition.vertical}-${anchorPosition.horizontal}`}
          autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        />

        {/* <AlterDialog
          alterheading={"Warning"}
          alterableName={
            "You switched to another window! Your exam will be submitted."
          }
          open={istimeOvers}
          setOpen={setIstimeOvers}
          valueofdata={ListStudentExam?.id}
          buttonname={
            <>
              <ButtonField
                className="alter_button"
                onClick={() => {
                  dispatch(
                    listEvaluation({
                      student_exam_id: ListStudentExam?.id,
                    })
                  );
                  navigate("/examresult", { state: ListStudentExam?.id }); // Replace with your target route
                }}
              >
                Submit
              </ButtonField>
            </>
          }
        /> */}

        {/* <Drawer open={open} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 450, padding: "60px 30px" }}
            role="presentation"
            onClick={toggleDrawer(false)}
          >
            <div className="boxDialog">Change Section </div>
            <div className="boxDialogparapgraph">
              1) By changing section you will be shifted to a new section .
              <br></br>
              2) If you are sure about section changes , selects from below{" "}
            </div>
            <div className="listissection">
              {List?.data?.questions_by_type.map((item: any) => (
                <div>
                  <div
                    className="listtype"
                    key={item.question_type_id}
                    onClick={() => handleListItemClick(item?.question_type_id)}
                    style={{
                      cursor: "pointer",
                      color:
                        questions[currentQuestion - 1]?.question_type ===
                        item?.question_type_name
                          ? "#009DFF"
                          : "#828282",
                      borderRadius: "10px",
                    }} // Optional: Change cursor to pointer for better UX
                  >
                    <Brightness1Icon
                      fontSize="small"
                      sx={{
                        marginRight: "10px",
                        color:
                          questions[currentQuestion - 1]?.question_type ===
                          item?.question_type_name
                            ? "#009DFF"
                            : "#828282",
                      }}
                    />
                    {item?.question_type_name}
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          </Box>
        </Drawer> */}
      </div>
    </>
  );
};

export default ReExam;

import { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star"; // Import a star icon from MUI
import "./InstructionPractice.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  clearListExamID,
  listExamID,
} from "../../Redux/Slices/Exam/ListExamIDSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import {
  clearlistStudentExam,
  listStudentExam,
} from "../../Redux/Slices/StudentExam/ListStudentExamSlice";
import { Button, CircularProgress } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { listInstructionPracticeExamID } from "../../Redux/Slices/PracticeExam/ListinstructionPracticeExamIDSlice";
import {
  clearListPracticeExamID,
  listPracticeExamID,
} from "../../Redux/Slices/PracticeExam/ListPracticeExamIDSlice";
import { listStudentExamPractice } from "../../Redux/Slices/StudentExamPractice/ListStudentExamPracticeSlice";
import {
  clearEditStudentExamPracticeResponse,
  EditStudentExamPractice,
} from "../../Redux/Slices/StudentExamPractice/EditStudentExamPracticeSlice";
import InfoIcon from "@mui/icons-material/Info";

interface InstructionPracticeProps {}

const InstructionPractice: FunctionComponent<InstructionPracticeProps> = () => {
  // Create an array of instruction objects
  const navigate = useNavigate();
  const { state: exam_id } = useLocation();
  const dispatch = useAppDispatch();

  const { data: listInstructionPracticeExamSlice } = useAppSelector(
    (state) => state.ListInstructionPracticeExamSlice
  );
  const ListInstructionPracticeExamSlices: any =
    listInstructionPracticeExamSlice?.data;

  const ListInstructionPractice =
    ListInstructionPracticeExamSlices?.test_instructions;

  const { data: listexamlist, isLoading: listexamlistLoading } = useAppSelector(
    (state) => state.ListPracticeExamIDSlice
  );

  const [details, setDetails] = useState<ILoginResponse>({});

  const { data: listStudentExamSlice } = useAppSelector(
    (state) => state.ListStudentExamPracticeSlice
  );
  const ListStudentExam: any = listStudentExamSlice?.data;
  const { data: EditStudentPractice } = useAppSelector(
    (state) => state.EditStudentExamPracticeSlice
  );

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(listStudentExamPractice());
    }, 1500); // 5000 milliseconds = 5 seconds
    return () => clearTimeout(timeoutId);
  }, []);

  useLayoutEffect(() => {
    dispatch(listInstructionPracticeExamID({ test_id: exam_id }));
    if (ListStudentExam) {
      const timeout = setTimeout(() => {
        dispatch(listPracticeExamID({ student_test_id: ListStudentExam?.id }));
      }, 2000); // 5000 milliseconds = 5 seconds
      return () => clearTimeout(timeout);
    }
  }, [exam_id, ListStudentExam]);

  useEffect(() => {
    // Check if "details" exists in localStorage before decoding
    const storedDetails = localStorage.getItem("details");
    if (storedDetails) {
      const userDetails = decode(storedDetails);
      setDetails(userDetails);
    }
    dispatch(clearListPracticeExamID());

    // Only re-run if `ListInstructionPracticeExamSlices` is relevant
  }, [ListInstructionPracticeExamSlices]);

  const [showLoading, setShowLoading] = useState(true);

  // Set the initial "Loading questions" display
  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      setShowLoading(false); // Allow normal loading state to take over
    }, 20); // 5 seconds
    return () => clearTimeout(initialTimeout);
  }, []);
  const handleButtonClick = () => {
    navigate("/practiceexam", { state: { examID: exam_id, Practice: "test" } });
    localStorage.setItem(
      "timestorage",
      ListInstructionPracticeExamSlices?.total_timing
    );
  };
  const PendingTime = localStorage.getItem("pending_time");
  const Response = localStorage.getItem("response");
  const question_id = Number(localStorage.getItem("question_id"));
  const Question_Type = Number(localStorage.getItem("question_type"));
  const StudentExamId = Number(localStorage.getItem("student_exam_id"));
  useEffect(() => {
    dispatch(
      EditStudentExamPractice({
        pending_time: PendingTime,
        student_test_id: StudentExamId,
        question_type_id: Question_Type,
        question_id: question_id,
        response: Response,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (EditStudentPractice?.status === true) {
      navigate("/instructionpendingpractice", {
        state: StudentExamId,
      });
      localStorage.removeItem("pending_time");
      localStorage.removeItem("response");
      localStorage.removeItem("question_id");
      localStorage.removeItem("question_type");
    }
  }, [EditStudentPractice]);

  useEffect(() => {
    return () => {
      dispatch(clearEditStudentExamPracticeResponse());
    };
  }, []);

  return (
    <div className="instruction-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className="instructionheading">
          {ListInstructionPracticeExamSlices?.test_name}
        </div>
      </div>

      <div className="instructiondescription">
        {ListInstructionPracticeExamSlices?.test_description}
      </div>
      <div className="instruction-list-container">
        <div style={{ flex: 1 }}>
          <div className="instructionheadingbold">
            Please read instructions carefully
          </div>
          <div className="instruction-liststo">
            {ListInstructionPractice?.map((item: any, index: any) => (
              <div key={index} className="instructionitem">
                <FiberManualRecordIcon
                  sx={{ fontSize: "10px", color: "black", marginTop: "3px" }}
                />

                <div style={{ fontSize: "15px" }}>{item?.instruction}</div>
              </div>
            ))}
          </div>
        </div>

        {/* {!!!listexamlistLoading && ( */}
        <div style={{ flex: 0.3 }}>
          <div className="instructionheadingbold">Test Includes</div>

          <div className="sections-type">
            <div className="section-items">
              <div className="centermultiple">
                {ListInstructionPracticeExamSlices?.question_type_name}
              </div>
              <div>
                Question type :{" "}
                {ListInstructionPracticeExamSlices?.question_type_name ===
                "Writing"
                  ? "Written"
                  : "MCQ"}
              </div>

              <div>
                {" "}
                Question count :{" "}
                {ListInstructionPracticeExamSlices?.question_count === null
                  ? "-"
                  : ListInstructionPracticeExamSlices?.question_count}{" "}
              </div>
              <div>
                {" "}
                Duration :{" "}
                {ListInstructionPracticeExamSlices?.total_timing === null
                  ? "-"
                  : ListInstructionPracticeExamSlices?.total_timing}
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>

      {listexamlist?.data ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <button className="proceed-button" onClick={handleButtonClick}>
            Start Test
          </button>
          <Button
            className="buttonnamenextresportwriteexam"
            sx={{ zIndex: 1111 }}
            onClick={() => {
              navigate("/home"); // Replace with your target route
            }}
          >
            Back to Home
          </Button>
        </div>
      ) : (
        <>
          <button
            className="proceed-button"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>Loading questions</div>
            <div>
              <CircularProgress
                size={20}
                sx={{
                  color: "white",
                  marginLeft: "10px",
                }}
              />
            </div>
          </button>
          <div className="vaidationforwriting">
            <InfoIcon />
            <div>
              Please wait while the questions are being prepared. The test will
              begin shortly{" "}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InstructionPractice;

import { FunctionComponent, memo, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IPracticeExam } from "./IPracticeExam";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addPracticeExam } from "../../Redux/Slices/PracticeExam/AddPracticeExamSlice";
import { EditPracticeExam } from "../../Redux/Slices/PracticeExam/EditPracticeExamSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import {
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
interface PracticeExamFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IPracticeExam;
  setInitialValues: React.Dispatch<React.SetStateAction<IPracticeExam>>;
}

const PracticeExamForm: FunctionComponent<PracticeExamFormProps> = (props) => {
  const dispatch = useAppDispatch();

  // Fetching question types from Redux

  const { data: listPracticeExams } = useAppSelector(
    (state) => state.ListPracticeExamSlice
  );

  const PracticeExam = Array.isArray(listPracticeExams?.data)
    ? listPracticeExams?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];

  const { data: listQuestions } = useAppSelector(
    (state) => state.ListQuestionTypeSlice
  );
  const QuestionList = Array.isArray(listQuestions?.data)
    ? listQuestions?.data
        ?.filter((role) => role.status !== false)
        .map((listQuestions) => ({
          label: listQuestions?.name!,
          value: listQuestions.id,
        }))
    : [];

  const filteredQuestionTypeList = QuestionList?.filter(
    (questionType) =>
      !PracticeExam?.some(
        (item) => item?.question_type_id === questionType?.value
      )
  );

  // Map filtered list to required format
  const QuestionFInalList = filteredQuestionTypeList?.map((questionType) => ({
    label: questionType.label!,
    value: questionType.value,
  }));

  const userSchema = z.object({
    id: z.number().optional(),
    name: z
      .string({ message: "Please enter exam name" })
      .min(1, { message: "Please enter exam name" }),
    question_type_id: z.number({ message: "Please select question type" }),
    question_count: z.number({ message: "Please enter question counts" }),
    description: z
      .string({ message: "Please enter description" })
      .min(1, { message: "Please enter description" }),
    status: z.boolean({
      message: "Please select the status",
    }),
    duration: z
      .number({ message: "Please enter the duration of the exam" })
      .refine((val) => val > 0, {
        message: "PracticeExam duration must be greater than 0 mintues",
      }),
    instruction: z
      .array(
        z
          .string({ message: "Please enter the instruction" })
          .min(1, "Please enter the instruction"),
        { message: "Please enter the instruction" }
      )
      .min(1, "Add at least one instruction")
      .max(20, "Cannot add more than 20 instructions"), // Limit instructions
  });

  const methods = useForm<IPracticeExam>({
    defaultValues: {
      ...props.initialValues,
      status: props.initialValues.id ? props.initialValues.status : true,
    },
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IPracticeExam> = (val) => {
    if (val.id) {
      dispatch(EditPracticeExam(val));
    } else {
      dispatch(addPracticeExam(val));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  // Status list
  const PracticeExamSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];

  const { setValue, getValues } = methods;
  const [instructionInput, setInstructionInput] = useState<string>("");
  const [instructions, setInstructions] = useState<string[]>([]);

  // Add instruction handler
  const handleAddInstruction = () => {
    const currentInstructions = getValues("instruction") || []; // Ensure it's an array
    if (instructionInput!.trim() && currentInstructions!.length! < 20) {
      const updatedInstructions = [...currentInstructions, instructionInput];
      setInstructions(updatedInstructions); // Update local state
      setValue("instruction", updatedInstructions, { shouldValidate: true }); // Update instructions field
      setInstructionInput(""); // Clear input field
    }
  };
  // Delete instruction handler
  const handleDeleteInstruction = (index: number) => {
    const currentInstructions = getValues("instruction");
    const updatedInstructions =
      currentInstructions && currentInstructions?.filter((_, i) => i !== index);
    setInstructions(updatedInstructions!); // Update local state
    setValue("instruction", updatedInstructions); // Update instructions field
    methods.trigger("instruction"); // Force re-render to reflect changes
  };

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_double_input"
      >
        <TextInputFieldC name="name" label="Practice Exam Name" type="text" />
        <Select
          name="question_type_id"
          label="Question Type"
          options={QuestionFInalList ?? []}
          value={
            QuestionList?.find(
              (list) => list.value === methods.getValues("question_type_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("question_type_id", val?.value, {
              shouldValidate: true,
            });
          }}
        />
        <NumberInputField
          type="number"
          name="question_count"
          label="Question Count"
        />
        <TextAreaInputField
          name="description"
          label="description"
          type="text"
        />

        <NumberInputField
          name="duration"
          label="Duration in Mintues"
          type="number"
          sx={{ width: "100%" }}
        />

        <Select
          name="status"
          label="Status"
          options={PracticeExamSatus}
          value={
            PracticeExamSatus?.find(
              (role) => role.value === methods.getValues("status")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("status", val?.value, { shouldValidate: true });
          }}
        />
        {/* Instruction Input Field with TextArea */}
        <Box display="flex">
          <TextField
            label="Instruction"
            value={instructionInput}
            onChange={(e) => setInstructionInput(e.target.value)}
            fullWidth
            multiline
            error={!!methods.formState.errors.instruction}
            helperText={
              !!methods.formState.errors.instruction
                ? String(methods.formState.errors.instruction.message)
                : ""
            }
            minRows={1}
            maxRows={4} // Control how many rows the textarea will expand to
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddInstruction}
            sx={{
              ml: 1,
              width: "10px",
              height: "4rem",
              bgcolor: "primary.main", // Set the background color to primary
              ":hover": {
                bgcolor: "primary.main", // Keep the hover background color the same
              },
            }}
          >
            <AddIcon sx={{ color: "white" }} />
          </Button>
        </Box>
        {/* Render Instructions */}
        {getValues("instruction")?.map((instruction, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="lightgrey"
            p={0.3}
            my={1}
            borderRadius="4px"
            sx={{
              marginBottom: "0px",
              marginTop: "0px",

              ":hover button": { visibility: "visible" }, // Visibility for smoother transitions
            }}
          >
            <Typography
              sx={{
                flexGrow: 1, // Make text take available space
                wordWrap: "break-word", // Ensure long text wraps
                overflow: "hidden",
                padding: "10px",
              }}
            >
              {instruction}
            </Typography>
            <IconButton onClick={() => handleDeleteInstruction(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </form>
    </FormProvider>
  );
};

export default memo(PracticeExamForm);

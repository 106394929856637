import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import "./DashboardUser.scss"; // Import SCSS styles
import StudentImage from "../../Assets/Images/05.svg"; // Adjust the path based on the folder structure
import Paper from "./ImageforDashboard/Paper.svg"; // Adjust the path based on the folder structure
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  clearListExamID,
  listExamID,
} from "../../Redux/Slices/Exam/ListExamIDSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import { listPackage } from "../../Redux/Slices/Package/ListPackageSlice";
import { listUserPackage } from "../../Redux/Slices/UserPackage/ListUserPackageSlice";
import { ReactComponent as cardbg } from "../../Assets/Images/Card.svg";
import { pendingExamList } from "../../Redux/Slices/UserPackage/PendingExamListSlice";
import { listMockExam } from "../../Redux/Slices/MockExam/ListMockExamSlice";
import { listInstructionExamID } from "../../Redux/Slices/Exam/ListinstructionExamIDSlice";
import { pendingExam } from "../../Redux/Slices/PendingExam/PendingExamSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import IconButtonField from "../../Components/Button/IconButtonField";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { listUserExamList } from "../../Redux/Slices/UserPackage/ListUserExamListSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { listUser } from "../../Redux/Slices/User/ListUserSlice";
import { listPracticeExam } from "../../Redux/Slices/PracticeExam/ListPracticeExamSlice";
import { listPracticeTest } from "../../Redux/Slices/PracticeTest/ListPracticeTestSlice";
import { listInstructionPracticeExamID } from "../../Redux/Slices/PracticeExam/ListinstructionPracticeExamIDSlice";
import { clearListPracticeExamID } from "../../Redux/Slices/PracticeExam/ListPracticeExamIDSlice";
import { addStudentExamPractice } from "../../Redux/Slices/StudentExamPractice/AddStudentExamPracticeSlice";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { listEvaluationPractice } from "../../Redux/Slices/PracticeExam/ListEvaluationPracticeSlice";
import {
  clearlistEvaluationPracticewritingExam,
  listEvaluationPracticewritingExam,
} from "../../Redux/Slices/PracticeExam/ListEvaluationPracticewritingExamSlice";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { clearpendingPracticeExam } from "../../Redux/Slices/PendingPracticeExam/PendingPracticeExamSlice";
import { clearEditStudentExamPracticeResponse } from "../../Redux/Slices/StudentExamPractice/EditStudentExamPracticeSlice";
import TabLists from "../../Components/Tabs/TabLists";
import { ITabPannelProps, ITabsProps } from "../../Components/Tabs/ITabProps";
import { CardContent } from "@mui/material";
import { motion } from "framer-motion";
import CAI from "./ImageforDashboard/Chider.png";
import AIhandwithbaby from "./ImageforDashboard/Aiwith.png";
import WrintAi from "./ImageforDashboard/Wing.png";
import Student from "./ImageforDashboard/dONW.png";
import Computerimage from "./ImageforDashboard/fotor-ai-20250220173734 (1).jpg";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dashboardDataCard } from "../../Redux/Slices/DashboardData/DashboardDataCard";
import { dashboardCard } from "../../Redux/Slices/Dashboard/DashboardCard";
import { dashboardChart } from "../../Redux/Slices/DashboardChart/DashboardChart";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ButtonField from "../../Components/Button/ButtonField";

const DashboardUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<ILoginResponse>({});

  const loginResponse = useAppSelector((state) => state.LoginSlicer);

  console.log(loginResponse);

  const { data: mockExamSlice } = useAppSelector(
    (state) => state.MockExamSlice
  );
  const Mock: any = mockExamSlice?.data;

  const { data: ListUserSliceLoading } = useAppSelector(
    (state) => state.ListUserSlice
  );

  const ListUser: any = Array.isArray(ListUserSliceLoading?.data)
    ? ListUserSliceLoading?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];

  useEffect(() => {
    dispatch(listMockExam());
    const userDetails = decode(localStorage.getItem("details"));
    setDetails(userDetails);
  }, []);

  const name: any = details?.username;
  const finalname = name?.split("@")[0];
  const { data: listUserExamListSlice } = useAppSelector(
    (state) => state.ListUserExamListSlice
  );
  const Packages = Array.isArray(listUserExamListSlice?.data)
    ? listUserExamListSlice?.data
        .filter((user) => !user.expiry) // Filter out courses where expiry is false
        .map((user, index) => ({
          ...user,
          index: index + 1,
        }))
    : [];

  const { data: listPracticeExams } = useAppSelector(
    (state) => state.ListPracticeTestSlice
  );
  const PracticeExam = Array.isArray(listPracticeExams?.data)
    ? listPracticeExams?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];
  // my enrolledment
  const { data: pendingExamLists } = useAppSelector(
    (state) => state.PendingExamList
  );
  const Pending = Array.isArray(pendingExamLists?.data)
    ? pendingExamLists?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];
  console.log(pendingExamLists?.status);

  useEffect(() => {
    dispatch(listUser());
    dispatch(listPracticeTest());
    dispatch(listUserExamList());
    dispatch(pendingExamList());
    localStorage.removeItem("pending_time");
    localStorage.removeItem("student_exam_id");
    localStorage.removeItem("sectionreexamId");
    localStorage.removeItem("sectionId");
    localStorage.removeItem("response");
    localStorage.removeItem("question_id");
    localStorage.removeItem("question_type");

    const userDetails = decode(localStorage.getItem("details"));
    setDetails(userDetails);
  }, []);

  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  function formatDuration(duration: any) {
    if (!duration) return ""; // Handle empty input gracefully

    return duration
      .replace("minutes", "min")
      .replace("minute", "min")
      .replace("hours", "hr")
      .replace("hour", "hr")
      .replace("seconds", "sec")
      .replace("second", "sec");
  }
  useEffect(() => {
    dispatch(clearListPracticeExamID());
    dispatch(clearpendingPracticeExam());
    dispatch(clearlistEvaluationPracticewritingExam());
    dispatch(clearEditStudentExamPracticeResponse());
  }, [dispatch]);

  // tab list
  const [tabValue, setTabValue] = React.useState("1");
  const datass = [
    { name: "Best Score Achieved", value: 20 },
    { name: "Remaining", value: 80 },
  ];

  const COLORS = ["blue", "#E0E0E0"];
  const COLORSs = ["#4CAF50", "#E0E0E0"];

  const tabs: ITabsProps[] = [
    { value: "1", label: "Home", disabled: false },
    { value: "2", label: "About Us", disabled: false },
    { value: "3", label: "Dashboard", disabled: false },

    { value: "4", label: "Practice Test", disabled: false },
    {
      value: "5",
      label: "Mini Mock Exam",
      disabled: false,
    },
    { value: "6", label: "Full Length Mock Exam", disabled: false },
    { value: "7", label: "Exam in Progress", disabled: false },
  ];

  useEffect(() => {
    dispatch(dashboardChart());
    dispatch(dashboardDataCard());
  }, [dispatch]);
  const { data: dashboardDataCardSlice } = useAppSelector(
    (state) => state.DashboardDataCard
  );

  const { data: DashboardCards } = useAppSelector(
    (state) => state.DashboardChart
  );

  const Dashboarduser: any = DashboardCards?.data;
  const pieData = [
    { name: "Total Exams Attempted:", value: Dashboarduser?.retake_count }, // Pie Chart value
    { name: "Average Score", value: Dashboarduser?.average_marks },
  ];
  const tabPanels: ITabPannelProps[] = [
    {
      value: "1",
      children: (
        <div className="WeloFour">
          <div className="welcome-container">
            <div className="welcome-text">
              <h1>Welcome to Your Ultimate GATE Exam Practice Portal! 🎯 📚</h1>
              <p>
                Get ready to ace your GATE exams with our interactive and
                AI-powered practice platform! Whether you're sharpening your
                skills or testing your knowledge, we've got everything you need
                to succeed.
              </p>
            </div>
            <div className="welcome-image">
              <img src={CAI} alt="Uploaded preview" />
            </div>
          </div>

          <div className="features-container">
            {/* Image Section */}
            <div className="features-image">
              <img src={AIhandwithbaby} alt="Uploaded preview" />
            </div>
            {/* Text Section */}
            <div className="features-text">
              <h1 className="features-text">What’s Inside?</h1>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Total Questions Available:
                </strong>{" "}
                {dashboardDataCardSlice?.data?.total_questions_available}+ to
                challenge and improve your skills.
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Full-Length Mock Exam:
                </strong>{" "}
                {dashboardDataCardSlice?.data?.full_length_mock_exam} complete
                test to simulate real exam conditions.
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  {" "}
                  Practice Tests Available:
                </strong>{" "}
                {dashboardDataCardSlice?.data?.practice_tests_available}+ to
                boost your confidence.
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Quantitative Reasoning Questions:
                </strong>{" "}
                {dashboardDataCardSlice?.data?.quantitative_practice_questions}{" "}
                question to master problem-solving.
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Abstract Reasoning Questions:
                </strong>{" "}
                {dashboardDataCardSlice?.data?.abstract_practice_questions}{" "}
                question to enhance logical thinking.
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Reading Comprehension Questions:
                </strong>{" "}
                {dashboardDataCardSlice?.data?.reading_practice_questions}{" "}
                question to improve understanding and speed.
              </div>
            </div>
          </div>
          <div className="welcome-container">
            <div className="welcome-text">
              <h1> 🚀 Writing Test – AI-Powered Feedback for GATE Success!</h1>
              <p>Everest Tutoring: Elevate Your Writing with AI Precision!</p>
              <p className="features-text">
                Struggling with writing? Our AI-powered feedback system helps
                refine your skills with{" "}
                <strong className="features-text">real-time insights</strong>{" "}
                and{" "}
                <strong className="features-text">
                  personalized suggestions
                </strong>{" "}
                , ensuring your writing is clear, structured, and exam-ready!
              </p>
              <div>
                <strong className="features-text">
                  🔹 Improve your writing with AI-powered guidance
                </strong>
              </div>
              <div>
                <strong className="features-text">
                  🔹 Get instant feedback and tips for improvement
                </strong>
              </div>
              <div>
                <strong className="features-text">
                  🔹 Prepare with confidence and master essay writing
                </strong>
              </div>
              <div>
                <strong className="features-text">
                  📢 Start Practicing Today & Get One Step Closer to GATE
                  Success! 🏆🔥
                </strong>
              </div>
            </div>
            <div className="welcome-image">
              <img src={WrintAi} alt="Uploaded preview" />
            </div>
          </div>
        </div>
      ),
      checkCondition: () => {
        if (tabValue !== "1") setTabValue("1");
      },
    },

    {
      value: "2",
      children: (
        <div className="WeloFour">
          <div className="welcome-container">
            <div className="welcome-text">
              <div className="features-text">
                Welcome to our{" "}
                <strong className="features-text">
                  GATE Exam Practice Portal 🎯 📚
                </strong>{" "}
                , the ultimate preparation platform for students. We are
                dedicated to helping students succeed by providing unlimited
                access to high-quality practice tests that simulate real exam
                conditions.
              </div>
            </div>
            <div className="welcome-image">
              <img src={Student} alt="Uploaded preview" />
            </div>
          </div>

          <div className="features-container">
            {/* Image Section */}
            <div className="features-image">
              <img src={Computerimage} alt="Uploaded preview" />
            </div>
            {/* Text Section */}
            <div className="features-text">
              <h1 className="features-text">Our platform offers:</h1>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Real-time practice tests
                </strong>{" "}
                to improve speed and accuracy.
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Sample mini mock exams
                </strong>{" "}
                to experience our test format.
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  {" "}
                  Full-length mock exams
                </strong>{" "}
                for comprehensive preparation.
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Detailed performance reports
                </strong>{" "}
                for both exams and practice tests.{" "}
              </div>
              <div className="features-text">
                ✅{" "}
                <strong className="features-text">
                  Automated reports sent to parents
                </strong>{" "}
                after exam submission, ensuring transparency and progress
                tracking.{" "}
              </div>
            </div>
          </div>
        </div>
      ),
      checkCondition: () => {
        if (tabValue !== "2") setTabValue("2");
      },
    },
    {
      value: "3",
      children: (
        <div className="Listcolor">
          <div className="Reportheading">Summary of Past Exam Performance</div>
          <div className="dashboardr-containerr">
            {/* Left Side - Two Cards */}
            <div className="cardr-containerr">
              <div className="cardr">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ fontSize: "13px" }}>Total Exams Attempted :</h2>
                  <p style={{ fontSize: "13px" }}>
                    {Dashboarduser?.retake_count}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1 style={{ fontSize: "13px" }}>
                    Best Score Achieved (%) :
                  </h1>
                  <p style={{ fontSize: "13px" }}>
                    {Dashboarduser?.best_percentage} %
                  </p>
                </div>
              </div>
              <div className="cardr">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ fontSize: "13px" }}>Average Score</h2>
                  <p style={{ fontSize: "13px" }}>
                    {Dashboarduser?.average_marks}%
                  </p>
                </div>
                {/* <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2 style={{ fontSize: "13px" }}>Accuracy Rate (%)</h2>
                  <p style={{ fontSize: "13px" }}>32%</p>
                </div> */}
              </div>
            </div>

            {/* Right Side - Pie Chart */}
            {/* <div className="chartr-containerr">
              <PieChart width={250} height={250}>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={100}
                  dataKey="value"
                  label
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div> */}
          </div>
          <div className="Reportheading">Practice Exam</div>

          <div className="score-container">
            {Dashboarduser?.practice_test_accuracy?.map(
              (item: any, index: number) => {
                const score = parseFloat(item.accuracy); // Convert "10%" -> 10
                const chartData = [
                  { name: "Your Score in Percentage", value: score },
                  { name: "Total Mark in Percentage", value: 100 },
                ];

                return (
                  <div key={index} className="score-box">
                    {/* Left Side - Card */}
                    <div className="score-card">
                      <h2 style={{ fontSize: "15px", textAlign: "left" }}>
                        {" "}
                        {item.question_type}
                      </h2>
                      <p style={{ fontSize: "15px", textAlign: "left" }}>
                        Mark: {item.accuracy} %
                      </p>
                      <p style={{ fontSize: "15px", textAlign: "left" }}>
                        Timing : {item.total_timing}
                      </p>
                    </div>

                    {/* Right Side - Pie Chart */}
                    <div className="score-chart">
                      <PieChart width={150} height={200}>
                        <Pie
                          data={chartData}
                          cx="50%"
                          cy="50%"
                          // innerRadius={50}
                          // outerRadius={80}
                          dataKey="value"
                        >
                          {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORSs[index]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="Reportheading">Last 5 Exam Scores</div>

          <div className="dashboardss-containerss">
            {/* Left Side - Cards */}
            <div className="cardss">
              <h2 style={{ marginRight: "20px", fontSize: "20px" }}>
                Exam Performance
              </h2>
              {Dashboarduser?.last_5_exams?.map((exam: any, index: number) => (
                <p
                  key={index}
                  style={{ marginRight: "20px", fontSize: "15px" }}
                >
                  <strong style={{ marginRight: "20px", fontSize: "20px" }}>
                    {exam.exam_name}:
                  </strong>{" "}
                  {exam.total_marks}%
                </p>
              ))}
            </div>

            {/* Right Side - Bar Chart */}
            <div className="chartss-containerss">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={Dashboarduser?.last_5_exams}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  layout="horizontal"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="category" dataKey="exam_name" />
                  <YAxis type="number" domain={[0, 50]} />
                  <Tooltip />
                  <Bar
                    dataKey="total_marks"
                    isAnimationActive={true}
                    label={{ position: "top", fill: "#333", fontSize: 12 }}
                    barSize={40}
                  >
                    {Dashboarduser?.last_5_exams?.map(
                      (entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill="blue" />
                      )
                    )}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      ),
      checkCondition: () => {
        if (tabValue !== "3") setTabValue("3");
      },
    },
    {
      value: "4",
      children: (
        <Box className="dashboard_cards_container">
          {PracticeExam?.length === 0 ? (
            // Render this when the Package array is empty
            <Card className="dashboard_card">
              <div className="dashboard_cards">
                <Typography className="heading_name" variant="h6">
                  Practice Test Not Available
                </Typography>
              </div>
            </Card>
          ) : (
            PracticeExam?.map((pkg, index) => (
              <Card className="dashboard_card" key={index}>
                <div className="dashboard_cards">
                  <div className="heading_namelist">
                    <div
                      className="questioncount"
                      title={`Question count : ${pkg?.question_count}`}
                    >
                      <span className="spanlist">{pkg?.question_count}</span>
                    </div>
                    {pkg?.name}
                    <div className="timeandpercentage">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                        title={pkg?.duration}
                      >
                        <AccessTimeIcon />
                        {formatDuration(pkg?.duration)}
                      </div>
                      {/* {pkg?.student_test_id === null ? ( */}
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <IconButtonField
                          toolTip="Report"
                          onClick={() => {
                            if (pkg?.question_type_name === "Writing") {
                              dispatch(
                                listEvaluationPracticewritingExam({
                                  student_test_id:
                                    pkg?.view_report?.student_test_id,
                                })
                              );

                              navigate("/examresultpractice", {
                                state: {
                                  id: pkg?.view_report?.student_test_id,
                                  type: "Writing",
                                },
                              });
                            } else {
                              dispatch(
                                listEvaluationPractice({
                                  student_test_id:
                                    pkg?.view_report?.student_test_id,
                                })
                              );

                              navigate("/examresultpractice", {
                                state: pkg?.view_report?.student_test_id,
                              });
                            }
                          }}
                        >
                          {pkg?.view_report !== null && <AssessmentIcon />}
                        </IconButtonField>
                        {/* ) : ( */}
                        {pkg?.student_test_id !== null && (
                          <div
                            title={`Answered Question : ${pkg?.answered_percentage}%`}
                          >
                            {pkg?.answered_percentage}%
                          </div>
                        )}
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                  {pkg?.student_test_id === null ? (
                    <button
                      className="card-buttonlist"
                      onClick={() => {
                        // dispatch(clearListPracticeExamID());
                        dispatch(
                          listInstructionPracticeExamID({
                            test_id: pkg?.id,
                          })
                        );
                        dispatch(
                          addStudentExamPractice({
                            test_id: pkg?.id,
                            student_id: details?.id,
                            closed: false,
                          })
                        );
                        navigate("/instructionpractice", {
                          state: pkg?.id,
                        });
                        dispatch(clearEditStudentExamPracticeResponse());
                      }}
                    >
                      Start Now
                    </button>
                  ) : (
                    <button
                      className="card-buttonlist"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/instructionpendingpractice", {
                          state: pkg?.student_test_id,
                        });
                      }}
                    >
                      Continue
                    </button>
                  )}
                </div>
              </Card>
            ))
          )}
        </Box>
      ),
      checkCondition: () => {
        if (tabValue !== "4") setTabValue("4");
      },
    },
    {
      value: "5",
      children: (
        <div className="mock-exam-container">
          <div className="cards-wrapper">
            <div className="multi-card">
              <div className="mock-exam" style={{ textAlign: "start" }}>
                <Typography variant="h5" className="textsytleheading">
                  Try Sample Mock Exam
                </Typography>
                <p style={{ fontSize: "15px" }}>
                  Begin your mock exam to test your knowledge.
                </p>
                <button
                  className="card-button"
                  onClick={() => {
                    dispatch(listInstructionExamID({ exam_id: Mock[0]?.id }));
                    dispatch(
                      addStudentExam({
                        exam_id: Mock[0]?.id,
                        student_id: details?.id,
                        closed: false,
                      })
                    );
                    navigate("/instruction", { state: Mock[0]?.id }); // Replace with your target route
                    dispatch(clearListExamID());
                  }}
                >
                  Start Now
                </button>
              </div>
              <div className="exam-history">
                <Typography variant="h5" className="textsytleheading">
                  Exam History
                </Typography>
                <p style={{ fontSize: "15px" }}>To view exam history</p>
                <IconButtonField onClick={() => navigate("/examhistory")}>
                  <DoubleArrowIcon />
                </IconButtonField>
              </div>
            </div>
            <div className="card">
              <Typography
                variant="h4"
                className="textsytleheading"
                sx={{ color: "white" }}
              >
                {/* My Enrollments */}
              </Typography>
              <Typography
                variant="h4"
                className="textsytleheading"
                sx={{ color: "white" }}
              >
                My Enrolments
              </Typography>
              <button
                className="card-button"
                onClick={() => {
                  navigate("/totalexam"); // Replace with your target route
                }}
              >
                See All
              </button>
            </div>
          </div>
        </div>
      ),
      checkCondition: () => {
        if (tabValue !== "5") setTabValue("5");
      },
    },
    {
      value: "6",
      children: (
        <>
          {Packages?.length === 0 ? (
            <>
              <div style={{ marginBottom: "20px" }}>
                <div className="customCardpending">
                  <Card className="dashboard_cardspending">
                    <Typography className="heading_name" variant="h6">
                      Exams Not Available
                    </Typography>
                  </Card>
                </div>
              </div>
            </>
          ) : (
            Packages?.map((pkg, index) => (
              <div key={index}>
                <div
                  style={{ marginBottom: "20px", cursor: "pointer" }}
                  // onClick={() => {
                  //   navigate("/purchasedexam", { state: pkg?.package?.id }); // Replace with your target route
                  // }}
                >
                  <div className="customCardpendingtotal">
                    <Card
                      className="dashboard_cardspending"
                      sx={{ bgcolor: "#f5f5f5" }}
                    >
                      <Typography className="heading_name" variant="h6">
                        {pkg?.course_name}
                      </Typography>
                    </Card>
                    <div className="textContent">
                      <Typography
                        variant="h5"
                        sx={{ color: "#0088ff" }}
                        className="textsytleheading"
                      >
                        {pkg?.course_name}
                      </Typography>

                      <Typography variant="body2" className="textsytle">
                        {pkg?.course_description}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{ color: "#0088ff" }}
                        className="textsytleheading"
                      >
                        {pkg?.exams?.length === 0 ? (
                          <button
                            className="card-buttonexamlist"
                            onClick={() => {
                              if (tabValue !== "7") setTabValue("7");
                            }}
                          >
                            Exam in Progress
                          </button>
                        ) : (
                          "Exams"
                        )}
                      </Typography>
                      {/* Use parentheses to directly return the JSX from the map function */}
                      {pkg?.exams?.map((listexam, examIndex) => (
                        <Accordion key={examIndex} sx={{ width: "100%" }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id={`panel-${index}-header`}
                            sx={{
                              color: "#0088ff",
                              fontWeight: 600,
                              fontSize: "medium",
                            }}
                          >
                            {listexam?.exam_name}
                          </AccordionSummary>
                          <AccordionDetails>
                            <div>{listexam?.exam_description}</div>
                            {listexam?.retake_count === 0 ? (
                              <div className="EnrolledHistory">
                                <button
                                  className="card-buttonexamlist"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    dispatch(clearListExamID());
                                    dispatch(
                                      listInstructionExamID({
                                        exam_id: listexam?.exam_id,
                                      })
                                    );
                                    dispatch(
                                      addStudentExam({
                                        exam_id: listexam?.exam_id,
                                        student_id: details?.id,
                                        closed: false,
                                      })
                                    );
                                    navigate("/instruction", {
                                      state: listexam?.exam_id,
                                    }); // Replace with your target route
                                  }}
                                >
                                  Start Now
                                </button>
                                <div className="Attempcountexam">
                                  Attempt Count : {listexam?.retake_count}
                                </div>
                              </div>
                            ) : (
                              <div className="Buttonlist">
                                <button
                                  className="card-buttonexamlist"
                                  // onClick={() => {
                                  //   navigate("/instructionreExam", {
                                  //     state: listexam?.student_exam_id,
                                  //   }); // Replace with your target route
                                  // }}
                                  onClick={() => {
                                    dispatch(clearListExamID());
                                    dispatch(
                                      listInstructionExamID({
                                        exam_id: listexam?.exam_id,
                                      })
                                    );
                                    dispatch(
                                      addStudentExam({
                                        exam_id: listexam?.exam_id,
                                        student_id: details?.id,
                                        closed: false,
                                      })
                                    );
                                    navigate("/instruction", {
                                      state: listexam?.exam_id,
                                    }); // Replace with your target route
                                  }}
                                >
                                  ReTake Exam
                                </button>
                                <button
                                  className="card-buttonexam"
                                  onClick={() => {
                                    navigate("/examresult", {
                                      state: listexam?.student_exam_id,
                                    });
                                  }}
                                >
                                  Report
                                </button>
                                <div className="Attempcount">
                                  Attempt Count : {listexam?.retake_count}
                                </div>
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </>
      ),
      checkCondition: () => {
        if (tabValue !== "6") setTabValue("6");
      },
    },
    {
      value: "7",
      children: (
        <div className="pendingexamsscrollbar">
          {Pending?.length === 0 ? (
            <div style={{ marginBottom: "20px" }}>
              <div className="customCardpending">
                <Card
                  className="dashboard_cardspending"
                  sx={{ bgcolor: "#f5f5f5" }}
                >
                  <Typography className="heading_name" variant="h6">
                    Pending Exam Not Available
                  </Typography>
                </Card>
              </div>
            </div>
          ) : (
            Pending?.map((card, index) => (
              <div style={{ marginBottom: "20px" }} key={index}>
                <div className="customCardpending">
                  <Card
                    className="dashboard_cardspending"
                    sx={{ bgcolor: "#f5f5f5" }}
                  >
                    <Typography className="heading_name" variant="h6">
                      {card?.exam_name}
                    </Typography>
                  </Card>
                  <div className="textContent">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5" className="textsytleheading">
                        {card?.exam_name}
                      </Typography>
                      <button className="card-buttons">
                        Total Question {card?.total_questions}
                      </button>
                    </div>

                    <Typography variant="body2" className="textsytle">
                      {card?.exam_description}
                    </Typography>
                    <Box sx={{ width: "100%", marginTop: "10px" }}>
                      <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <LinearProgress
                            variant="determinate"
                            value={card?.answered_percentage}
                            sx={{ height: "7px", borderRadius: "10px" }}
                          />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="text.secondary">
                            {card?.answered_percentage} %
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <button
                      className="card-buttons"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/instructionpending", { state: card?.id }); // Replace with your target route
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      ),
      checkCondition: () => {
        if (tabValue !== "7") setTabValue("7");
      },
    },
  ];

  const handleChangeab = (val: string) => {
    const currentTab = tabPanels?.find((item: any) => item?.value === val);

    if (currentTab?.checkCondition) {
      currentTab.checkCondition();
      if (currentTab?.saveNext) {
        if (parseInt(tabValue) + 1 === parseInt(val)) {
          setTabValue((parseInt(tabValue) + 1).toString());
        }
      }
    }

    if (parseInt(tabValue) > parseInt(val)) {
      setTabValue(val);
    }
  };

  // is pending exam list popup
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (
      loginResponse?.data?.status === true &&
      pendingExamLists?.status === true
    ) {
      setOpen(true); // Open only if both conditions are true
    } else {
      setOpen(false); // Close if any condition is false
    }
  }, [loginResponse, pendingExamLists]); // Dependencies

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="DashboardUsermain">
        <div className="customCard">
          <div style={{ display: "flex" }}>
            <img
              className="imagesleftside"
              src={StudentImage}
              alt="Left Image"
            />
            <div className="textContent">
              <Typography variant="body1" className="textsytles">
                Hi{" "}
                <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
                  {name},
                </span>
              </Typography>
              <Typography variant="h5" className="textsytleheading">
                Welcome to Everest Tutoring’s AI GATE Question Bank!
              </Typography>
              <Typography variant="body2" className="textsytles">
                Select one of the courses below to complete a timed Mini Mock
                Test, powered by AI! Review your performance and solutions at
                the end, and ask our Smart Tutor for help with any question!
              </Typography>
            </div>
          </div>

          <img className="image" src={Paper} alt="Right Image" />
        </div>

        <br></br>

        <TabLists
          scrollButtons={true}
          variant="scrollable"
          tabs={tabs}
          tabPannels={tabPanels}
          tabPanelClassName="tabPanelClassName"
          orientation="horizontal"
          tabClassName="Tab_css"
          textColor="primary"
          centered={true}
          onChange={(_, v) => handleChangeab(v)}
          tabValue={tabValue}
        />
      </div>
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ReportGmailerrorredIcon
              sx={{ fontSize: "50px", color: "#009dff" }}
            />
            <div style={{ fontSize: "20px", color: "#009dff" }}>Warning</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ fontSize: "15px" }}
            >
              Your exam is in progress. Are you ready to continue?{" "}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonField
              className="form_save_button"
              onClick={() => {
                if (tabValue !== "7") setTabValue("7");
                setOpen(false);
              }}
            >
              Yes
            </ButtonField>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setOpen(false)}
            >
              No
            </ButtonField>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default DashboardUser;

import React, { useEffect, useRef, useState } from "react";
import { Card, Typography, Box, LinearProgress } from "@mui/material";
import "./Exam_History.scss"; // Import SCSS styles
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listExamHistory } from "../../Redux/Slices/ExamHistory/ListExamHistorySlice";
import {
  clearListExamID,
  listExamID,
} from "../../Redux/Slices/Exam/ListExamIDSlice";
import { listReViewExamID } from "../../Redux/Slices/ReViewExam/ListReViewExamSlice";
import { listReExamID } from "../../Redux/Slices/ReExam/ListReExamSlice";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { listExamHistoryReWritingSlice } from "../../Redux/Slices/ExamHistory/ListExamHistoryReWritingSlice";
import { listEvaluationPracticewritingExam } from "../../Redux/Slices/PracticeExam/ListEvaluationPracticewritingExamSlice";
import { listEvaluationPractice } from "../../Redux/Slices/PracticeExam/ListEvaluationPracticeSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import { listInstructionExamID } from "../../Redux/Slices/Exam/ListinstructionExamIDSlice";
import { ILoginResponse } from "../Login/ILogin";
import { decode } from "../../Utils/encodeDecode";
import { listMockExamHistory } from "../../Redux/Slices/ExamHistory/ListMockExamHistorySlice";

const ExamHistory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: ListMockExamHistorySlice } = useAppSelector(
    (state) => state.ListMockExamHistorySlice
  );
  const { data: ListExamHistorySlice } = useAppSelector(
    (state) => state.ListExamHistorySlice
  );
  const { data: ListExamHistoryreSlice } = useAppSelector(
    (state) => state.ListExamHistoryReWritingSlice
  );
  const { data: AddFeedBackSlice } = useAppSelector(
    (state) => state.AddFeedBackSlice
  );
  const MockExamHistorySlicelist: any = ListMockExamHistorySlice?.data;
  const ExamHistorySlicelist: any = ListExamHistorySlice?.data;
  const ExamHistorySliceRElist: any = ListExamHistoryreSlice?.data;

  useEffect(() => {
    dispatch(listMockExamHistory());
    dispatch(listExamHistory());
    dispatch(listExamHistoryReWritingSlice());
  }, [dispatch, AddFeedBackSlice]);

  // down arrow
  const [showArrow, setShowArrow] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        setShowArrow(scrollHeight - scrollTop > clientHeight + 1); // Show arrow if not at the bottom
      }
    };

    const container: any = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const [details, setDetails] = useState<ILoginResponse>({});

  useEffect(() => {
    const userDetails = decode(localStorage.getItem("details"));
    setDetails(userDetails);
  }, []);
  return (
    <div className="ExamHistorymain">
      <div className="histroy">
        <div className="listexam">History </div>
        <button
          className="card-buttonexamlist"
          onClick={() => {
            navigate("/home"); // Replace with your target route
          }}
        >
          Go to Dashboard Page
        </button>
      </div>
      <div className="pendingexamsscrobar">
        <div className="listexam">Mock Exam</div>

        {MockExamHistorySlicelist?.length === 0 ? (
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <div className="customCardpendinghistory">
              <div className="textContenthistory">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" className="textsytleexamlist">
                    Exam History Not Available
                  </Typography>
                  {/* <div>{formattedDate}</div> */}
                </div>

                <Typography variant="body2" className="textsytle">
                  {/* {history?.exam_description} */}
                  <br />
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div className="feedbackword"> Feedback: </div>
                  <div className="card-buttonsexamgood">{feedbackText}</div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          MockExamHistorySlicelist?.map((history: any, index: any) => {
            // Format the entry_date
            const formattedDate = new Date(
              history?.exit_time
            ).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });

            const feedbackText = Array.isArray(history?.feed_back)
              ? history.feed_back
                  .map((item: any) => item.feedback)
                  .join(", ") || "Skipped"
              : history?.feed_back || "Skipped";

            return (
              <div className="BorderPractiseExam">
                <div
                  style={{ marginBottom: "20px", marginTop: "20px" }}
                  key={index}
                >
                  <div className="customCardpendinghistory">
                    <div className="textContenthistory">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" className="textsytleexamlist">
                          {history?.exam_name}
                        </Typography>
                        <div>{formattedDate}</div>
                      </div>

                      <Typography variant="body2" className="textsytle">
                        {history?.exam_description}
                        <br />
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div className="feedbackword"> Feedback: </div>
                          <div
                            className="card-buttonsexamgood"
                            style={{ marginRight: "5px" }}
                          >
                            {feedbackText}
                          </div>
                          <div className="feedbackword"> Attempt count : </div>
                          <div className="card-buttonsexamgood">
                            {history?.retake_count}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="card-buttonexam"
                            onClick={() => {
                              navigate("/examresult", { state: history?.id });
                            }}
                          >
                            Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <br></br>
      <br></br>
      <div className="pendingexamsscrobar">
        <div className="listexam">Practice Test</div>

        {ExamHistorySliceRElist?.length === 0 ? (
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <div className="customCardpendinghistory">
              <div className="textContenthistory">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" className="textsytleexamlist">
                    Exam History Not Available
                  </Typography>
                  {/* <div>{formattedDate}</div> */}
                </div>

                <Typography variant="body2" className="textsytle">
                  {/* {history?.exam_description} */}
                  <br />
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div className="feedbackword"> Feedback: </div>
                  <div className="card-buttonsexamgood">{feedbackText}</div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ExamHistorySliceRElist?.map((history: any, index: any) => {
            // Format the entry_date
            const formattedDate = new Date(
              history?.exit_time
            ).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });

            const feedbackText = Array.isArray(history?.feed_back)
              ? history.feed_back
                  .map((item: any) => item.feedback)
                  .join(", ") || "Skipped"
              : history?.feed_back || "Skipped";

            return (
              <div className="BorderPractiseExam">
                <div
                  style={{ marginBottom: "20px", marginTop: "20px" }}
                  key={index}
                >
                  <div className="customCardpendinghistory">
                    <div className="textContenthistory">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" className="textsytleexamlist">
                          {history?.test_name}
                        </Typography>
                        <div>{formattedDate}</div>
                      </div>

                      <Typography variant="body2" className="textsytle">
                        {history?.test_description}
                        <br />
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div className="feedbackword"> Feedback: </div>
                          <div
                            className="card-buttonsexamgood"
                            style={{ marginRight: "5px" }}
                          >
                            {feedbackText}
                          </div>
                          <div className="feedbackword"> Attempt count : </div>
                          <div className="card-buttonsexamgood">
                            {history?.retake_count}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="card-buttonexam"
                            onClick={() => {
                              if (history?.test_name === "Writing Test") {
                                dispatch(
                                  listEvaluationPracticewritingExam({
                                    student_test_id: history?.id,
                                  })
                                );

                                navigate("/examresultpractice", {
                                  state: {
                                    id: history?.id,
                                    type: "Writing",
                                  },
                                });
                              } else {
                                dispatch(
                                  listEvaluationPractice({
                                    student_test_id: history?.id,
                                  })
                                );

                                navigate("/examresultpractice", {
                                  state: history?.id,
                                });
                              }
                            }}
                          >
                            Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <br />
      <br />
      <div className="pexamsscrollbar">
        <div className="listexam">Exam History</div>
        {ExamHistorySlicelist?.length === 0 ? (
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <div className="customCardpendinghistory">
              <div className="textContenthistory">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" className="textsytleexamlist">
                    Exam History Not Available
                  </Typography>
                  {/* <div>{formattedDate}</div> */}
                </div>

                <Typography variant="body2" className="textsytle">
                  {/* {history?.exam_description} */}
                  <br />
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div className="feedbackword"> Feedback: </div>
                  <div className="card-buttonsexamgood">{feedbackText}</div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ExamHistorySlicelist?.map((history: any, index: any) => {
            // Format the entry_date
            const formattedDate = new Date(
              history?.exit_time
            ).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });

            const feedbackText = Array.isArray(history?.feed_back)
              ? history.feed_back
                  .map((item: any) => item.feedback)
                  .join(", ") || "Skipped"
              : history?.feed_back || "Skipped";

            return (
              <div className="BorderPractiseExam">
                <div
                  style={{ marginBottom: "20px", marginTop: "20px" }}
                  key={index}
                >
                  <div className="customCardpendinghistory">
                    <div className="textContenthistory">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" className="textsytleexamlist">
                          {history?.exam_name}
                        </Typography>
                        <div>{formattedDate}</div>
                      </div>

                      <Typography variant="body2" className="textsytle">
                        {history?.exam_description}
                        <br />
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div className="feedbackword"> Feedback: </div>
                          <div
                            className="card-buttonsexamgood"
                            style={{ marginRight: "5px" }}
                          >
                            {feedbackText}
                          </div>
                          <div className="feedbackword"> Attempt count : </div>
                          <div className="card-buttonsexamgood">
                            {history?.retake_count}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="card-buttonexam"
                            onClick={() => {
                              navigate("/examresult", { state: history?.id });
                            }}
                          >
                            Report
                          </button>

                          <button
                            className="card-buttonexamlist"
                            onClick={() => {
                              dispatch(clearListExamID());
                              dispatch(
                                listInstructionExamID({
                                  exam_id: history?.exam_id,
                                })
                              );
                              dispatch(
                                addStudentExam({
                                  exam_id: history?.exam_id,
                                  student_id: details?.id,
                                  closed: false,
                                })
                              );
                              navigate("/instruction", {
                                state: history?.exam_id,
                              }); // Replace with your target route
                            }}
                          >
                            ReTake Exam
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ExamHistory;

import { FunctionComponent, useEffect } from "react";
import OnlineExam from "../../Components/OnlineExam/OnlineExam";
import "./Exam_participationPractice.scss";
import { useAppDispatch } from "../../Redux/Store/Hooks";
import { listExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";
import OnlineExamPractice from "../../Components/OnlineExamPractice/OnlineExamPractice";

interface ExamParticipationPracticeProps {}

const ExamParticipationPractice: FunctionComponent<
  ExamParticipationPracticeProps
> = () => {
  const dispatch = useAppDispatch();

  //   useEffect(() => {
  //     dispatch(listExamID({ exam_id: 29 }));
  //   }, []);
  return (
    <>
      <div style={{ height: "50px" }} />
      <OnlineExamPractice />
    </>
  );
};

export default ExamParticipationPractice;

import * as React from "react";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from "recharts";
import "./SubmitExam.scss";
import ButtonField from "../../Components/Button/ButtonField";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { Button } from "@mui/material";
import { listEvaluation } from "../../Redux/Slices/Exam/ListEvaluationSlice";
import { listExamsubmit } from "../../Redux/Slices/ExamAnswer/ListExamsubmitSlice";
import { pendingExamList } from "../../Redux/Slices/UserPackage/PendingExamListSlice";

export default function SubmitExam() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state: result_id } = useLocation();

  const { data: listExamsubmitSlice } = useAppSelector(
    (state) => state.ListExamsubmitSlice
  );
  const Add: any = listExamsubmitSlice?.data;

  const data02 = [
    { name: "Answered", value: Add?.answered_questions, fill: "#88B61E" }, // color for Group A
    {
      name: "Skipped / Unattempted",
      value: Add?.skipped_questions,
      fill: "#0076C0",
    }, // color for Group B
  ];

  React.useEffect(() => {
    dispatch(
      listExamsubmit({
        id: result_id,
      })
    );
  }, []);

  return (
    <div className="boxofsubmitexam">
      <div className="concentofexamsubmit">
        <div>
          <div className="submitexamheading">Submit Exam</div>
          <div className="submitcomandline">
            Before you submit the test, please make
            <br /> sure you have attended all the questions.
          </div>
          <div className="chartlist">
            <div style={{ width: "50%", height: 200 }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    dataKey="value"
                    data={data02}
                    cx="50%" // Position in percentage
                    cy="50%" // Position in percentage
                    innerRadius={40}
                    outerRadius={80}
                    fill="#82ca9d"
                  />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="chartexam">
              <div className="textchartexam">Answered</div>
              <div className="textchartexams">
                <Brightness1Icon
                  sx={{
                    color: "#88B61E",
                    fontSize: "9px",
                    marginRight: "5px",
                  }}
                />
                {Add?.answered_questions}
              </div>
              <div className="textchartexam">Skipped / Unattempted</div>
              <div className="textchartexams">
                <Brightness1Icon
                  sx={{ color: "#0076C0", fontSize: "9px", marginRight: "5px" }}
                  fontSize="small"
                />
                {Add?.skipped_questions}
              </div>
              <div className="textchartexam">Total Question </div>
              <div className="textchartexams">
                <Brightness1Icon
                  sx={{ color: "#F9AE13", fontSize: "9px", marginRight: "5px" }}
                  fontSize="small"
                />
                {Add?.total_questions}
              </div>
            </div>
          </div>
          <div className="buttonfieldsubmit">
            <Button
              className="messagebuttonsubmit"
              onClick={() => {
                dispatch(
                  listEvaluation({
                    student_exam_id: Add?.student_exam_id,
                  })
                );
                navigate("/examresult", { state: result_id }); // Replace with your target route
                dispatch(pendingExamList());
              }}
            >
              Submit
            </Button>
            <br></br>
            {/* <Button
              className="messagebuttonsubmit"
              onClick={() => {
                navigate("/home"); // Replace with your target route
                dispatch(pendingExamList());
              }}
            >
              Back to Home
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Pagination,
  Typography,
  Grid,
  SvgIcon,
  Box,
  Snackbar,
  Card,
} from "@mui/material";
import "./OnlineExamPractice.scss"; // For additional styling
import { ReactComponent as Deleteicon } from "../../Assets/Images/delete.svg";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addExamAnswer } from "../../Redux/Slices/ExamAnswer/AddExamAnswerSlice";
import { listExamAnswer } from "../../Redux/Slices/ExamAnswer/ListExamAnswerSlice";
import { EditExamAnswer } from "../../Redux/Slices/ExamAnswer/EditExamAnswerSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { listExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";
// dialog message
import { listStudentExam } from "../../Redux/Slices/StudentExam/ListStudentExamSlice";
import { listEvaluation } from "../../Redux/Slices/Exam/ListEvaluationSlice";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { PopupStudentExam } from "../../Redux/Slices/StudentExam/PopupStudentExamSlice";
import { addCloseSection } from "../../Redux/Slices/Exam/AddCloseSectionSlice";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill CSS for styling
import { motion } from "framer-motion";
import MessageDialog from "../../Components/MessageDialog/MessageDialog";
import AlterDialog from "../../Components/AlterDialog/AlterDialog";
import ButtonField from "../../Components/Button/ButtonField";
import { listStudentExamPractice } from "../../Redux/Slices/StudentExamPractice/ListStudentExamPracticeSlice";
import { listPracticeExamID } from "../../Redux/Slices/PracticeExam/ListPracticeExamIDSlice";
import { listExamAnswerPractice } from "../../Redux/Slices/ExamAnswerPractice/ListExamAnswerPracticeSlice";
import { addExamAnswerPractice } from "../../Redux/Slices/ExamAnswerPractice/AddExamAnswerPracticeSlice";
import { listEvaluationPractice } from "../../Redux/Slices/PracticeExam/ListEvaluationPracticeSlice";
import QuillEditor from "quill";
import {
  clearlistEvaluationPracticewritingExam,
  listEvaluationPracticewritingExam,
} from "../../Redux/Slices/PracticeExam/ListEvaluationPracticewritingExamSlice";
import MessageDialogTest from "../MessageDialogTest/MessageDialogTest";
import InfoIcon from "@mui/icons-material/Info";
interface Question {
  id: number;
  question_type: string;
  prompt: string;
  instruction: string;
  question_id: number;
  question: string;
  question_url?: string;
  question_type_id?: number;
  options: { optionname: string; option_id: number }[];
  optionsurl: { optionname: string; option_id: number }[];
}

// Register necessary modules
const FontSize = ReactQuill.Quill.import("formats/size");
FontSize.whitelist = ["small", false, "large", "huge"];
ReactQuill.Quill.register(FontSize, true);

const Align = ReactQuill.Quill.import("formats/align");
Quill.register(Align, true);

const modules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, { font: [] }], // Paragraph styles (headers) and font
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }], // Text and background color
    [{ script: "sub" }, { script: "super" }], // Subscript/superscript
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    [{ direction: "rtl" }], // Right-to-left (if needed)
    ["blockquote", "code-block"],
    // ["link", "image", "formula"], // Link, image, formula
    ["clean"], // Clear formatting
    [{ undo: { icon: "undo" } }, { redo: { icon: "redo" } }], // Correct undo/redo
  ],
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "list",
  "bullet",
  "indent",
  "direction",
  "blockquote",
  "code-block",
  "align",
  // "link",
  // "image",
  // "formula",
];

const OnlineExamPractice: React.FC = () => {
  // overall data list
  const { data: listexamlist } = useAppSelector(
    (state) => state.ListPracticeExamIDSlice
  );
  const List: any = listexamlist;
  const ListExam: any = listexamlist;

  const navigate = useNavigate();
  const { state: exam_id } = useLocation();

  // data list  option
  const { data: listExamAnswerSlice, isLoading: answerSliceLoading } =
    useAppSelector((state) => state.ListExamAnswerPracticeSlice);
  const Listoption: any = listExamAnswerSlice?.data;

  const dispatch = useAppDispatch();

  const { data: listStudentExamSlice } = useAppSelector(
    (state) => state.ListStudentExamPracticeSlice
  );
  const { data: addExamAnswerRes, isLoading: addExamAnswerLoading } =
    useAppSelector((state) => state.AddExamAnswerPracticeSlice);

  const { data: editExamAnswerRes, isLoading: editExamAnswerLoading } =
    useAppSelector((state) => state.AddExamAnswerPracticeSlice);

  const ListStudentExam: any = listStudentExamSlice?.data;

  const { data: ListExamAnswerSlice } = useAppSelector(
    (state) => state.ListExamAnswerPracticeSlice
  );
  const StudentExamList: any = ListExamAnswerSlice?.data;

  // dialog  list
  const [openmessage, setOpenmessage] = React.useState(false);
  // //   dialog box
  // const [open, setOpen] = React.useState(false);
  // const toggleDrawer = (newOpen: boolean) => () => {
  //   setOpen(newOpen);
  // };

  // const handleClose = () => {
  //   setOpenmessage(false);
  //   setOpen(true);
  //   setIsSubmitted(false);
  // };

  function handleListItemClick(question_type_id: number) {
    // Find the index of the first question with the matching question_type_id
    const questionIndex = List?.data?.questions.findIndex(
      (question: any) => question.question_type_id === question_type_id
    );
    setCurrentQuestion(questionIndex + 1);
  }

  useEffect(() => {
    dispatch(listStudentExamPractice());
  }, []);

  useEffect(() => {
    dispatch(listPracticeExamID({ student_test_id: ListStudentExam?.id }));
  }, [ListStudentExam]);

  const [questions, setQuestions] = useState<Question[]>([]); // Initialize with predefined data

  const [currentQuestionTypeIndex, setCurrentQuestionTypeIndex] =
    useState<number>(
      parseInt(localStorage.getItem("sectionreexamId") ?? "0") ?? 0
    ); // Tracks current question type

  useEffect(() => {
    // setSearchParams({ id: encode(currentQuestionTypeIndex) });
    localStorage.setItem(
      "sectionreexamId",
      currentQuestionTypeIndex.toString()
    );
  }, [currentQuestionTypeIndex]);

  useEffect(() => {
    !!localStorage.getItem("sectionreexamId") &&
      setCurrentQuestionTypeIndex(
        parseInt(localStorage.getItem("sectionreexamId")!)
      );
  }, []);

  function formatTime(timeInSeconds: number) {
    const hours = Math.floor(timeInSeconds / 3600); // Calculate total hours
    const minutes = Math.floor((timeInSeconds % 3600) / 60); // Remaining minutes after hours

    let result = "";
    if (hours > 0) {
      result += `${hours} hour${hours !== 1 ? "s" : ""}`;
    }
    if (minutes > 0) {
      if (result) result += " and "; // Add "and" if both hours and minutes are present
      result += `${minutes} minute${minutes !== 1 ? "s" : ""}`;
    }

    return result || "0 minutes"; // Default to "0 minutes" if no time
  }
  // const TimeList:any = formatTime(timeLeft)

  const currentType = List?.data?.question_by_type?.[currentQuestionTypeIndex];

  const [timeget, Settimeget] = useState(
    currentType?.total_timing ||
      formatTime(parseInt(localStorage.getItem("remain_time") ?? "5 minutes"))
  );
  function convertToSeconds(time: string): number {
    if (!time || typeof time !== "string") {
      throw new Error("Time input is undefined or not a string");
    }

    const timeParts = time.split(" ");

    // Ensure we have both a number and a unit
    if (timeParts.length < 2) {
      throw new Error(
        "Invalid time format. Expected format: '<value> <unit>' (e.g., '5 minutes')"
      );
    }

    const value = parseInt(timeParts[0]);
    const unit = timeParts[1].toLowerCase();

    if (isNaN(value)) {
      throw new Error(`Invalid number format in time input: '${timeParts[0]}'`);
    }

    // Supported units
    const validUnits = ["minute", "minutes", "hour", "hours"];

    if (!validUnits.some((validUnit) => unit.includes(validUnit))) {
      throw new Error(
        `Invalid time unit '${unit}'. Supported units are 'minutes' or 'hours'.`
      );
    }

    if (unit.includes("minute")) {
      return value * 60; // Convert minutes to seconds
    } else if (unit.includes("hour")) {
      return value * 60 * 60; // Convert hours to seconds
    }

    // Default fallback (shouldn't reach here due to earlier checks)
    throw new Error("Unexpected error in time conversion");
  }

  const [timeLeft, setTimeLeft] = useState<number>(convertToSeconds(timeget));

  useEffect(() => {
    const currentType =
      List?.data?.question_by_type?.[currentQuestionTypeIndex];

    if (currentType?.questions) {
      const firstQuestionType = currentType?.questions?.map(
        (question: any) => ({
          id: question?.question_id,
          question_type: question?.question_type_name,
          question_type_id: question?.question_type_id,
          question_id: question?.question_id,
          question_url: question?.question_url,
          question: question?.question_text,
          prompt: question?.prompt,
          instruction: question?.instruction,
          options: question?.options?.map((opt: any) => ({
            optionname: opt?.option_text,
            option_id: opt?.option_id,
          })),
          optionsurl: question?.options?.map((opt: any) => ({
            optionname: opt?.option_url === null ? null : opt?.option_url,
            option_id: opt?.option_id,
          })),
        })
      );
      setQuestions(firstQuestionType);
    } else {
      console.warn(
        `No questions found for question type index: ${currentQuestionTypeIndex}`
      );
      setQuestions([]); // Reset questions state if no data
    }
    // time set
    if (currentType?.total_timing) {
      const minutes = currentType?.total_timing || "3 minutes";
      Settimeget(minutes);
    }
  }, [List, currentQuestionTypeIndex]);

  // const [currentQuestion, setCurrentQuestion] = useState<number>(1);
  // const [selectedAnswers, setSelectedAnswers] = useState<{
  //   [sectionIndex: number]: { [questionId: number]: string | null };
  // }>({});

  const [currentQuestion, setCurrentQuestion] = useState(1); // Tracks current question (pagination)
  const [selectedAnswersOption, setSelectedAnswersOption] = useState<
    Record<number, Record<number, number>>
  >({});

  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: number]: string | null;
  }>({});

  const [isExamOver, setIsExamOver] = useState<boolean>(false);
  const [istimeOver, setIstimeOver] = useState<boolean>(false);
  const [istimeOvers, setIstimeOvers] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  // Section List
  const [isSection, setIsSection] = useState<boolean>(false);
  const [isSectionnext, setIsSectionnext] = useState<boolean>(false);

  useEffect(() => {
    // Reset time when question type changes
    if (currentType?.total_timing) {
      const newTimeLeft = convertToSeconds(currentType.total_timing);
      setTimeLeft(newTimeLeft);
    }
  }, [currentQuestionTypeIndex]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    const handleTabChange = () => {
      if (document.hidden) {
        setIstimeOvers(true);
      }
    };
    document.addEventListener("visibilitychange", handleTabChange);

    const preventCopyPaste = (e: ClipboardEvent) => {
      e.preventDefault();
    };
    document.addEventListener("copy", preventCopyPaste);
    document.addEventListener("cut", preventCopyPaste);
    document.addEventListener("paste", preventCopyPaste);

    // Disable spellcheck, autocorrect, autocapitalize, and Grammarly
    const disableTextFieldAttributes = () => {
      const inputs = document.querySelectorAll("input, textarea");
      inputs.forEach((input) => {
        input.setAttribute("spellcheck", "false");
        input.setAttribute("autocorrect", "off");
        input.setAttribute("autocapitalize", "off");
        input.setAttribute("data-gramm", "false"); // Grammarly
      });
    };
    disableTextFieldAttributes();

    if (timeLeft === 0) {
      setIstimeOver(true);
      // clearInterval(timer);
    }
    localStorage.setItem("remain_time", timeLeft.toString());
    return () => {
      clearInterval(timer);
      document.removeEventListener("visibilitychange", handleTabChange);
      document.removeEventListener("copy", preventCopyPaste);
      document.removeEventListener("cut", preventCopyPaste);
      document.removeEventListener("paste", preventCopyPaste);
    };
  }, [timeLeft]);

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  // is for section pagenation
  useEffect(() => {
    setCurrentQuestion(1); // Reset to the first question in the new section
  }, [currentQuestionTypeIndex]);

  const handleAnswerSelect = (questionId: number, option: string) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: option,
    }));
  };

  const clearSelection = () => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion]: "",
    }));

    setSelectedAnswersOption((prev) => {
      const updatedOptions = { ...prev };

      if (updatedOptions[currentQuestionTypeIndex]) {
        delete updatedOptions[currentQuestionTypeIndex][currentQuestion];

        // If there are no more selected answers in this type, remove the object
        if (
          Object.keys(updatedOptions[currentQuestionTypeIndex]).length === 0
        ) {
          delete updatedOptions[currentQuestionTypeIndex];
        }
      }

      return updatedOptions;
    });
  };

  const [wordCount, setWordCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [sentenceCount, setSentenceCount] = useState(0);
  const [paragraphCount, setParagraphCount] = useState(0);

  // Text Editor
  const [editorValue, setEditorValue] = useState<string>(""); // State to store editor value

  const handleChange = (content: any) => {
    // Remove HTML tags and trim whitespace
    const textContent = content?.replace(/<[^>]+>/g, "")?.trim();
    const words = textContent?.split(/\s+/); // Split into words

    // Enforce max word limit (10 words)

    setEditorValue(content);
    setWordCount(words?.length);

    // Character count (excluding spaces)
    const characterCount = textContent?.replace(/\s+/g, "")?.length;
    setCharacterCount(characterCount);

    // Sentence count
    const sentences = textContent
      ?.split(/[.!?]+/)
      .filter((sentence: any) => sentence?.trim()?.length > 0);
    setSentenceCount(sentences.length);

    // Paragraph count
    const paragraphs = textContent
      ?.split(/\n+/)
      .filter((para: any) => para?.trim()?.length > 0);
    setParagraphCount(paragraphs?.length);
  };

  // option list
  const renderQuestionOptions = (
    questionId: number,
    question_type: string,
    options: { optionname: string; option_id: number }[],
    optionsurl: {
      optionname: string;
      option_id: number;
    }[] // Assuming you want to access option_url
  ) => {
    const hasNullUrl = optionsurl.some((option) => option.optionname === null);

    return (
      <Grid
        container
        spacing={question_type === "Reading Comprehension" ? 3 : 1}
        sx={{
          ...(question_type === "Reading Comprehension" ||
          question_type === "Writing"
            ? {
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }
            : {
                backgroundColor: "white",
                marginBottom: "40px",
                position: "fixed",
                bottom: "0",
                padding: "0% 8% 4% 8%",
              }),
        }}
      >
        {question_type === "Writing" ? (
          <>
            <ReactQuill
              className="custom-quill"
              style={{
                width: "100%",
                padding: "20px 0px 20px 8px",
                height: "50vh",
              }}
              theme="snow"
              value={editorValue}
              onChange={handleChange}
              modules={modules}
              formats={formats}
              tabIndex={0} // Ensure it's focusable without enabling spellcheck
              placeholder="Write something here..."
              preserveWhitespace
              data-gramm="false"
              onFocus={() => {
                // Ensure the underlying contenteditable disables spellcheck and autocorrect
                const editorElement = document.querySelector(".ql-editor");
                if (editorElement) {
                  editorElement.setAttribute("spellcheck", "false");
                  editorElement.setAttribute("autocorrect", "off");
                  editorElement.setAttribute("autocapitalize", "off");
                  editorElement.setAttribute("data-gramm", "false"); // Disable Grammarly
                }
              }}
            />
          </>
        ) : hasNullUrl ? (
          options.map((option, index) => (
            <Grid item xs={6} key={index} className="gridwidth">
              <div className="Optionnameflex">
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    fontWeight: "400",
                    textTransform: "capitalize",
                    color: "blue",
                  }}
                >
                  {`${String.fromCharCode(97 + index)})`}
                </span>
                <Box
                  onClick={() => {
                    setSelectedAnswersOption((prev) => ({
                      ...prev,
                      [currentQuestionTypeIndex]: {
                        ...(prev[currentQuestionTypeIndex] || {}),
                        [currentQuestion]: option.option_id,
                      },
                    }));
                    handleAnswerSelect(questionId, option.optionname);
                  }}
                  sx={{
                    border: "1px solid #CDCDCD",
                    borderRadius: "6px",
                    padding:
                      question_type === "Reading Comprehension"
                        ? "10px"
                        : "3px",
                    cursor: "pointer",
                    textAlign: "left",
                    fontSize: "13px",
                    width: "100%",
                    color:
                      selectedAnswersOption[currentQuestionTypeIndex]?.[
                        currentQuestion
                      ] === option.option_id
                        ? "white"
                        : "black",
                    backgroundColor:
                      selectedAnswersOption[currentQuestionTypeIndex]?.[
                        currentQuestion
                      ] === option.option_id
                        ? "#88B61E"
                        : "white",
                    "&:hover": {
                      backgroundColor:
                        selectedAnswersOption[currentQuestionTypeIndex]?.[
                          currentQuestion
                        ] === option.option_id
                          ? "#88B61E"
                          : "#f5f5f5",
                    },
                    display: "flex", // Makes the content centered
                    alignItems: "center", // Vertically centers content
                    height: "100%", // Fills the available height
                  }}
                >
                  {option.optionname}
                </Box>
              </div>
            </Grid>
          ))
        ) : (
          optionsurl.map((option, index) => (
            <Grid item xs={6} key={index}>
              <Box
                onClick={() => {
                  setSelectedAnswersOption((prev) => ({
                    ...prev,
                    [currentQuestionTypeIndex]: {
                      ...(prev[currentQuestionTypeIndex] || {}),
                      [currentQuestion]: option.option_id,
                    },
                  }));
                  handleAnswerSelect(questionId, option.optionname);
                }}
                sx={{
                  border: "1px solid #CDCDCD",
                  borderRadius: "6px",
                  padding: "3px",
                  cursor: "pointer",
                  textAlign: "justify",

                  color:
                    selectedAnswersOption[currentQuestionTypeIndex]?.[
                      currentQuestion
                    ] === option.option_id
                      ? "white"
                      : "black",
                  backgroundColor:
                    selectedAnswersOption[currentQuestionTypeIndex]?.[
                      currentQuestion
                    ] === option.option_id
                      ? "#88B61E"
                      : "white",
                  "&:hover": {
                    backgroundColor:
                      selectedAnswersOption[currentQuestionTypeIndex]?.[
                        currentQuestion
                      ] === option.option_id
                        ? "#88B61E"
                        : "#f5f5f5",
                  },

                  display: "flex", // Makes the content centered
                  alignItems: "center", // Vertically centers content
                  justifyContent: "center", // Horizontally centers content
                  minHeight:
                    question_type === "Reading Comprehension" ? "100px" : "0px", // Ensures uniform height for all boxes
                  height: "100%", // Fills the available height
                }}
              >
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      fontWeight: "400",
                      textTransform: "capitalize",
                    }}
                  >
                    {String.fromCharCode(97 + index)}
                  </span>
                  <img
                    src={option.optionname}
                    alt={`Uploaded ${index}`}
                    style={{
                      maxWidth: "100%",
                      height: "50px", // Adjusts height proportionally
                    }}
                  />
                </div>
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    );
  };

  const [previousTotal, setPreviousTotal] = useState<number>(0);
  // To get the selected option for the current question
  const selectedOptionForCurrentQuestion: any =
    selectedAnswers[currentQuestion];
  // const selectedOptionForCurrentQuestion =
  //   selectedAnswers[currentQuestion]?.[questions[currentQuestion - 1]?.id]; // Access the specific option as a string

  const filterquestionlist = Listoption?.filter((data: any) => {
    return data?.question_id === questions[currentQuestion - 1]?.id;
  });

  // Check if selectedOptionForCurrentQuestion is a URL (blob or regular URL)
  const isUrl =
    selectedOptionForCurrentQuestion?.startsWith("https://") ||
    selectedOptionForCurrentQuestion?.startsWith("http://");

  // Use the appropriate filter based on whether it's a URL or not
  const selectedOptionFilterValue = isUrl
    ? questions[currentQuestion - 1]?.optionsurl?.filter(
        (option) => selectedOptionForCurrentQuestion === option.optionname
      )
    : questions[currentQuestion - 1]?.options?.filter(
        (option) => selectedOptionForCurrentQuestion === option.optionname
      );

  // question image show
  const imageFile = questions[currentQuestion - 1]?.question_url;

  const questionTypesCount = questions.reduce((acc: any, curr: any) => {
    const type = curr.question_type;
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});

  // function calculateBalance(
  //   questions: { [key: string]: number },
  //   currentType: string
  // ) {
  //   // Get the count for the current question type
  //   const currentQuestions = questions[currentType] || 0;
  //   // Update the previous total by adding the current question type count
  //   const updatedTotal = previousTotal + currentQuestions;

  //   // Update the state with the new total
  //   setPreviousTotal(updatedTotal);
  // }

  useEffect(() => {
    // alert("hi");
  }, [questions[currentQuestion - 1]?.question_type]);

  useEffect(() => {
    dispatch(
      listExamAnswerPractice({
        student_test_id: ListStudentExam?.id,
      })
    );
  }, [addExamAnswerRes, editExamAnswerRes]);
  // useEffect(() => {
  //   if (
  //     !answerSliceLoading &&
  //     !addExamAnswerLoading &&
  //     !editExamAnswerLoading &&
  //     isSubmitted
  //   ) {
  //     const sectionlist = List?.data?.questions_by_type?.map(
  //       (questionType: any) => {
  //         const { question_type_id, question_type_name, question_count } =
  //           questionType;

  //         // Filter data to count and check for skipped questions for this question type
  //         const matchingQuestions = StudentExamList?.filter(
  //           (item: any) => item?.questionType_id === question_type_id
  //         );
  //         const actualCount = matchingQuestions?.length;
  //         const skippedCount = matchingQuestions?.filter(
  //           (item: any) => item?.skipped
  //         ).length;

  //         // Determine if this type has pending questions
  //         const hasPending = actualCount !== question_count || skippedCount > 0;
  //         return hasPending;
  //       }
  //     );

  //     if (sectionlist.includes(true)) {
  //       setOpenmessage(true);
  //     } else {
  //       setIsExamOver(true);
  //       setOpenmessage(false);
  //     }
  //   }
  // }, [addExamAnswerRes, editExamAnswerRes, listExamAnswerSlice, isSubmitted]);

  // timeing method
  const timing = `${Math.floor(timeLeft / 60)}:${String(timeLeft % 60).padStart(
    2,
    "0"
  )}`;
  const [lastMinters, setLastMinters] = useState<boolean>(false);
  const [anchorPosition, setAnchorPosition] = useState<{
    vertical: "top" | "bottom";
    horizontal: "left" | "center" | "right";
  }>({
    vertical: "top",
    horizontal: "right",
  });

  useEffect(() => {
    localStorage.setItem(
      "question_type",
      questions[currentQuestion - 1]?.question_type_id?.toString() ?? ""
    );
    localStorage.setItem(
      "question_id",
      questions[currentQuestion - 1]?.question_id?.toString() ?? ""
    );
    localStorage.setItem("response", editorValue);
    localStorage.setItem("pending_time", timing);
    localStorage.setItem("student_exam_id", ListStudentExam?.id);
    if (timing === "3:00") {
      // This function should be called when timing is "5:00"
      const newPosition: any = { vertical: "top", horizontal: "right" };
      setAnchorPosition(newPosition); // Set the position for the Snackbar
      setLastMinters(true); // Open the Snackbar
    }
  }, [timing]);

  // Reload  page

  const [showModal, setShowModal] = useState(false);
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    // Prevent the default unload action and show the modal
    setShowModal(true);
    event.preventDefault();
    // Return empty string for some browsers (like Chrome) to display the confirmation dialog
    // event.returnValue = "";
  };
  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) =>
      handleBeforeUnload(event);
    window.addEventListener("beforeunload", beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, []);

  // timesubmit report
  const { data: EvaluationPracticewritingExam } = useAppSelector(
    (state) => state.ListEvaluationPracticewritingExamSlice
  );
  useEffect(() => {
    if (EvaluationPracticewritingExam?.status) {
      navigate("/examresultpractice", {
        state: {
          id: ListStudentExam?.id,
          type: "Writing",
        },
      });
    }
  }, [EvaluationPracticewritingExam]);

  // zooming
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });

  const toggleZoom = (event: React.MouseEvent) => {
    if (scale === 1) {
      setScale(2); // Zoom in

      // Center the zoom around the click position
      const rect = event.currentTarget.getBoundingClientRect();
      setPosition({
        x: (rect.width / 2 - event.clientX) / 1.5,
        y: (rect.height / 2 - event.clientY) / 1.5,
      });
    } else {
      setScale(1); // Reset zoom
      setPosition({ x: 0, y: 0 }); // Reset position
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (scale === 1) return; // Only allow dragging when zoomed in
    setDragging(true);
    setStart({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!dragging) return;

    // Prevent the image from going too far outside
    const newX = e.clientX - start.x;
    const newY = e.clientY - start.y;

    setPosition({ x: newX, y: newY });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  return (
    <div className="unselectable">
      <div className="onlineexamdiv">
        <div className="Questiontype">
          <div className="examtext">{List?.data?.test_name}</div> &nbsp; :
          &nbsp;
          <div className="Questiontypeheading">
            {questions[currentQuestion - 1]?.question_type}
          </div>
          <div className="onlineexmcount">
            Q. {currentQuestion} of {""}
            {questions?.length}{" "}
          </div>
        </div>
        <div className="Questiontype">
          {/* <div className="">
            <Button onClick={toggleDrawer(true)} className="buttonsection">
              Section
            </Button>
          </div> */}
          <div className="onlineexamtime">
            {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, "0")}
          </div>
        </div>
      </div>
      <div className="onlineexamdivs">
        <Pagination
          count={questions.length} // Total number of questions
          variant="outlined"
          siblingCount={6} // Display 5 sibling pages
          boundaryCount={1} // Show 3 boundary pages
          page={currentQuestion} // Current active page
          onChange={(event, value) => setCurrentQuestion(value)} // Update `currentQuestion` on page change
          renderItem={(item: any) => {
            if (item.type === "previous" || item.type === "next") return null;

            // Check if the current page has an answer
            const currentSectionAnswers =
              selectedAnswersOption[currentQuestionTypeIndex] || {};
            const isAnswered = Boolean(currentSectionAnswers[item.page]);
            const color = isAnswered ? "blue" : "white";

            return (
              <Button
                key={item.page}
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  color: item.page === currentQuestion ? "white" : "black",
                  backgroundColor:
                    item.page === currentQuestion ? "blue" : color,
                  minWidth: "unset",
                  padding: 0,
                  marginLeft: "5px",
                  marginTop: "3px",
                }}
                onClick={() => setCurrentQuestion(item.page)} // Navigate to the selected question
              >
                {item.page}
              </Button>
            );
          }}
        />
      </div>
      {questions[currentQuestion - 1]?.question_type ===
        "Reading Comprehension" ||
      questions[currentQuestion - 1]?.question_type === "Writing" ? (
        <div className="overonlinediv">
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              width: "50%",
              borderRight: "1px solid #cecece",
            }}
          >
            <div className="examscrollreading">
              <Typography variant="h5" sx={{ paddingRight: "10px" }}>
                {questions[currentQuestion - 1]?.question_type === "Writing" ? (
                  <>
                    <Typography
                      sx={{
                        fontSize: "1.3rem",
                        marginBottom: "20px",
                        textAlign: "justify",
                        fontWeight: 600,
                      }}
                    >
                      Use this idea as the basis for a piece of writing. You may
                      write in any style.
                    </Typography>
                    {questions[currentQuestion - 1]?.question_url && (
                      <Box
                        textAlign="center"
                        sx={{
                          overflow: "hidden",
                          userSelect: "none", // Prevent text selection while dragging
                          cursor: scale > 1 ? "grab" : "zoom-in",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={imageFile}
                          alt="Zoomable"
                          onClick={toggleZoom}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseUp} // Stop dragging if the mouse leaves
                          style={{
                            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                            transition: dragging
                              ? "none"
                              : "transform 0.3s ease",
                            cursor: dragging
                              ? "grabbing"
                              : scale > 1
                              ? "grab"
                              : "zoom-in",
                            maxWidth: "100%",
                            maxHeight: "80vh", // Prevents image from being too large
                            objectFit: "contain", // Keeps aspect ratio
                          }}
                        />
                      </Box>
                      // <Typography
                      //   variant="h5"
                      //   sx={{
                      //     marginBottom: "20px",
                      //     textAlign: "left",
                      //     position: "relative", // Ensure proper positioning of the image
                      //     overflow: "visible", // Ensure the enlarged image is not clipped
                      //     "& img": {
                      //       maxWidth: "100%",
                      //       height:
                      //         List?.data?.question_by_type[0]
                      //           ?.question_type_name === "Writing"
                      //           ? "50rem"
                      //           : "100%",
                      //       cursor: "pointer",
                      //       transition: "transform 0.3s ease",
                      //       position: "relative",
                      //       transformOrigin: "left center", // Keep the hover scaling aligned to the left
                      //     },
                      //     "& img:hover": {
                      //       transform: "scale(1.5)",
                      //     },
                      //   }}
                      // >
                      //   <img src={imageFile} alt="Uploaded preview" />
                      // </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "20px",
                        textAlign: "justify",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {questions[currentQuestion - 1]?.prompt}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "20px",
                        textAlign: "justify",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {questions[currentQuestion - 1]?.instruction}
                    </Typography>
                  </>
                )}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              width: "50%",
              padding: "10px",
            }}
          >
            <div className="examscrollreading">
              <Typography variant="h5">
                {questions[currentQuestion - 1]?.question_type ===
                  "Reading Comprehension" && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "1.3rem",
                        marginBottom: "20px",
                        textAlign: "justify",
                        fontWeight: 600,
                      }}
                    >
                      {questions[currentQuestion - 1]?.question}
                    </Typography>

                    {questions[currentQuestion - 1]?.question_url && (
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "left",
                          position: "relative", // Ensure proper positioning of the image
                          overflow: "visible", // Ensure the enlarged image is not clipped
                          "& img": {
                            maxWidth: "100%",
                            height:
                              List?.data?.question_by_type[0]
                                ?.question_type_name === "Writing"
                                ? "30rem"
                                : "100%",
                            cursor: "pointer",
                            transition: "transform 0.3s ease",
                            position: "relative",
                            transformOrigin: "left center", // Keep the hover scaling aligned to the left
                          },
                          "& img:hover": {
                            transform: "scale(1.5)",
                          },
                        }}
                      >
                        <img src={imageFile} alt="Uploaded preview" />
                      </Typography>
                    )}
                  </>
                )}

                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "20px",
                    textAlign: "justify",
                    whiteSpace: "break-spaces",
                  }}
                >
                  {List?.data?.questions?.length === 0 ? (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "larger",
                        marginTop: "30%",
                      }}
                    >
                      No Question Found
                    </div>
                  ) : (
                    renderQuestionOptions(
                      currentQuestion,
                      questions[currentQuestion - 1]?.question_type,
                      questions[currentQuestion - 1]?.options || [],
                      questions[currentQuestion - 1]?.optionsurl || []
                    )
                  )}{" "}
                  {questions[currentQuestion - 1]?.question_type ===
                    "Writing" &&
                    wordCount < 120 && (
                      <div className="vaidationforwriting">
                        <InfoIcon />
                        <div>
                          {" "}
                          Please write a minimum of 120 words to submit the exam
                        </div>
                      </div>
                    )}
                  {questions[currentQuestion - 1]?.question_type ===
                    "Writing" && (
                    <Box sx={{ maxWidth: 600, mx: "auto", marginTop: "20px" }}>
                      {/* Responsive Cards */}
                      <Grid container spacing={2}>
                        {[
                          { label: "Words", value: wordCount },
                          { label: "Characters", value: characterCount },
                          { label: "Sentences", value: sentenceCount },
                          { label: "Paragraphs", value: paragraphCount },
                        ]?.map((item, index) => (
                          <Grid item xs={6} sm={3} key={index}>
                            <Card
                              sx={{
                                textAlign: "center",
                                p: 2,
                                borderRadius: "10px",
                                boxShadow: 2,
                                background: "#f8f9fa",
                              }}
                            >
                              <Typography variant="h5" fontWeight="bold">
                                {item.value}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {item.label}
                              </Typography>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </Typography>
              </Typography>
            </div>
          </Grid>
        </div>
      ) : (
        <>
          <div className="overonlinediv">
            <Grid item xs={12} md={6} sx={{ width: "100%" }}>
              <div className="examscroll">
                <Typography
                  variant="h5"
                  // className="examscroll"
                  // sx={{
                  //   height: "550px", // Fixed height
                  //   overflow: "auto", // Enable scrolling
                  //   overflowY: "scroll",
                  // }}
                >
                  {questions[currentQuestion - 1]?.question_type ===
                  "Writing" ? (
                    <Typography
                      sx={{
                        fontSize: "1.3rem",
                        marginBottom: "20px",
                        textAlign: "justify",
                        fontWeight: 600,
                      }}
                    >
                      Use this idea as the basis for a piece of writing. You may
                      write in any style.
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "justify",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {questions[currentQuestion - 1]?.prompt}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "justify",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {questions[currentQuestion - 1]?.instruction}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          textAlign: "justify",
                          fontWeight: 600,
                        }}
                      >
                        {questions[currentQuestion - 1]?.question}
                      </Typography>
                    </>
                  )}

                  {questions[currentQuestion - 1]?.question_url && (
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "20px",
                        textAlign: "left",
                        position: "relative", // Ensure proper positioning of the image
                        overflow: "visible", // Ensure the enlarged image is not clipped
                        "& img": {
                          maxWidth: "100%",
                          height:
                            List?.data?.question_by_type[0]
                              ?.question_type_name === "Writing"
                              ? "30rem"
                              : "100%",
                          cursor: "pointer",
                          transition: "transform 0.3s ease",
                          position: "relative",
                          transformOrigin: "left center", // Keep the hover scaling aligned to the left
                        },
                        "& img:hover": {
                          transform: "scale(1.5)",
                        },
                      }}
                    >
                      <img src={imageFile} alt="Uploaded preview" />
                    </Typography>
                  )}
                </Typography>
              </div>
            </Grid>
          </div>

          {List?.data?.questions?.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "larger",
                marginTop: "30%",
              }}
            >
              No Question Found
            </div>
          ) : (
            renderQuestionOptions(
              currentQuestion,
              questions[currentQuestion - 1]?.question_type,
              questions[currentQuestion - 1]?.options || [],
              questions[currentQuestion - 1]?.optionsurl || []
            )
          )}
        </>
      )}

      <div className="navigation-wrapper"></div>
      <div className="navigation-buttons">
        {List?.data?.question_by_type[0]?.question_type_name ===
        "Writing" ? null : (
          <div style={{ display: "flex" }}>
            <Button
              onClick={clearSelection}
              sx={{ textTransform: "capitalize", fontSize: "14px" }}
            >
              <SvgIcon
                component={Deleteicon}
                inheritViewBox
                sx={{ marginRight: "5px" }}
              />
              Clear
            </Button>
          </div>
        )}

        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Button
            className="buttonnameprevious"
            sx={{ color: "#009DFF" }}
            onClick={() => setCurrentQuestion((prev) => Math.max(prev - 1, 1))}
            disabled={currentQuestion === 1}
          >
            Previous
          </Button>

          {currentQuestion === questions?.length ? (
            currentQuestionTypeIndex <
            List?.data?.question_by_type?.length - 1 ? (
              <Button
                className="buttonnamenext"
                variant="contained"
                color="primary"
                onClick={() => {
                  if (
                    filterquestionlist?.length > 0 &&
                    filterquestionlist[0]?.id
                  ) {
                    dispatch(
                      addExamAnswerPractice({
                        id: filterquestionlist[0]?.id,
                        student_test_id: ListStudentExam?.id,
                        question_id: questions[currentQuestion - 1]?.id,
                        option_id: selectedOptionFilterValue
                          ? selectedOptionFilterValue[0]?.option_id
                          : null,
                        remaining_time: timing,
                        skipped: !selectedOptionFilterValue[0]?.option_id,
                        question_type_id:
                          questions[currentQuestion - 1]?.question_type_id,
                      })
                    );
                  } else {
                    dispatch(
                      addExamAnswerPractice({
                        student_test_id: ListStudentExam?.id,
                        question_id: questions[currentQuestion - 1]?.id,
                        option_id: selectedOptionFilterValue
                          ? selectedOptionFilterValue[0]?.option_id
                          : null,
                        remaining_time: timing,
                        skipped: !selectedOptionFilterValue[0]?.option_id,
                        question_type_id:
                          questions[currentQuestion - 1]?.question_type_id,
                      })
                    );
                  }
                  setIsSection(true);
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                className="buttonnamenext"
                variant="contained"
                color="primary"
                title={
                  List?.data?.question_by_type[0]?.question_type_name ===
                  "Writing"
                    ? "50 Words to must"
                    : ""
                }
                disabled={
                  List?.data?.question_by_type[0]?.question_type_name ===
                    "Writing" && wordCount < 120
                }
                onClick={() => {
                  if (
                    List?.data?.question_by_type[0]?.question_type_name !==
                    "Writing"
                  ) {
                    if (
                      filterquestionlist?.length > 0 &&
                      filterquestionlist[0]?.id
                    ) {
                      dispatch(
                        addExamAnswerPractice({
                          id: filterquestionlist[0]?.id,
                          student_test_id: ListStudentExam?.id,
                          question_id: questions[currentQuestion - 1]?.id,
                          option_id: selectedOptionFilterValue
                            ? selectedOptionFilterValue[0]?.option_id
                            : null,
                          remaining_time: timing,
                          skipped: !selectedOptionFilterValue[0]?.option_id,
                          question_type_id:
                            questions[currentQuestion - 1]?.question_type_id,
                        })
                      );
                    } else {
                      dispatch(
                        addExamAnswerPractice({
                          student_test_id: ListStudentExam?.id,
                          question_id: questions[currentQuestion - 1]?.id,
                          option_id: selectedOptionFilterValue
                            ? selectedOptionFilterValue[0]?.option_id
                            : null,
                          remaining_time: timing,
                          skipped: !selectedOptionFilterValue[0]?.option_id,
                          question_type_id:
                            questions[currentQuestion - 1]?.question_type_id,
                        })
                      );
                    }
                  }

                  setIsSubmitted(true);
                }}
              >
                Submit
              </Button>
            )
          ) : (
            <Button
              className="buttonnamenext"
              sx={{ zIndex: 1111 }}
              onClick={() => {
                setCurrentQuestion((prev) =>
                  Math.min(prev + 1, questions?.length)
                );
                if (
                  filterquestionlist?.length > 0 &&
                  filterquestionlist[0]?.id
                ) {
                  dispatch(
                    addExamAnswerPractice({
                      id: filterquestionlist[0]?.id,
                      student_test_id: ListStudentExam?.id,
                      question_id: questions[currentQuestion - 1]?.id,
                      option_id: selectedOptionFilterValue
                        ? selectedOptionFilterValue[0]?.option_id
                        : null,
                      remaining_time: timing,
                      skipped: !selectedOptionFilterValue[0]?.option_id,
                      question_type_id:
                        questions[currentQuestion - 1]?.question_type_id,
                    })
                  );
                } else {
                  dispatch(
                    addExamAnswerPractice({
                      student_test_id: ListStudentExam?.id,
                      question_id: questions[currentQuestion - 1]?.id,
                      option_id: selectedOptionFilterValue
                        ? selectedOptionFilterValue[0]?.option_id
                        : null,
                      remaining_time: timing,
                      skipped: !selectedOptionFilterValue[0]?.option_id,
                      question_type_id:
                        questions[currentQuestion - 1]?.question_type_id,
                    })
                  );
                }
              }}
              disabled={currentQuestion === questions?.length}
            >
              Next
            </Button>
          )}
        </div>
      </div>
      {/* 
      <Dialog
        open={openmessage}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "#009DFF" }}>
          {"Pending Sections List"}
        </DialogTitle>
        <DialogContent sx={{ width: "35rem" }}>
          <DialogContentText id="alert-dialog-description">
            {List?.data?.questions_by_type?.map((questionType: any) => {
              const { question_type_id, question_type_name, question_count } =
                questionType;

              // Filter data to count and check for skipped questions for this question type
              const matchingQuestions = StudentExamList?.filter(
                (item: any) => item?.questionType_id === question_type_id
              );
              const actualCount = matchingQuestions?.length;
              const skippedCount = matchingQuestions?.filter(
                (item: any) => item?.skipped
              ).length;

              // Determine if this type has pending questions
              const hasPending =
                actualCount !== question_count || skippedCount > 0;

              if (hasPending) {
                return (
                  <div key={question_type_id}>
                    <p>
                      <Brightness1Icon
                        sx={{
                          marginRight: "10px",
                          color: "#009DFF",
                          fontSize: "12px",
                        }}
                      />
                      {question_type_name}
                    </p>
                  </div>
                );
              }
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <ButtonField className="cancel_button" onClick={handleClose}>
            Back
          </ButtonField>
          <ButtonField
            className="message_button"
            onClick={() => setIsExamOver(true)}
          >
            Next
          </ButtonField>
        </DialogActions>
      </Dialog> */}
      {/* submit exam popup */}
      <MessageDialogTest
        messageheading={"Submit Exam"}
        messageableName={"When you are ready, click submit button"}
        open={isSubmitted}
        setOpen={setIsExamOver}
        exam_id={exam_id?.examID}
        valueofdata={ListStudentExam?.id}
        setIsSubmitted={setIsSubmitted}
        student_exam_id={ListStudentExam?.id}
        remaining_time={timing}
        question_type_id={questions[currentQuestion - 1]?.question_type_id}
        Question_id={questions[currentQuestion - 1]?.question_id}
        condition={
          List?.data?.question_by_type[0]?.question_type_name === "Writing"
        }
        writingSentence={editorValue}
      />

      {/* time up message  */}
      <AlterDialog
        alterheading={"Time’s Up!"}
        alterableName={
          currentQuestionTypeIndex < List?.data?.question_by_type?.length - 1
            ? "The allocated time for this section has ended. You are now being moved to the next section. Your responses for this section have been saved automatically."
            : "The allocated time for this section has ended. You will now be redirected to the result page. Your responses have been saved automatically."
        }
        altermessage={
          currentQuestionTypeIndex < List?.data?.question_by_type?.length - 1
            ? "Please proceed to the next section."
            : "Please wait while we calculate your results."
        }
        open={istimeOver}
        setOpen={setIstimeOver}
        valueofdata={ListStudentExam?.id}
        buttonname={
          <>
            <ButtonField
              className="alter_button"
              onClick={() => {
                if (
                  currentQuestionTypeIndex <
                  List?.data?.question_by_type?.length - 1
                ) {
                  setCurrentQuestionTypeIndex((prev) => prev + 1);
                } else {
                  if (
                    List?.data?.question_by_type[0]?.question_type_name ===
                    "Writing"
                  ) {
                    dispatch(
                      listEvaluationPracticewritingExam({
                        sentence: editorValue,
                        student_test_id: ListStudentExam?.id,
                        question_id:
                          questions[currentQuestion - 1]?.question_id,
                      })
                    );
                  } else {
                    dispatch(
                      listEvaluationPractice({
                        student_test_id: ListStudentExam?.id,
                      })
                    );

                    navigate("/examresultpractice", {
                      state: ListStudentExam?.id,
                    });
                  }
                  dispatch(PopupStudentExam({ closed: false }));
                }

                setIstimeOver(false);
              }}
            >
              {currentQuestionTypeIndex <
              List?.data?.question_by_type?.length - 1
                ? "Continue"
                : "Proceed"}
            </ButtonField>
          </>
        }
      />
      <AlterDialog
        alterheading={"Warning"}
        alterableName={"Your answers have not been saved."}
        open={showModal}
        setOpen={setShowModal}
        buttonname={
          <>
            <ButtonField
              className="form_save_button"
              onClick={() => {
                setShowModal(false);
                window.location.reload(); // Or navigate to a different page if needed
              }}
            >
              Yes
            </ButtonField>
            <ButtonField
              className="form_cancel_button"
              onClick={() => {
                setShowModal(false);
              }}
            >
              No
            </ButtonField>
          </>
        }
      />

      <Snackbar
        anchorOrigin={anchorPosition}
        open={lastMinters}
        onClose={() => {
          setLastMinters(false);
        }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#4d7aa3", // Background color of the Snackbar content
            color: "white", // Text color
          },
        }}
        message={
          <span style={{ display: "flex", alignItems: "center" }}>
            <WarningAmberIcon style={{ marginRight: "8px" }} />
            Last 3 Minutes
          </span>
        }
        key={`${anchorPosition.vertical}-${anchorPosition.horizontal}`}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
      />
    </div>
  );
};

export default OnlineExamPractice;

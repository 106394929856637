import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStudentPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";

export const EditStudentExamPracticeSlice = createSlice({
  name: "EditStudentExamPracticeSlice",
  initialState: {} as IReduxState<IStudentPracticeExam>,
  reducers: {
    EditStudentExamPractice: (
      state,
      _: PayloadAction<IStudentPracticeExam>
    ) => {
      state.isLoading = true;
    },
    successEditStudentExamPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureEditStudentExamPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },

    clearEditStudentExamPracticeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  EditStudentExamPractice,
  failureEditStudentExamPractice,
  successEditStudentExamPractice,
  clearEditStudentExamPracticeResponse,
} = EditStudentExamPracticeSlice.actions;

export default EditStudentExamPracticeSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IWritingExam } from "../../../Pages/DashboardUser/IDashboardUser";

export const ListWritingExamAIPracticeSlice = createSlice({
  name: "ListWritingExamAIPracticeSlice",
  initialState: {} as IReduxState<IWritingExam[]>,

  reducers: {
    listListWritingExamAIPracticeSlice: (
      state,
      _: PayloadAction<IWritingExam>
    ) => {
      state.isLoading = true;
    },
    successListWritingExamAIPracticeSlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureListWritingExamAIPracticeSlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  listListWritingExamAIPracticeSlice,
  successListWritingExamAIPracticeSlice,
  failureListWritingExamAIPracticeSlice,
} = ListWritingExamAIPracticeSlice.actions;

export default ListWritingExamAIPracticeSlice.reducer;

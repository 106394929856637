import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IOpenSection } from "../../../Pages/Exam/IExam";

export const AddOpenSectionSlice = createSlice({
  name: "AddOpenSectionSlice",
  initialState: {} as IReduxState<IOpenSection>,
  reducers: {
    addOpenSection: (state, _: PayloadAction<IOpenSection>) => {
      state.isLoading = true;
    },
    successaddOpenSection: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddOpenSection: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddOpenSectionResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addOpenSection,
  successaddOpenSection,
  failureaddOpenSection,
  clearAddOpenSectionResponse,
} = AddOpenSectionSlice.actions;

export default AddOpenSectionSlice.reducer;

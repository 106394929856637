import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failurelistReadingPrompt,
  successlistReadingPrompt,
} from "./ListReadingPromptSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IReadingPrompt } from "../../../Pages/DashboardUser/IDashboardUser";

// list
const ListReadingPromptApi = (action: PayloadAction<IReadingPrompt>) => {
  return api.get("/v1/api/question/prompt", { params: action.payload });
};

export function* ListReadingPromptCall(action: PayloadAction<IReadingPrompt>) {
  try {
    const response: AxiosResponse = yield call(ListReadingPromptApi, action);
    yield put(successlistReadingPrompt(response.data));
  } catch (error) {
    yield put(failurelistReadingPrompt(error));
  }
}

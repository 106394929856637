import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";
import { IStartPracticeExam } from "../../../Pages/Instruction/IInstruction";

export const ListPracticeExamSlice = createSlice({
  name: "ListPracticeExamSlice",
  initialState: {} as IReduxState<IStartPracticeExam>,
  reducers: {
    listPracticeExam: (state) => {
      state.isLoading = true;
    },
    successlistPracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistPracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistPracticeExam,
  listPracticeExam,
  successlistPracticeExam,
} = ListPracticeExamSlice.actions;

export default ListPracticeExamSlice.reducer;

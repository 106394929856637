import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureaddExamHistory,
  successaddExamHistory,
} from "./AddExamHistorySlice";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  failuredeleteExamHistory,
  successdeleteExamHistory,
} from "./DeleteExamHistorySlice";
import {
  failurelistExamHistory,
  successlistExamHistory,
} from "./ListExamHistorySlice";
import {
  failureEditExamHistory,
  successEditExamHistory,
} from "./EditExamHistorySlice";
import { IExamHistory } from "../../../Pages/Exam_Histroy/IExam_History";
import {
  failurelistExamHistoryReWritingSlice,
  successlistExamHistoryReWritingSlice,
} from "./ListExamHistoryReWritingSlice";
import {
  failurelistMockExamHistory,
  successlistMockExamHistory,
} from "./ListMockExamHistorySlice";

// list
const ListExamHistoryApi = () => {
  return api.get("/v1/api/exam/exam-history");
};

export function* ListExamHistoryCall() {
  try {
    const response: AxiosResponse = yield call(ListExamHistoryApi);
    yield put(successlistExamHistory(response.data));
  } catch (error) {
    yield put(failurelistExamHistory(error));
  }
}
// list
const ListMockExamHistoryApi = () => {
  return api.get("/v1/api/exam/mockExam-history");
};

export function* ListMockExamHistoryCall() {
  try {
    const response: AxiosResponse = yield call(ListMockExamHistoryApi);
    yield put(successlistMockExamHistory(response.data));
  } catch (error) {
    yield put(failurelistMockExamHistory(error));
  }
}

// list rewriting
const ListExamHistoryReWritingSliceApi = () => {
  return api.get("/v1/api/exam/history");
};

export function* ListExamHistoryReWritingSliceCall() {
  try {
    const response: AxiosResponse = yield call(
      ListExamHistoryReWritingSliceApi
    );
    yield put(successlistExamHistoryReWritingSlice(response.data));
  } catch (error) {
    yield put(failurelistExamHistoryReWritingSlice(error));
  }
}

// add
const AddExamHistoryApi = (action: PayloadAction<IExamHistory>) => {
  return api.post("/v1/api/question/add-question", action.payload);
};

export function* AddExamHistoryCall(action: PayloadAction<IExamHistory>) {
  try {
    const response: AxiosResponse = yield call(AddExamHistoryApi, action);
    yield put(successaddExamHistory(response.data));
  } catch (error) {
    yield put(failureaddExamHistory(error));
  }
}

// edit
const EditExamHistoryApi = (action: PayloadAction<IExamHistory>) => {
  return api.put("/v1/api/question/update/", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditExamHistoryCall(action: PayloadAction<IExamHistory>) {
  try {
    const response: AxiosResponse = yield call(EditExamHistoryApi, action);
    yield put(successEditExamHistory(response.data));
  } catch (error) {
    yield put(failureEditExamHistory(error));
  }
}

// delete
const DeleteExamHistoryApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/question/delete/", {
    params: { id: action.payload },
  });
};

export function* DeleteExamHistoryCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteExamHistoryApi, action);
    yield put(successdeleteExamHistory(response.data));
  } catch (error) {
    yield put(failuredeleteExamHistory(error));
  }
}

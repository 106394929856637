import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPracticeExamlistID } from "../../../Pages/PracticeExam/IPracticeExam";
import { IStartPracticeExam } from "../../../Pages/Instruction/IInstruction";

export const ListPracticeExamSlice = createSlice({
  name: "ListPracticeExamIDSlice",
  initialState: {} as IReduxState<IStartPracticeExam>,
  reducers: {
    listPracticeExamID: (state, _: PayloadAction<IPracticeExamlistID>) => {
      state.isLoading = true;
    },
    successlistPracticeExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistPracticeExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearListPracticeExamID: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistPracticeExamID,
  listPracticeExamID,
  successlistPracticeExamID,
  clearListPracticeExamID,
} = ListPracticeExamSlice.actions;

export default ListPracticeExamSlice.reducer;

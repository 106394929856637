import "./MessageDialogTest.scss";
import { FunctionComponent, useEffect, useState } from "react";
import { MessageDialogProps } from "./IMessageDialogTest";
import Dialog from "../Dialog/Dialog";
import IconButtonField from "../Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Cancel } from "../../Assets/Images/Cancel.svg";
import ButtonField from "../Button/ButtonField";
import { ReactComponent as Success } from "../../Assets/Images/success.svg";
import { ReactComponent as Failure } from "../../Assets/Images/failure.svg";
import ResponseDialog from "../ResponseDialog/ResponseDialog";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listExamsubmit } from "../../Redux/Slices/ExamAnswer/ListExamsubmitSlice";
import { addCloseSection } from "../../Redux/Slices/Exam/AddCloseSectionSlice";
import { listExamsubmitPractice } from "../../Redux/Slices/ExamAnswerPractice/ListExamsubmitPracticeSlice";
import {
  clearlistEvaluationPracticewritingExam,
  listEvaluationPracticewritingExam,
} from "../../Redux/Slices/PracticeExam/ListEvaluationPracticewritingExamSlice";

const MessageDialogTest: FunctionComponent<MessageDialogProps> = (props) => {
  const {
    open,
    setOpen,
    messageableName,
    messageheading,
    valueofdata,
    condition,
    writingSentence,
    Question_id,
    exam_id,
    setIsSubmitted,
    student_exam_id,
    remaining_time,
    question_type_id,
  } = props;
  const [responseDialog, setResponseDialog] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
    setResponseDialog(false);
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const final: any = valueofdata;
  const { data: EvaluationPracticewritingExam } = useAppSelector(
    (state) => state.ListEvaluationPracticewritingExamSlice
  );

  useEffect(() => {
    if (EvaluationPracticewritingExam?.status) {
      navigate("/examresultpractice", {
        state: { id: final, type: "Writing" },
      });
    }
  }, [EvaluationPracticewritingExam]);

  useEffect(() => {
    return () => {
      dispatch(clearlistEvaluationPracticewritingExam());
    };
  }, []);

  return (
    <>
      <Dialog
        open={open}
        dialogClassName="message_dialog"
        dialogTitleClassName="message_dialog_title"
        Dialog_Title={<>{messageheading}</>}
        dialogContentClassName="message_dialog_content"
        Dialog_Content={<>{messageableName}</>}
        dialogActionClassName="message_dialog_action"
        Dialog_Actions={
          <>
            <ButtonField
              className="cancel_button"
              onClick={() => {
                setOpen(false);
                setIsSubmitted && setIsSubmitted(false);
              }}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="message_button"
              onClick={() => {
                if (condition === false) {
                  dispatch(
                    listExamsubmitPractice({
                      id: final,
                    })
                  );

                  setIsSubmitted && setIsSubmitted(false);
                  navigate("/submitexampractice", { state: final }); // Replace with your target route
                } else {
                  dispatch(
                    listEvaluationPracticewritingExam({
                      sentence: writingSentence,
                      student_test_id: final,
                      question_id: Number(Question_id),
                    })
                  );

                  setIsSubmitted && setIsSubmitted(false);
                  // Replace with your target route
                }
              }}
            >
              Submit
            </ButtonField>
          </>
        }
      />
    </>
  );
};

export default MessageDialogTest;

import { Autocomplete, TextField } from "@mui/material";
import { FunctionComponent, useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { listReadingPrompt } from "../../Redux/Slices/ReadingPrompt/ListReadingPromptSlice";

export interface SelectProps {
  name: string;
  label?: string;
  options: { label: string; value: number }[];
  value?: { label: string; value: number } | null;
  onChange?: (value: { label: string; value: number } | null) => void;
}

const Autocompleted: FunctionComponent<SelectProps> = ({
  name,
  label,
  options,
  onChange,
  value,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext(); // Extract errors
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(value?.label || "");
  const [selectedValue, setSelectedValue] = useState(value || null);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
      setInputValue(value.label);
    }
  }, [value]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={(option) => option.label}
          value={selectedValue}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
            if (newInputValue.length > 0) {
              dispatch(listReadingPrompt({ keyword: newInputValue }));
            }
          }}
          onChange={(_, selectedOption) => {
            setSelectedValue(selectedOption || null);
            setInputValue(selectedOption?.label || "");
            if (selectedOption) {
              setValue(name, selectedOption.value, { shouldValidate: true });
              dispatch(listReadingPrompt({}));
              if (onChange) onChange(selectedOption);
            } else {
              setValue(name, null, { shouldValidate: true });
              if (onChange) onChange(null);
            }
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                }}
              >
                {option.label}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]} // Check if there's an error
              helperText={errors[name]?.message as string} // Display error message
            />
          )}
        />
      )}
    />
  );
};

export default Autocompleted;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IEvaluationPracticewritingExam } from "../../../Pages/Exam/IExam";

export const ListEvaluationPracticewritingExamSlice = createSlice({
  name: "ListEvaluationPracticewritingExamSlice",
  initialState: {} as IReduxState<IEvaluationPracticewritingExam[]>,
  reducers: {
    listEvaluationPracticewritingExam: (
      state,
      _: PayloadAction<IEvaluationPracticewritingExam>
    ) => {
      state.isLoading = true;
    },
    successlistEvaluationPracticewritingExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistEvaluationPracticewritingExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearlistEvaluationPracticewritingExam: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistEvaluationPracticewritingExam,
  listEvaluationPracticewritingExam,
  successlistEvaluationPracticewritingExam,
  clearlistEvaluationPracticewritingExam,
} = ListEvaluationPracticewritingExamSlice.actions;

export default ListEvaluationPracticewritingExamSlice.reducer;

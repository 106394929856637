import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamHistory } from "../../../Pages/Exam_Histroy/IExam_History";

export const ListMockExamHistorySlice = createSlice({
  name: "ListMockExamHistorySlice",
  initialState: {} as IReduxState<IExamHistory[]>,
  reducers: {
    listMockExamHistory: (state) => {
      state.isLoading = true;
    },
    successlistMockExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistMockExamHistory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistMockExamHistory,
  listMockExamHistory,
  successlistMockExamHistory,
} = ListMockExamHistorySlice.actions;

export default ListMockExamHistorySlice.reducer;

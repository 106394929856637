import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";

export const AddPracticeExamSlice = createSlice({
  name: "AddPracticeExamSlice",
  initialState: {} as IReduxState<IPracticeExam>,
  reducers: {
    addPracticeExam: (state, _: PayloadAction<IPracticeExam>) => {
      state.isLoading = true;
    },
    successaddPracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddPracticeExam: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddPracticeExamResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addPracticeExam,
  failureaddPracticeExam,
  successaddPracticeExam,
  clearAddPracticeExamResponse,
} = AddPracticeExamSlice.actions;

export default AddPracticeExamSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamAnswerPractice } from "../../../Pages/Exam participation/IExam_participation";

export const AddExamAnswerPracticeSlice = createSlice({
  name: "AddExamAnswerPracticeSlice",
  initialState: {} as IReduxState<IExamAnswerPractice>,
  reducers: {
    addExamAnswerPractice: (state, _: PayloadAction<IExamAnswerPractice>) => {
      state.isLoading = true;
    },
    successaddExamAnswerPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddExamAnswerPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddExamAnswerPracticeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addExamAnswerPractice,
  failureaddExamAnswerPractice,
  successaddExamAnswerPractice,
  clearAddExamAnswerPracticeResponse,
} = AddExamAnswerPracticeSlice.actions;

export default AddExamAnswerPracticeSlice.reducer;

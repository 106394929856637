import { FunctionComponent, useEffect, useRef, useState } from "react";
import StarIcon from "@mui/icons-material/Star"; // Import a star icon from MUI
import "./ExamResultPractice.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ButtonField from "../../Components/Button/ButtonField";
import { add } from "lodash";
import { listEvaluation } from "../../Redux/Slices/Exam/ListEvaluationSlice";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { listEvaluationPractice } from "../../Redux/Slices/PracticeExam/ListEvaluationPracticeSlice";
import { useLocation } from "react-router-dom";
import { listEvaluationPracticewritingExam } from "../../Redux/Slices/PracticeExam/ListEvaluationPracticewritingExamSlice";
import { Box, Typography, Paper, Grid, Modal, Divider } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AppBarnav from "../../Components/AppBar/AppBar";
import Loading from "../../Components/Loading/Loading";

interface ExamResultPracticeProps {}

const ExamResultPractice: FunctionComponent<ExamResultPracticeProps> = () => {
  const navigate = useNavigate();
  const { state: review_id } = useLocation();
  const location = useLocation(); // This gives you the current location object
  const query = new URLSearchParams(location.search);
  const id = query.get("id"); // Extract the 'id' parameter from the URL
  const Etype = query.get("type"); // Extract the 'type' parameter from the

  const dispatch = useAppDispatch();

  const { data: listEvaluationSlice, isLoading: EvaluationSlice } =
    useAppSelector((state) => state.ListEvaluationPracticeSlice);
  const {
    data: EvaluationPracticewritingExam,
    isLoading: PracticewritingExam,
  } = useAppSelector((state) => state.ListEvaluationPracticewritingExamSlice);

  const Add: any = listEvaluationSlice?.data;

  const datalistresult = [
    { name: "Total Correct", value: Add?.total_correct, fill: "#4CAF50" },
    { name: "Total Incorrect", value: Add?.total_wrong, fill: "red" },
    { name: "Total Skipped", value: Add?.total_skipped, fill: "yellow" },

    // color for Group A
  ];

  const datalistone = [
    { name: "Total Correct", value: Add?.total_correct, fill: "#88B61E" },
    {
      name: "Total Question",
      value: Add?.total_questions,
      fill: "#eaeaea",
    },
  ];

  const datalisttwo = [
    { name: "Total Incorrect", value: Add?.total_wrong, fill: "red" },
    {
      name: "Total Question",
      value: Add?.total_questions,
      fill: "#eaeaea",
    },
    // color for Group A
  ];
  const datalistthird = [
    { name: "Total Skipped", value: Add?.total_skipped, fill: "yellow" },
    {
      name: "Total Question",
      value: Add?.total_questions,
      fill: "#eaeaea",
    },
    // color for Group A
  ];

  const data = [
    { name: "Correct", value: Add?.total_correct, fill: "#4CAF50" }, // Green for correct
    { name: "Incorrect", value: Add?.total_wrong, fill: "#F44336" }, // Red for incorrect
    { name: "Skipped", value: Add?.total_skipped, fill: "#FF9800" }, // Orange for skipped
  ];

  const total_questions = Add?.total_questions; // Example for Y-axis max value

  const percentage = Math.round(
    (Add?.total_correct / Add?.total_questions) * 100
  );
  const Incorrect = Math.round((Add?.total_wrong / Add?.total_questions) * 100);
  const Skipped = Math.round((Add?.total_skipped / Add?.total_questions) * 100);
  const type = Etype || review_id?.type; // Use `Etype` if available, otherwise use `review_id?.type`

  useEffect(() => {
    const student_test_id =
      review_id?.type === "Writing"
        ? review_id?.id
        : id
        ? Number(id)
        : review_id;
    const type = Etype || review_id?.type; // Use `Etype` if available, otherwise use `review_id?.type`

    // if (student_test_id) {
    if (type === "Writing") {
      dispatch(
        listEvaluationPracticewritingExam({
          student_test_id,
        })
      );
    } else {
      dispatch(
        listEvaluationPractice({
          student_test_id,
        })
      );
    }
    // }
  }, [id, Etype, review_id, dispatch]);

  const timetaken = Add?.total_timing;
  let hours = 0,
    minutes = 0,
    seconds = 0; // Default values

  if (timetaken) {
    // Split the time string into hours, minutes, and seconds
    [hours, minutes, seconds] = timetaken
      .split(":")
      .map((part: any, index: number) =>
        index < 2 ? parseInt(part) : parseFloat(part)
      );
  }

  function formatTime(totalTime: any) {
    // Split the input string into components
    const [hours, minutes, seconds] = totalTime?.split(":").map(Number);

    // Extract seconds and round to nearest whole number
    const formattedSeconds = Math.round(seconds);

    // Format the output string
    return `${String(hours).padStart(
      2,
      "0"
    )} Hr ${minutes} M ${formattedSeconds} S`;
  }

  // Mock feedback and score

  const updatedList: any = EvaluationPracticewritingExam?.data?.map(
    (item: any) => {
      const totalScore = item?.total_score ?? 0; // Default to 0 if undefined
      const scored = item?.score ?? 0; // Default to 0 if undefined
      const remaining = totalScore - scored; // Calculate remaining score

      return {
        ...item,
        data: [
          { name: "Achieved Score", value: scored, fill: "green" },
          { name: "Pending Score", value: remaining, fill: "#81aecb" }, // Add remaining field
        ],
      };
    }
  );

  return (
    <>
      {EvaluationSlice && <Loading isLoading={EvaluationSlice} />}
      {PracticewritingExam && <Loading isLoading={PracticewritingExam} />}

      <AppBarnav />
      {type === "Writing" ? (
        <>
          <div className="examdivpagewriting">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div className="writing">Writing Report</div>
              <div className="secondwriting">
                <ButtonField
                  className="messagebuttons"
                  disabled={updatedList?.length < 1}
                  onClick={() => {
                    navigate("/reviewexampractice", { state: review_id });
                  }}
                >
                  View Soultion
                </ButtonField>
                <ButtonField
                  className="messagebuttons"
                  onClick={() => {
                    navigate("/home"); // Replace with your target route
                  }}
                >
                  Back to Home
                </ButtonField>
              </div>
            </div>

            <div className="responsive-table-container">
              <div className="responsive-table">
                <div className="table-row header">
                  <div className="table-cell" style={{ fontSize: "15px" }}>
                    Section
                  </div>
                  <div className="tableand" style={{ fontSize: "15px" }}>
                    Feedback
                  </div>
                </div>
                {updatedList?.length > 1 ? (
                  updatedList?.map((section: any, index: number) => (
                    <div className="table-row" key={index}>
                      <div className="table-cell section-cell">
                        <h3
                          style={{
                            textTransform: "capitalize",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                        >
                          {section?.title}
                        </h3>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            height: "150px",
                          }}
                        >
                          <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                              <Pie
                                dataKey="value"
                                data={section?.data}
                                cx="50%"
                                cy="50%"
                                innerRadius={30}
                                outerRadius={60}
                                fill={section.color}
                              >
                                <Label
                                  value={
                                    section?.total_score
                                      ? `Total :${section.total_score.toString()}`
                                      : "Total  : 0"
                                  }
                                  position="center"
                                  fontSize={18}
                                  fill="black"
                                />
                              </Pie>
                              <Tooltip />
                            </PieChart>
                          </ResponsiveContainer>
                        </div>

                        <div
                          className="exampargarph"
                          style={{ textAlign: "center" }}
                        >
                          You have scored
                          <span
                            style={{
                              color: "green",
                              margin: "10px",
                              fontSize: "15px",
                            }}
                          >
                            {section?.score}
                          </span>
                          out of
                          <span
                            style={{
                              color: "blue",
                              margin: "10px",
                              fontSize: "15px",
                            }}
                          >
                            {section?.total_score}
                          </span>
                        </div>
                        {section?.total_time_spent && (
                          <div
                            style={{
                              display: "flex",
                              margin: "10px",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                            title="Total Time Taken"
                          >
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                fontSize: "16px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <AccessAlarmIcon /> Total Time Taken :{" "}
                              {formatTime(section?.total_time_spent)}{" "}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="table-cell feedback-cell">
                        <p>{section?.feedback}</p>
                      </div>
                      <Divider />
                    </div>
                  ))
                ) : (
                  <div className="tabledatafount">Loading .....</div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="examdivpage">
          <div className="headingexam">Exam Result</div>
          <div className="examResultdiv">
            {/* data list  */}
            <div className="leftdiv">
              <div className="yourscore">Your Score</div>
              <div className="reslutvalue">{Add?.total_correct}</div>
              <div className="resultcount">Out of {Add?.total_questions}</div>

              <div className="listexam">
                <div>
                  <div className="listexamword">Accuracy</div>
                  <div className="listexamword">Percentage</div>
                </div>
                <div>
                  <div className="listexamword">{percentage}%</div>
                  <div className="listexamword">{percentage}%</div>
                </div>
              </div>

              <ButtonField
                className="messagebutton"
                onClick={() => {
                  navigate("/reviewexampractice", { state: review_id });
                }}
              >
                View Solution
              </ButtonField>
              <ButtonField
                className="messagebutton"
                onClick={() => {
                  navigate("/feedbackpractice", { state: review_id }); // Replace with your target route
                }}
              >
                Proceed to feedback
              </ButtonField>
              <ButtonField
                className="messagebutton"
                sx={{ zIndex: 1111 }}
                onClick={() => {
                  navigate("/home"); // Replace with your target route
                }}
              >
                Back to Home
              </ButtonField>
            </div>

            {/* chart div  */}
            <div className="rightdiv">
              <div className="headingtotal">
                Total Marks : {Add?.total_questions}
              </div>
              <div className="listexam">
                <div style={{ width: "33.3%", height: 200 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        dataKey="value"
                        data={datalistone}
                        cx="50%" // Position in percentage
                        cy="50%" // Position in percentage
                        innerRadius={40}
                        outerRadius={80}
                        fill="#82ca9d"
                      >
                        <Label
                          value={datalistone[0]?.value || 0} // Your label text
                          position="center"
                          style={{ fontSize: "20px", fontWeight: "600" }}
                          fontSize={23} // Customize the font size
                          fill="#88B61E" // Customize the color
                        />
                      </Pie>

                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="correctvalue">Correct</div>
                </div>
                <div style={{ width: "33.3%", height: 200 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        dataKey="value"
                        data={datalisttwo}
                        cx="50%" // Position in percentage
                        cy="50%" // Position in percentage
                        innerRadius={40}
                        outerRadius={80}
                        fill="#82ca9d"
                      >
                        <Label
                          value={datalisttwo[0]?.value || 0} // Your label text
                          position="center"
                          fontSize={23} // Customize the font size
                          fill="red" // Customize the color
                        />
                      </Pie>

                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="correctvalue">Incorrect</div>
                </div>
                <div style={{ width: "33.3%", height: 200 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        dataKey="value"
                        data={datalistthird}
                        cx="50%" // Position in percentage
                        cy="50%" // Position in percentage
                        innerRadius={40}
                        outerRadius={80}
                        fill="#82ca9d"
                      >
                        <Label
                          value={datalistthird[0]?.value || 0} // Your label text
                          position="center"
                          fontSize={23} // Customize the font size
                          fill="#82ca9d" // Customize the color
                        />
                      </Pie>

                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="correctvalue">Skipped</div>
                </div>
              </div>
              <div className="exampargarph">
                You have scored
                <span
                  style={{ color: "green", margin: "10px", fontSize: "17px" }}
                >
                  {Add?.total_correct}
                </span>
                marks for correct answers, missed
                <span
                  style={{ color: "red", margin: "10px", fontSize: "17px" }}
                >
                  {Add?.total_wrong}
                </span>
                marks on incorrect answers,
                <span
                  style={{ color: "blue", margin: "10px", fontSize: "17px" }}
                >
                  {Add?.total_skipped}
                </span>
                marks by skipping questions.
              </div>
            </div>
          </div>
          {/* 
      <div className="examresultpage">Section Analysis</div>

      <Box className="exmaresult_cards_container">
        {Add?.length === 0 ? (
          // Render this when the Package array is empty
          <Card className="dashboard_card">
            <div className="dashboard_cards">
              <Typography className="Examresult_name" variant="h6">
                Section Not Available
              </Typography>
            </div>
          </Card>
        ) : (
          // Render this when the Package array has data
          Add?.question_type_details?.map((pkg: any, index: number) => (
            <Card className="Examlist_card" key={index}>
              <div className="Examresult_cards">
                <div className="Examresult_name">{pkg?.question_type_name}</div>
                <div className="examresultcorrect">{pkg?.total_correct}</div>
                <div className="examcountout">Out of {pkg?.total_count} </div>
              </div>
              <Divider />
              <div className="listexam">
                <div>
                  <div className="listexamword">Time taken :</div>
                  <div className="listexamword">Accuracy :</div>
                  <div className="listexamword">Percentage :</div>
                </div>
                <div>
                  <div className="listexamwordtime">
                    {formatTime(pkg?.total_time_spent)}
                  </div>
                  <div className="listexamword">
                    {Math.round((pkg?.total_correct / pkg?.total_count) * 100)}%
                  </div>
                  <div className="listexamword">
                    {Math.round((pkg?.total_correct / pkg?.total_count) * 100)}%
                  </div>
                </div>
              </div>
            </Card>
          ))
        )}
      </Box> */}
          <div className="scorelist">
            <div className="examresultpage">Question Report</div>
            <div className="examlistdiv">
              <div style={{ width: "16.6%" }}>
                <div className="answervalue">{Add?.total_questions}</div>
                <div className="questionvalue">Questions</div>
              </div>
              <div style={{ width: "16.6%" }}>
                <div className="answervalue" style={{ color: "green" }}>
                  {Add?.total_correct}
                </div>
                <div className="questionvalue">Correct</div>
              </div>
              <div style={{ width: "16.6%" }}>
                <div className="answervalue" style={{ color: "red" }}>
                  {" "}
                  {Add?.total_wrong}
                </div>
                <div className="questionvalue">Incorrect</div>
              </div>
              <div style={{ width: "16.6%" }}>
                <div className="answervalue">{Add?.total_skipped}</div>
                <div className="questionvalue">Skipped</div>
              </div>
              <div style={{ width: "16.6%" }}>
                <div className="answervalue">{Add?.total_correct}</div>
                <div className="questionvalue">Score</div>
              </div>
              <div style={{ width: "16.6%" }}>
                <div className="answervalue">{`${hours ? hours : "00"} Hr ${
                  minutes ? minutes : "00"
                } M ${Math.floor(seconds)} S`}</div>
                <div className="questionvalue">Time Taken</div>
              </div>
            </div>
          </div>
          <div className="headingexam">How did you perform</div>
          <div className="examResultdiv">
            {/* data list  */}
            <div className="leftdiv">
              <div className="timesepent">Time Analysis</div>

              <div style={{ width: "100%", height: 200 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={datalistresult}
                      fill="#82ca9d"
                    ></Pie>

                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="answervalue">{`${hours ? hours : "00"} Hr ${
                minutes ? minutes : "00"
              } M ${Math.floor(seconds)} S`}</div>
              <div className="resultcount">Total Time Spent</div>

              <div className="listexam">
                <div>
                  <div className="listexamword">On Correct Answer</div>
                  <div className="listexamword">On Incorrect Answer</div>
                  <div className="listexamword">On Skipped</div>
                </div>
                <div>
                  <div className="listexamword" style={{ color: "#4CAF50" }}>
                    {percentage} %
                  </div>
                  <div className="listexamword" style={{ color: "red" }}>
                    {Incorrect} %
                  </div>
                  <div className="listexamword">{Skipped} %</div>
                </div>
              </div>
            </div>

            {/* chart div  */}
            <div className="rightdiv">
              <div className="performchart">
                <div className="timesepent">Total Time Spent</div>

                <div className="headingtotal">
                  <span style={{ fontSize: "14px" }}>Total Marks</span> :{" "}
                  {Add?.total_questions}
                </div>
              </div>
              <div className="performchart">
                <div className="timesepent">Attempts</div>

                <div className="headingtotal">
                  <span style={{ fontSize: "14px", color: "#4CAF50" }}>
                    Correct
                  </span>{" "}
                  : {Add?.total_correct}{" "}
                  <span style={{ fontSize: "14px", color: "#F44336" }}>
                    InCorrect
                  </span>{" "}
                  : {Add?.total_wrong}{" "}
                  <span style={{ fontSize: "14px", color: "#FF9800" }}>
                    Skipped
                  </span>{" "}
                  : {Add?.total_skipped}
                </div>
              </div>

              <div className="listexam">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    layout="horizontal" // Ensures a horizontal layout
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="category" dataKey="name" />{" "}
                    {/* X-axis shows names */}
                    <YAxis type="number" domain={[0, total_questions]} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar
                      dataKey="value"
                      isAnimationActive={false}
                      label={{ position: "top", fill: "#333", fontSize: 12 }}
                      barSize={40} // Adjust bar size
                    >
                      {data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.fill} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="exampargarph">
                You have scored
                <span
                  style={{ color: "green", margin: "10px", fontSize: "17px" }}
                >
                  {Add?.total_correct}
                </span>
                marks for correct answers, missed
                <span
                  style={{ color: "red", margin: "10px", fontSize: "17px" }}
                >
                  {Add?.total_wrong}
                </span>
                marks on incorrect answers,
                <span
                  style={{ color: "blue", margin: "10px", fontSize: "17px" }}
                >
                  {Add?.total_skipped}
                </span>
                marks by skipping questions.
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExamResultPractice;

import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSage from "../Saga/Saga";
import TestReducer from "../Slices/TestSlicers/TestSlicers";
import SessionExpiredReducer from "../Slices/SessionExpired/SessionExpired";
import EditUserPermissionsReducer from "../Slices/UserPermission/UpdateUserPermissionSlicer";
import UserPermissionRowsReducer from "../Slices/UserPermission/UserPermissionRowsSlicer";
import LoginReducer from "../Slices/LoginSlicer/LoginSlicer";
import GoogleLoginReducer from "../Slices/LoginSlicer/GoogleLoginSlicer"
import RegistrationSlicerReducer from "../Slices/Registration/RegistrationSlicer"
import ResetPasswordReducer from "../Slices/ResetPassword/ResetPassword";
import SideMenuPermissionReducer from "../Slices/SideMenuPermission/ListSideMenuPermissionSlice";
import PermissionsToPageReducer from "../Slices/UserPermission/PermissionsToPage";
import ForgotPasswordReducer from "../Slices/LoginSlicer/ForgotPassword";
import DashboardCardReducer from "../Slices/Dashboard/DashboardCard";
import DashboardChartReducer from "../Slices/DashboardChart/DashboardChart"
import DashboardDataCardReducer from "../Slices/DashboardData/DashboardDataCard"
import ThemeReducer from "../Slices/Theme/ThemeSlice";
 // Menu
import ListMenuSliceReducer from "../Slices/Menu/ListMenuSlice"
import AddMenuSlice from "../Slices/Menu/AddMenuSlice"
import EditMenuSliceReducer from "../Slices/Menu/EditMenuSlice"
import DeleteMenuReducer from "../Slices/Menu/DeleteMenuSlice"

 // Questions
 import ListQuestionsSliceReducer from "../Slices/Questions/ListQuestionsSlice"
 import AddQuestionsSlice from "../Slices/Questions/AddQuestionsSlice"
 import EditQuestionsSliceReducer from "../Slices/Questions/EditQuestionsSlice"
 import DeleteQuestionsReducer from "../Slices/Questions/DeleteQuestionsSlice"
 
  // ExamHistory
  import ListMockExamHistorySliceReducer from "../Slices/ExamHistory/ListMockExamHistorySlice"
  import ListExamHistorySliceReducer from "../Slices/ExamHistory/ListExamHistorySlice"
  import ListExamHistoryReWritingSliceReducer from "../Slices/ExamHistory/ListExamHistoryReWritingSlice"
  import AddExamHistorySlice from "../Slices/ExamHistory/AddExamHistorySlice"
  import EditExamHistorySliceReducer from "../Slices/ExamHistory/EditExamHistorySlice"
  import DeleteExamHistoryReducer from "../Slices/ExamHistory/DeleteExamHistorySlice"
  
 // Options
 import ListOptionsSliceReducer from "../Slices/Options/ListOptionsSlice"
 import AddOptionsSlice from "../Slices/Options/AddOptionsSlice"
 import EditOptionsSliceReducer from "../Slices/Options/EditOptionsSlice"
 import DeleteOptionsReducer from "../Slices/Options/DeleteOptionsSlice"
 
  // Exam
  import ListReViewExamIDSliceReducer from "../Slices/ReViewExam/ListReViewExamSlice"
  import ListReExamIDSliceReducer from "../Slices/ReExam/ListReExamSlice"
  import PendingExamSliceReducer from "../Slices/PendingExam/PendingExamSlice"
  import ListExamsubmitSliceReducer from "../Slices/ExamAnswer/ListExamsubmitSlice"
  import ListExamIDSliceReducer from "../Slices/Exam/ListExamIDSlice"
  import ListEvaluationSliceReducer from "../Slices/Exam/ListEvaluationSlice"
  import ListEvaluationPracticewritingExamSliceReducer from "../Slices/PracticeExam/ListEvaluationPracticewritingExamSlice"
  import ListExamSliceReducer from "../Slices/Exam/ListExamSlice"
  import AddExamSlice from "../Slices/Exam/AddExamSlice"
  import AddCloseSectionSliceReducer from "../Slices/Exam/AddCloseSectionSlice"
  import EditExamSliceReducer from "../Slices/Exam/EditExamSlice"
  import DeleteExamReducer from "../Slices/Exam/DeleteExamSlice"
  import AddOpenSectionSliceReducer from "../Slices/OpenSection/AddOpenSectionSlice"

  // Practice List 
  import ListPracticeTestSliceReducer from "../Slices/PracticeTest/ListPracticeTestSlice"
    // Practice Exam 
    import ListEvaluationPracticeSliceReducer from "../Slices/PracticeExam/ListEvaluationPracticeSlice"
    import ListExamsubmitPracticeSliceReducer from "../Slices/ExamAnswerPractice/ListExamsubmitPracticeSlice"
    import ListPracticeExamIDSliceReducer from "../Slices/PracticeExam/ListPracticeExamIDSlice"
   import ListInstructionPracticeExamSliceReducer from "../Slices/PracticeExam/ListinstructionPracticeExamIDSlice"
   import ListPracticeExamSliceReducer from "../Slices/PracticeExam/ListPracticeExamSlice"
   import PendingPracticeExamSliceReducer from "../Slices/PendingPracticeExam/PendingPracticeExamSlice"
    import AddPracticeExamSlice from "../Slices/PracticeExam/AddPracticeExamSlice"
    import EditPracticeExamSliceReducer from "../Slices/PracticeExam/EditPracticeExamSlice"
    import DeletePracticeExamReducer from "../Slices/PracticeExam/DeletePracticeExamSlice"
    
     // StudentPractice Exam 
  import PopupStudentExamPracticeSliceReducer from "../Slices/StudentExamPractice/PopupStudentExamPracticeSlice"
  import EditStudentExamPracticeSliceReducer from "../Slices/StudentExamPractice/EditStudentExamPracticeSlice"
  import ListStudentExamPracticeSliceReducer from "../Slices/StudentExamPractice/ListStudentExamPracticeSlice"
  import AddStudentExamPracticeSlice from "../Slices/StudentExamPractice/AddStudentExamPracticeSlice"

  // Student Exam 
  import PopupStudentExamSliceReducer from "../Slices/StudentExam/PopupStudentExamSlice"
  import EditStudentExamSliceReducer from "../Slices/StudentExam/EditStudentExamSlice"
  import ListStudentExamSliceReducer from "../Slices/StudentExam/ListStudentExamSlice"
  import AddStudentExamSlice from "../Slices/StudentExam/AddStudentExamSlice"
   // Instruction
   import ListInstructionSliceReducer from "../Slices/Instruction/ListInstructionSlice"
   import AddInstructionSlice from "../Slices/Instruction/AddInstructionSlice"
   import EditInstructionSliceReducer from "../Slices/Instruction/EditInstructionSlice"
   import DeleteInstructionReducer from "../Slices/Instruction/DeleteInstructionSlice"
   
     // ExamAnswer Practice
     import ListExamAnswerPracticeSliceReducer from "../Slices/ExamAnswerPractice/ListExamAnswerPracticeSlice"
     import AddExamAnswerPracticeSlice from "../Slices/ExamAnswerPractice/AddExamAnswerPracticeSlice"
  
      // ExamAnswer
      import ListExamAnswerSliceReducer from "../Slices/ExamAnswer/ListExamAnswerSlice"
      import AddExamAnswerSlice from "../Slices/ExamAnswer/AddExamAnswerSlice"
      import EditExamAnswerSliceReducer from "../Slices/ExamAnswer/EditExamAnswerSlice"
      import DeleteExamAnswerReducer from "../Slices/ExamAnswer/DeleteExamAnswerSlice"
   
       // QuestionType
 import ListQuestionTypeSliceReducer from "../Slices/QuestionType/ListQuestionTypeSlice"
 import AddQuestionTypeSlice from "../Slices/QuestionType/AddQuestionTypeSlice"
 import EditQuestionTypeSliceReducer from "../Slices/QuestionType/EditQuestionTypeSlice"
 import DeleteQuestionTypeReducer from "../Slices/QuestionType/DeleteQuestionTypeSlice"
  // ExamModel
  import ListExamModelSliceReducer from "../Slices/ExamModel/ListExamModelSlice"
  import AddExamModelSlice from "../Slices/ExamModel/AddExamModelSlice"
  import EditExamModelSliceReducer from "../Slices/ExamModel/EditExamModelSlice"
  import DeleteExamModelReducer from "../Slices/ExamModel/DeleteExamModelSlice"
  
       // FeedBack
       import ListFeedBackSliceReducer from "../Slices/FeedBack/ListFeedBackSlice"
       import AddFeedBackSlice from "../Slices/FeedBack/AddFeedBackSlice"
       import EditFeedBackSliceReducer from "../Slices/FeedBack/EditFeedBackSlice"
       import DeleteFeedBackReducer from "../Slices/FeedBack/DeleteFeedBackSlice"
    import AddFeedBackPracticeSliceReducer from "../Slices/FeedBackPractice/AddFeedBackPracticeSlice"
  // UserProfile
  import ListUserProfileSliceReducer from "../Slices/UserProfile/ListUserProfileSlice"
  import AddUserProfileSlice from "../Slices/UserProfile/AddUserProfileSlice"
  import EditUserProfileSliceReducer from "../Slices/UserProfile/EditUserProfileSlice"
  import DeleteUserProfileReducer from "../Slices/UserProfile/DeleteUserProfileSlice"

   // User Admin
   import ListUserAdminSliceReducer from "../Slices/UserAdmin/ListUserAdminSlice"
   import AddUserAdminSliceReducer from "../Slices/UserAdmin/AddUserAdminSlice"
   import EditUserAdminSliceReducer from "../Slices/UserAdmin/EditUserAdminSlice"
   import DeleteUserAdminReducer from "../Slices/UserAdmin/DeleteUserAdminSlice"
  

  // User
  import ListUserSliceReducer from "../Slices/User/ListUserSlice"
  import AddUserSlice from "../Slices/User/AddUserSlice"
  import EditUserSliceReducer from "../Slices/User/EditUserSlice"
  import DeleteUserReducer from "../Slices/User/DeleteUserSlice"
  import ListRegionSliceReducer from "../Slices/User/ListRegionSlice"
  import ListYearGroupSliceReducer from "../Slices/User/ListYearGroupSlice"
    // Package
    import ListPackageSliceReducer from "../Slices/Package/ListPackageSlice"
    import AddPackageSlice from "../Slices/Package/AddPackageSlice"
    import EditPackageSliceReducer from "../Slices/Package/EditPackageSlice"
    import DeletePackageReducer from "../Slices/Package/DeletePackageSlice"
  
       // Courses
       import ListCoursesSliceReducer from "../Slices/Courses/ListCoursesSlice"
       import AddCoursesSlice from "../Slices/Courses/AddCoursesSlice"
       import EditCoursesSliceReducer from "../Slices/Courses/EditCoursesSlice"
       import DeleteCoursesReducer from "../Slices/Courses/DeleteCoursesSlice"
     
       // userPackage
   import PendingExamListReducer from "../Slices/UserPackage/PendingExamListSlice"
   import ListUserPackageSliceReducer from "../Slices/UserPackage/ListUserPackageSlice"
   import ListUserPackageDetailsSliceReducer from "../Slices/UserPackage/ListUserPackageDetailsSlice"
   import ListUserExamListSliceReducer from "../Slices/UserPackage/ListUserExamListSlice"
     // Role
     import ListRoleSliceReducer from "../Slices/Role/ListRoleSlice"
     import AddRoleSlice from "../Slices/Role/AddRoleSlice"
     import EditRoleSliceReducer from "../Slices/Role/EditRoleSlice"
     import DeleteRoleReducer from "../Slices/Role/DeleteRoleSlice"
  //  mock exam 
  import MockExamSliceReducer from "../Slices/MockExam/ListMockExamSlice"
import ListInstructionExamSliceReducer from "../Slices/Exam/ListinstructionExamIDSlice"
//  Reading prompt
import ReadingPromptSliceReducer from "../Slices/ReadingPrompt/ListReadingPromptSlice"

// Writing Exam main
import ListWritingExamAISliceReducer from "../Slices/Writing_Exam/ListWriting_Exam_AI_Slice"
// Writing Exam 
import ListWritingExamAIPracticeSliceReducer from "../Slices/Writing_Exam_Practice/ListWriting_Exam_AI_PracticeSlice"
import  ListWritingExamPracticeSliceReducer from "../Slices/Writing_Exam_Practice/ListWriting_Exam_PracticeSlice"
//  chatbot
import ChatbotSlicerReducer from "../Slices/Chatbot/ChatbotSlicer"
 import ListReViewExamIDPracticeSliceReducer from '../Slices/ReViewExamPractice/ListReViewExamPracticeSlice'    
  
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    Test: TestReducer,
    Theme: ThemeReducer,
    // Dashboard
    DashboardCard: DashboardCardReducer,
        // DashboardChart
        DashboardChart: DashboardChartReducer,
      // DashboardData
      DashboardDataCard: DashboardDataCardReducer,
    // Permissions
    PermissionsToPage: PermissionsToPageReducer,
    // SessionExpired
    SessionExpired: SessionExpiredReducer,
    // User Permission
    EditUserPermission: EditUserPermissionsReducer,
    UserPermissionRows: UserPermissionRowsReducer,
    // Login
    LoginSlicer: LoginReducer,
     // google
     GoogleLoginSlicer: GoogleLoginReducer,
   // Registration
   RegistrationSlicer:RegistrationSlicerReducer,
    // Reset Password
    ResetPassword: ResetPasswordReducer,
    ForgotPassword: ForgotPasswordReducer,

    // Menu
    ListMenuSlice: ListMenuSliceReducer,
    AddMenuSlice: AddMenuSlice,
    EditMenuSlice:EditMenuSliceReducer,
    DeleteMenu: DeleteMenuReducer,
       // QuestionType
ListQuestionTypeSlice: ListQuestionTypeSliceReducer,
AddQuestionTypeSlice: AddQuestionTypeSlice,
EditQuestionTypeSlice:EditQuestionTypeSliceReducer,
DeleteQuestionType: DeleteQuestionTypeReducer,
  // Exam
  ListExamsubmitSlice: ListExamsubmitSliceReducer,
  ListReViewExamIDSlice:ListReViewExamIDSliceReducer,
  ListReExamIDSlice:ListReExamIDSliceReducer,
  PendingExamSlice:PendingExamSliceReducer,
  ListExamIDSlice: ListExamIDSliceReducer,
  ListInstructionExamSlice: ListInstructionExamSliceReducer,
  ListEvaluationSlice: ListEvaluationSliceReducer,
  ListEvaluationPracticewritingExamSlice: ListEvaluationPracticewritingExamSliceReducer,
  ListExamSlice: ListExamSliceReducer,
  AddExamSlice: AddExamSlice,
  AddCloseSectionSlice: AddCloseSectionSliceReducer,
  EditExamSlice:EditExamSliceReducer,
  DeleteExam: DeleteExamReducer,
  AddOpenSectionSlice: AddOpenSectionSliceReducer,

  // Practice test list
  ListPracticeTestSlice: ListPracticeTestSliceReducer,

   // Practice Exam
   ListReViewExamIDPracticeSlice:ListReViewExamIDPracticeSliceReducer,
   ListEvaluationPracticeSlice: ListEvaluationPracticeSliceReducer,
   ListExamsubmitPracticeSlice: ListExamsubmitPracticeSliceReducer,
   ListPracticeExamSlice: ListPracticeExamSliceReducer,
   PendingPracticeExamSlice:PendingPracticeExamSliceReducer,
   AddPracticeExamSlice: AddPracticeExamSlice,
   EditPracticeExamSlice:EditPracticeExamSliceReducer,
   DeletePracticeExam: DeletePracticeExamReducer,
   ListInstructionPracticeExamSlice:ListInstructionPracticeExamSliceReducer,
   ListPracticeExamIDSlice:ListPracticeExamIDSliceReducer,
  // chatbot
  ChatbotSlicer:ChatbotSlicerReducer,
  // student exam 
  ListStudentExamSlice: ListStudentExamSliceReducer,
  AddStudentExamSlice: AddStudentExamSlice,
  EditStudentExamSlice:EditStudentExamSliceReducer,
  PopupStudentExamSlice:PopupStudentExamSliceReducer,

  // studentPractice exam 
  ListStudentExamPracticeSlice: ListStudentExamPracticeSliceReducer,
  AddStudentExamPracticeSlice: AddStudentExamPracticeSlice,
  EditStudentExamPracticeSlice:EditStudentExamPracticeSliceReducer,
  PopupStudentExamPracticeSlice:PopupStudentExamPracticeSliceReducer,

   // ExamModel
   ListExamModelSlice: ListExamModelSliceReducer,
   AddExamModelSlice: AddExamModelSlice,
   EditExamModelSlice:EditExamModelSliceReducer,
   DeleteExamModel: DeleteExamModelReducer,

       // ExamHistory
       ListExamHistoryReWritingSlice: ListExamHistoryReWritingSliceReducer,
       ListMockExamHistorySlice: ListMockExamHistorySliceReducer,

       ListExamHistorySlice: ListExamHistorySliceReducer,
       AddExamHistorySlice: AddExamHistorySlice,
       EditExamHistorySlice:EditExamHistorySliceReducer,
       DeleteExamHistory: DeleteExamHistoryReducer,
              // Questions
ListQuestionsSlice: ListQuestionsSliceReducer,
AddQuestionsSlice: AddQuestionsSlice,
EditQuestionsSlice:EditQuestionsSliceReducer,
DeleteQuestions: DeleteQuestionsReducer,


    // Options
ListOptionsSlice: ListOptionsSliceReducer,
AddOptionsSlice: AddOptionsSlice,
EditOptionsSlice:EditOptionsSliceReducer,
DeleteOptions: DeleteOptionsReducer,
    // Instruction
    ListInstructionSlice: ListInstructionSliceReducer,
    AddInstructionSlice: AddInstructionSlice,
    EditInstructionSlice:EditInstructionSliceReducer,
    DeleteInstruction: DeleteInstructionReducer,
     // ExamAnswer
   ListExamAnswerSlice: ListExamAnswerSliceReducer,
   AddExamAnswerSlice: AddExamAnswerSlice,
   EditExamAnswerSlice:EditExamAnswerSliceReducer,
   DeleteExamAnswer: DeleteExamAnswerReducer,
     // ExamAnswerPractice
     ListExamAnswerPracticeSlice: ListExamAnswerPracticeSliceReducer,
     AddExamAnswerPracticeSlice: AddExamAnswerPracticeSlice,
    
             // FeedBack
             ListFeedBackSlice: ListFeedBackSliceReducer,
             AddFeedBackSlice: AddFeedBackSlice,
             EditFeedBackSlice:EditFeedBackSliceReducer,
             DeleteFeedBack: DeleteFeedBackReducer,
             
              // FeedBack
              AddFeedBackPracticeSlice: AddFeedBackPracticeSliceReducer,
            
      // Package
      ListPackageSlice: ListPackageSliceReducer,
      AddPackageSlice: AddPackageSlice,
      EditPackageSlice:EditPackageSliceReducer,
      DeletePackage: DeletePackageReducer,
      
      // Courses
      ListCoursesSlice: ListCoursesSliceReducer,
      AddCoursesSlice: AddCoursesSlice,
      EditCoursesSlice:EditCoursesSliceReducer,
      DeleteCourses: DeleteCoursesReducer,
      
          // UserAdmin
          ListUserAdminSlice: ListUserAdminSliceReducer,
          AddUserAdminSlice: AddUserAdminSliceReducer,
          EditUserAdminSlice:EditUserAdminSliceReducer,
          DeleteUserAdmin: DeleteUserAdminReducer,
       // User
       ListUserSlice: ListUserSliceReducer,
       AddUserSlice: AddUserSlice,
       EditUserSlice:EditUserSliceReducer,
       DeleteUser: DeleteUserReducer,
      //  list user
      ListRegionSlice:ListRegionSliceReducer,
      ListYearGroupSlice:ListYearGroupSliceReducer,

      //user package 
      ListUserPackageSlice: ListUserPackageSliceReducer,
      ListUserPackageDetailsSlice: ListUserPackageDetailsSliceReducer,
      ListUserExamListSlice: ListUserExamListSliceReducer,
      PendingExamList: PendingExamListReducer,
        // mock exam 
        MockExamSlice: MockExamSliceReducer ,

        // Reading Prompt
        ReadingPromptSlice: ReadingPromptSliceReducer ,

        ListWritingExamAISlice:ListWritingExamAISliceReducer,

        // writing exam 
        ListWritingExamAIPracticeSlice:ListWritingExamAIPracticeSliceReducer,
        ListWritingExamPracticeSlice:ListWritingExamPracticeSliceReducer,
          // Role
          ListRoleSlice: ListRoleSliceReducer,
          AddRoleSlice: AddRoleSlice,
          EditRoleSlice:EditRoleSliceReducer,
          DeleteRole: DeleteRoleReducer,
          
     // UserProfile
     ListUserProfileSlice: ListUserProfileSliceReducer,
     AddUserProfileSlice: AddUserProfileSlice,
     EditUserProfileSlice:EditUserProfileSliceReducer,
     DeleteUserProfile: DeleteUserProfileReducer,
     
    // SideMenu Permission
    SideMenuPermission: SideMenuPermissionReducer,

     },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSage);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStudentPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";

export const AddStudentExamPracticeSlice = createSlice({
  name: "AddStudentExamPracticeSlice",
  initialState: {} as IReduxState<IStudentPracticeExam>,
  reducers: {
    addStudentExamPractice: (state, _: PayloadAction<IStudentPracticeExam>) => {
      state.isLoading = true;
    },
    successaddStudentExamPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddStudentExamPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddStudentExamPracticeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addStudentExamPractice,
  failureaddStudentExamPractice,
  successaddStudentExamPractice,
  clearAddStudentExamPracticeResponse,
} = AddStudentExamPracticeSlice.actions;

export default AddStudentExamPracticeSlice.reducer;

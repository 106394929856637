import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IDashboardChart } from "../../../Pages/Dashboard/IDashboard";

export const DashboardChartSlice = createSlice({
  name: "DashboardChart",
  initialState: {} as IReduxState<IDashboardChart>,
  reducers: {
    dashboardChart: (state) => {
      state.isLoading = true;
    },
    successDashboardChart: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureDashboardChart: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDashboardChart: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureDashboardChart,
  successDashboardChart,
  clearDashboardChart,
  dashboardChart,
} = DashboardChartSlice.actions;

export default DashboardChartSlice.reducer;

import * as React from "react";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from "recharts";
import "./UserProfile.scss";
import ButtonField from "../../Components/Button/ButtonField";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { listEvaluation } from "../../Redux/Slices/Exam/ListEvaluationSlice";
import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addRole } from "../../Redux/Slices/Role/AddRoleSlice";
import { EditRole } from "../../Redux/Slices/Role/EditRoleSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { IUserProfile } from "./IUserProfile";
import { IUser } from "../User/IUser";
import { addUser } from "../../Redux/Slices/User/AddUserSlice";
import Snackbar from "../../Components/Snackbar/Snackbar";
import { listRegion } from "../../Redux/Slices/User/ListRegionSlice";
import { listYearGroup } from "../../Redux/Slices/User/ListYearGroupSlice";
import { listUser } from "../../Redux/Slices/User/ListUserSlice";
import { EditUser } from "../../Redux/Slices/User/EditUserSlice";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import { EditStudentExamPractice } from "../../Redux/Slices/StudentExamPractice/EditStudentExamPracticeSlice";
import { EditStudentExam } from "../../Redux/Slices/StudentExam/EditStudentExamSlice";

const UserProfile: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const AddUserSliceLoading = useAppSelector((state) => state.AddUserSlice);
  const EditUserSliceLoading = useAppSelector((state) => state.EditUserSlice);

  const PendingTime = localStorage.getItem("pending_time");
  const Response = localStorage.getItem("response");
  const question_id = Number(localStorage.getItem("question_id"));
  const Question_Type = Number(localStorage.getItem("question_type"));
  const StudentExamId = Number(localStorage.getItem("student_exam_id"));
  useEffect(() => {
    dispatch(listRegion());
    dispatch(listYearGroup());
    dispatch(listUser());
    dispatch(
      EditStudentExamPractice({
        pending_time: PendingTime,
        student_test_id: StudentExamId,
        question_type_id: Question_Type,
        question_id: question_id,
        response: Response,
      })
    );
    dispatch(
      EditStudentExam({
        pending_time: PendingTime,
        student_exam_id: StudentExamId,
        question_type_id: Question_Type,
        question_id: question_id,
        sentence: Response,
      })
    );
    localStorage.removeItem("pending_time");
    localStorage.removeItem("response");
    localStorage.removeItem("question_id");
    localStorage.removeItem("question_type");
    localStorage.removeItem("student_exam_id");
  }, [dispatch]);

  const { data: ListUserSliceLoading } = useAppSelector(
    (state) => state.ListUserSlice
  );

  const ListUser: any = Array.isArray(ListUserSliceLoading?.data)
    ? ListUserSliceLoading?.data.map((user, index) => ({
        ...user,
        index: index + 1,
      }))
    : [];
  const ListYearSliceLoading = useAppSelector(
    (state) => state.ListYearGroupSlice
  );
  const ListYear = ListYearSliceLoading?.data;

  const YearList = Array.isArray(ListYear?.data)
    ? ListYear?.data?.map((listExams) => ({
        label: listExams?.year_group!,
        value: listExams?.year_group,
      }))
    : [];

  const ListRegionSliceLoading = useAppSelector(
    (state) => state.ListRegionSlice
  );
  const ListRegion = ListRegionSliceLoading?.data;

  const RegionList = Array.isArray(ListRegion?.data)
    ? ListRegion?.data?.map((listExams) => ({
        label: listExams?.state!,
        value: listExams?.state,
      }))
    : [];

  const userSchema = z.object({
    id: z.number().optional(),
    student_name: z
      .string({ message: "Please enter name" })
      .min(1, { message: "Please enter name" }),
    parent_name: z
      .string({ message: "Please enter parent name" })
      .min(1, { message: "Please enter parent name" }),
    email: z
      .string({
        required_error: "Please enter a valid email ID",
        message: "Please enter a valid email ID",
      })
      .email({
        message: "Please enter a valid email ID",
      }),
    phone_no: z
      .number({ message: "Please enter phone number" })
      .nonnegative({ message: "Phone number must be exactly 10 digits" }),
    // .refine(
    //   (value) =>
    //     value.toString().length <= 10 && value.toString().length >= 10,
    //   {
    //     message: "Phone number must contain only 10 digits",
    //   }
    // ),
    hear_about_us: z
      .string({ message: "Please enter hear about us" })
      .min(1, { message: "Please enter hear about us" }),

    state: z.string({ message: "Please select state" }),
    year_group: z.string({ message: "Please select year group" }),
    status: z.boolean({
      message: "Please select the status",
    }),
  });
  const methods = useForm<IUser>({
    // defaultValues: initialValues,
    resolver: zodResolver(userSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<IUser> = (val) => {
    if (val.id) {
      dispatch(EditUser({ ...val, phone_no: String(val.phone_no) }));
    } else {
      dispatch(addUser({ ...val, phone_no: String(val.phone_no) }));
    }
  };

  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, [dispatch]);

  useEffect(() => {
    methods.reset({ ...ListUser[0] });
  }, [ListUserSliceLoading]);

  // Status list
  const RoleSatus = [
    {
      label: "Active",
      value: true,
    },

    {
      label: "Inactive",
      value: false,
    },
  ];
  const formRef = React.useRef<HTMLFormElement>(null);

  return (
    <>
      <Snackbar response={AddUserSliceLoading?.data} />
      <Snackbar response={EditUserSliceLoading.data} />
      <div className="boxofUserProfileuser">
        <div className="concentofexamuser">
          <div className="UserProfileheadinguser">Registration For Exam</div>
          <FormProvider {...methods}>
            <form
              ref={formRef}
              onSubmit={methods.handleSubmit(onSubmit)}
              className="form_contents"
            >
              <TextInputFieldC
                name="student_name"
                label="Student Name"
                type="text"
              />
              <TextInputFieldC
                name="parent_name"
                label="Parent Name"
                type="text"
              />
              <TextInputFieldC name="email" label="Email" type="text" />
              <NumberInputField
                name="phone_no"
                label="Phone Number"
                type="number"
                disabledScrollBar
              />
              <TextInputFieldC
                name="hear_about_us"
                label="Hear About Us"
                type="text"
              />
              <Select
                name="state"
                label="State"
                options={RegionList ?? []}
                value={
                  RegionList?.find(
                    (list) => list.value === methods.getValues("state")
                  ) ?? null
                }
                onChange={(_, value) => {
                  const val = value as AutocompleteOption;
                  methods.setValue("state", val?.value, {
                    shouldValidate: true,
                  });
                }}
              />

              <Select
                name="year_group"
                label="Year Group"
                options={YearList ?? []}
                value={
                  YearList?.find(
                    (list) => list.value === methods.getValues("year_group")
                  ) ?? null
                }
                onChange={(_, value) => {
                  const val = value as AutocompleteOption;
                  methods.setValue("year_group", val?.value, {
                    shouldValidate: true,
                  });
                }}
              />

              <Select
                name="status"
                label="Status"
                options={RoleSatus}
                value={
                  RoleSatus?.find(
                    (role) => role.value === methods.getValues("status")
                  ) ?? null
                }
                onChange={(_, value) => {
                  const val = value as AutocompleteOption;
                  methods.setValue("status", val?.value, {
                    shouldValidate: true,
                  });
                }}
              />
            </form>
            <ButtonField
              className="save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Register Now
            </ButtonField>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
export default memo(UserProfile);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IWritingExamAI } from "../../../Pages/DashboardUser/IDashboardUser";

export const ListWritingExamAISlice = createSlice({
  name: "ListWritingExamAISlice",
  initialState: {} as IReduxState<IWritingExamAI[]>,

  reducers: {
    listListWritingExamAISlice: (state, _: PayloadAction<IWritingExamAI>) => {
      state.isLoading = true;
    },
    successListWritingExamAISlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureListWritingExamAISlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  listListWritingExamAISlice,
  successListWritingExamAISlice,
  failureListWritingExamAISlice,
} = ListWritingExamAISlice.actions;

export default ListWritingExamAISlice.reducer;

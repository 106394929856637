"use client";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatars from "../Avatar/Avatars";
import "./AppBar.scss";
import { ReactComponent as Logo } from "../../Assets/Images/loginlogo.svg";
import { SvgIcon } from "@mui/material";

export default function AppBarnavTamplete() {
  return (
    <>
      <Box sx={{ display: "flex", height: "1px" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#FFFFFF",
            display: "flex",
            boxShadow: "0px 4px 40px 0px #0000000D",
          }}
        >
          <Toolbar className="appbarname">
            <Typography variant="h6" noWrap component="div">
              <SvgIcon
                sx={{
                  width: "123px",
                  height: "50px",
                  marginTop: "10px",
                  cursor: "default",
                }}
                color="primary"
                component={Logo}
                inheritViewBox
              />
            </Typography>

            <Avatars sx={{ height: "2rem", width: "2rem" }} />
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

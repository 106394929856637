import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamlistsubmitPractice } from "../../../Pages/Exam/IExam";

export const ListExamsubmitPracticeSlice = createSlice({
  name: "ListExamsubmitPracticeSlice",
  initialState: {} as IReduxState<IExamlistsubmitPractice[]>,
  reducers: {
    listExamsubmitPractice: (
      state,
      _: PayloadAction<IExamlistsubmitPractice>
    ) => {
      state.isLoading = true;
    },
    successlistExamsubmitPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExamsubmitPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearListExamsubmitPractice: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistExamsubmitPractice,
  listExamsubmitPractice,
  successlistExamsubmitPractice,
  clearListExamsubmitPractice,
} = ListExamsubmitPracticeSlice.actions;

export default ListExamsubmitPracticeSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamlistID } from "../../../Pages/Exam/IExam";
import { IPracticeExamlistID } from "../../../Pages/PracticeExam/IPracticeExam";

export const ListInstructionPracticeExamSlice = createSlice({
  name: "ListInstructionPracticeExamSlice",
  initialState: {} as IReduxState<IPracticeExamlistID[]>,
  reducers: {
    listInstructionPracticeExamID: (
      state,
      _: PayloadAction<IPracticeExamlistID>
    ) => {
      state.isLoading = true;
    },
    successlistInstructionPracticeExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistInstructionPracticeExamID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearlistInstructionPracticeExamID: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistInstructionPracticeExamID,
  listInstructionPracticeExamID,
  successlistInstructionPracticeExamID,
  clearlistInstructionPracticeExamID,
} = ListInstructionPracticeExamSlice.actions;

export default ListInstructionPracticeExamSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IFeedBackPractice } from "../../../Pages/FeedBackPractice/IFeedBackPractice";

export const AddFeedBackPracticeSlice = createSlice({
  name: "AddFeedBackPracticeSlice",
  initialState: {} as IReduxState<IFeedBackPractice>,
  reducers: {
    addFeedBackPractice: (state, _: PayloadAction<IFeedBackPractice>) => {
      state.isLoading = true;
    },
    successaddFeedBackPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddFeedBackPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddFeedBackPracticeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addFeedBackPractice,
  failureaddFeedBackPractice,
  successaddFeedBackPractice,
  clearAddFeedBackPracticeResponse,
} = AddFeedBackPracticeSlice.actions;

export default AddFeedBackPracticeSlice.reducer;

import { FunctionComponent, useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star"; // Import a star icon from MUI
import "./SubscribedPages.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import { listReExamID } from "../../Redux/Slices/ReExam/ListReExamSlice";
import { Button, Card, Typography } from "@mui/material";
import { listUserExamList } from "../../Redux/Slices/UserPackage/ListUserExamListSlice";
import { listUserPackageDetails } from "../../Redux/Slices/UserPackage/ListUserPackageDetailsSlice";
import AlarmIcon from "@mui/icons-material/Alarm";

interface SubscribedPagesProps {}

const SubscribedPages: FunctionComponent<SubscribedPagesProps> = () => {
  // Create an array of instruction objects
  const navigate = useNavigate();
  const { state: exam_id } = useLocation();
  const dispatch = useAppDispatch();
  const { data: listUserPackageDetailsSlice } = useAppSelector(
    (state) => state.ListUserPackageDetailsSlice
  );
  const Packagedetails: any = listUserPackageDetailsSlice?.data;
  useEffect(() => {
    dispatch(listUserPackageDetails({ package_id: exam_id }));
  }, []);

  return (
    <div className="instruction-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className="courseslistsubscribed">
          {Packagedetails?.package?.package_name}
        </div>{" "}
        <Button
          className="buttonnamenextresportwrite"
          sx={{ zIndex: 1111 }}
          onClick={() => {
            navigate("/home"); // Replace with your target route
          }}
        >
          Back to Home
        </Button>
      </div>

      <div className="descipationsubscribed">
        {Packagedetails?.package?.description}
      </div>
      <div className="courseslistsubscribed">Courses List </div>
      <div className="subscribedpagedev">
        <div className="Subscribedpageone">
          {Packagedetails?.courses?.length === 0 ? (
            <div style={{ marginBottom: "20px" }}>
              <div className="customCardpendingsubscribed">
                <Card className="dashboard_cardspending">
                  <Typography className="heading_name" variant="h6">
                    Courses Not Available
                  </Typography>
                </Card>
              </div>
            </div>
          ) : (
            Packagedetails?.courses?.map((card: any, index: number) => (
              <div style={{ marginBottom: "20px" }} key={index}>
                <div className="customCardpendingsubscribed">
                  <div className="textContentsubscribed">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        className="textsytleheading"
                        style={{ color: "#009DFF" }}
                      >
                        {`${index + 1}) ${card?.course_name}`}
                      </Typography>
                    </div>

                    <Typography variant="body2" className="textsytle">
                      {card?.course_description}
                    </Typography>
                    <Typography
                      variant="h5"
                      className="sytleheading"
                      style={{ color: "#009DFF" }}
                    >
                      Exam Details
                    </Typography>
                    <Typography variant="h5" className="examheading">
                      {card?.exams?.map((examlist: any, examIndex: number) => (
                        <div className="examlistcard" key={examIndex}>{`${
                          examIndex + 1
                        }) ${examlist?.name}`}</div>
                      ))}
                    </Typography>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="Subscribedpagetwo">
          <Card className="dashboard_card">
            <div className="dashboard_cards">
              <div className="heading_name">
                {Packagedetails?.package?.package_name}
              </div>
              <div className="concentnamesub">
                <div>
                  <AlarmIcon fontSize="small" />
                </div>
                <div>{Packagedetails?.package?.duration} validity</div>
              </div>
              <div className="costoflistsub">
                A$ {Packagedetails?.package?.cost}
              </div>

              <button className="card-buttonlistsub">
                {Packagedetails?.subscribed === true ? "Enrolled" : "Enroll"}
              </button>
              <div className="examcount"> What's included</div>
              <div className="examcount">
                Total Courses {Packagedetails?.course_count}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SubscribedPages;

import { AxiosResponse } from "axios";
import { call, delay, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failurelistPracticeTest,
  successlistPracticeTest,
} from "./ListPracticeTestSlice";

// list
const ListPracticeTestApi = () => {
  return api.get("/v1/api/exam/test");
};

export function* ListPracticeTestCall() {
  try {
    const response: AxiosResponse = yield call(ListPracticeTestApi);
    yield put(successlistPracticeTest(response.data));
  } catch (error) {
    yield put(failurelistPracticeTest(error));
  }
}

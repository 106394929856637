import { FunctionComponent } from "react";
import "./Instructionpagetamplete.scss";
import { useLocation, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Button } from "@mui/material";
import AppBarnav from "../../Components/AppBar/AppBar";
import AppBarnavTamplete from "../../Components/AppBarTamplete/AppBarTamplete";

interface InstructionPageTampleteProps {}

const InstructionPageTamplete: FunctionComponent<
  InstructionPageTampleteProps
> = () => {
  // Create an array of instruction objects
  const navigate = useNavigate();
  const { state: examlistdata } = useLocation();

  return (
    <>
      <AppBarnavTamplete />
      <div className="instruction-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div className="instructionheading">{examlistdata?.name}</div>
        </div>

        <div className="instructiondescription">
          {examlistdata?.description}
        </div>

        <div className="instruction-list-container">
          <div style={{ flex: 1 }}>
            <div className="instructionheadingbold">
              Please read instructions carefully
            </div>
            <div className="instruction-liststo">
              {examlistdata?.instrution_data?.map((item: any, index: any) => (
                <div key={index} className="instructionitem">
                  <FiberManualRecordIcon
                    sx={{ fontSize: "10px", color: "black", marginTop: "3px" }}
                  />

                  <div style={{ fontSize: "15px" }}>{item?.instruction}</div>
                </div>
              ))}
            </div>
          </div>

          <div style={{ flex: 0.3 }}>
            <div className="instructionheadingbold">Exam Includes</div>

            <div className="sections-type">
              {examlistdata?.question_types?.map((type: any, index: number) => (
                <div key={index} className="section-items">
                  <div className="centermultiple">{type?.question_type}</div>
                  <div>
                    Question type :
                    {type?.question_type === "Writing" ? "Written" : "MCQ"}
                  </div>

                  <div>
                    {" "}
                    Question count :{" "}
                    {type.question_count === null
                      ? "-"
                      : type.question_count}{" "}
                  </div>
                  <div>
                    {" "}
                    Duration :{type.duration === null
                      ? "-"
                      : type.duration}{" "}
                    minutes
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Button
            className="buttonnamenextresportwriteexam"
            sx={{ zIndex: 1111 }}
            onClick={() => {
              navigate("/masters/exam"); // Replace with your target route
            }}
          >
            Back to Exam page
          </Button>
        </div>
      </div>
    </>
  );
};

export default InstructionPageTamplete;

import "./Dashboard.scss";
import { FunctionComponent, useEffect } from "react";
import React, { PureComponent } from "react";
import { Box, Card, darken, LinearProgress, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import SchoolIcon from "@mui/icons-material/School"; // example icon, replace as needed
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  BarChart,
  Cell,
  PieChart,
  Pie,
} from "recharts";
import { dashboardCard } from "../../Redux/Slices/Dashboard/DashboardCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";
import EditNoteIcon from "@mui/icons-material/EditNote";
const Dashboard: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { data: DashboardCards } = useAppSelector(
    (state) => state.DashboardCard
  );

  const Dashboarduser: any = DashboardCards?.data;
  console.log(Dashboarduser?.questions_by_type);

  // user permisssion
  const { data: userPermissionlist } = useAppSelector(
    (state) => state.SideMenuPermission
  );
  const to = "dashboard";
  const filteredList = userPermissionlist?.Dashboard?.filter(
    (item: any) => item.to === to
  );

  useEffect(() => {
    dispatch(dashboardCard());
  }, [dispatch]);

  const data = [
    {
      name: "Total Students",
      value: Dashboarduser?.total_users,
      fill: "#5e6aa5",
    }, // Green for correct
    {
      name: "Total Exam Attempted",
      value: Dashboarduser?.total_exams_attempted,
      fill: "#7e968c",
    }, // Red for incorrect
  ];
  const total_questions: any = Dashboarduser?.total_exams_available; // Example for Y-axis max value
  const COLORSs = ["#a6d197", "#e97568", "#E0E0E0"];

  return (
    <>
      <div className="overexam">Dashboard</div>

      <div className="dashboardr-containerr">
        <div className="cardr-cont">
          <div className="cardrandlist">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                height: "60px",
                marginBottom: "10px",

                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <h2 style={{ fontSize: "20px" }}>Total Number of Students : </h2>
              <p style={{ fontSize: "20px", marginLeft: "10px" }}>
                {Dashboarduser?.total_users}{" "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                height: "60px",
                marginBottom: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <h2 style={{ fontSize: "20px" }}>
                Total Number of Exams Available :{" "}
              </h2>
              <p style={{ fontSize: "20px", marginLeft: "10px" }}>
                {Dashboarduser?.total_exams_available}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                height: "60px",
                marginBottom: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <h2 style={{ fontSize: "20px" }}>
                Total Number of Attempted Exams :
              </h2>
              <p style={{ fontSize: "20px", marginLeft: "10px" }}>
                {Dashboarduser?.total_exams_attempted}
              </p>
            </div>
          </div>
          <div className="cardrandlistss">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                height: "60px",
                marginBottom: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <h2 style={{ fontSize: "20px" }}>
                Total Questions in the Question Bank :
              </h2>
              <p style={{ fontSize: "20px", marginLeft: "10px" }}>
                {Dashboarduser?.total_questions}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                height: "60px",
                marginBottom: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <h1 style={{ fontSize: "20px" }}>Active Question Type Count :</h1>
              <p style={{ fontSize: "20px" }}>
                {Dashboarduser?.total_active_question}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                height: "60px",
                marginBottom: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <h2 style={{ fontSize: "20px" }}>Total Inactive Questions :</h2>
              <p style={{ fontSize: "20px", marginLeft: "10px" }}>
                {Dashboarduser?.total_inactive_questions}
              </p>
            </div>
          </div>
          <div className="cardrandlisteded">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                height: "60px",
                marginBottom: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <h2 style={{ fontSize: "20px" }}>Total Exams Created :</h2>
              <p style={{ fontSize: "20px", marginLeft: "10px" }}>
                {Dashboarduser?.total_exams_available}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px",
                height: "60px",
                marginBottom: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <h2 style={{ fontSize: "20px" }}>
                Total Practice Tests Available :
              </h2>
              <p style={{ fontSize: "20px", marginLeft: "10px" }}>
                {Dashboarduser?.total_practice_test}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="overexam">Questions Type</div>

      <div className="score-container">
        {Dashboarduser?.questions_by_type?.map((item: any, index: number) => {
          const score = parseFloat(item?.active_count); // Convert "10%" -> 10
          const scorelist = parseFloat(item?.inactive_count); // Convert "10%" -> 10
          const Totallist = parseFloat(item?.total_count); // Convert "10%" -> 10

          const chartData = [
            { name: "Active Count", value: score },
            { name: "Inactive Count", value: scorelist },
            { name: "Total Count", value: score + scorelist - Totallist },
          ];

          return (
            <div key={index} className="score-boxss">
              {/* Left Side - Card */}
              <h2 style={{ fontSize: "15px", textAlign: "center" }}>
                {" "}
                {item.name}
              </h2>
              <div className="score-boxsslist">
                <div className="score-card">
                  <p style={{ fontSize: "15px", textAlign: "left" }}>
                    Active Count : {item.active_count}
                  </p>
                  <p style={{ fontSize: "15px", textAlign: "left" }}>
                    Inactive Count : {item.inactive_count}
                  </p>
                  <p style={{ fontSize: "15px", textAlign: "left" }}>
                    Total Count : {item.total_count}
                  </p>
                </div>

                {/* Right Side - Pie Chart */}
                <div className="score-chart">
                  <PieChart width={150} height={200}>
                    <Pie
                      data={chartData}
                      cx="50%"
                      cy="50%"
                      // innerRadius={50}
                      // outerRadius={80}
                      dataKey="value"
                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORSs[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="overexam">Student Exam Usage Analysis</div>
      <ResponsiveContainer width="70%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          layout="horizontal" // Ensures a horizontal layout
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="category" dataKey="name" /> {/* X-axis shows names */}
          <YAxis type="number" domain={[0, total_questions]} />{" "}
          {/* Y-axis shows values */}
          <Tooltip />
          {/* <Legend /> */}
          <Bar
            dataKey="value"
            isAnimationActive={true}
            label={{ position: "top", fill: "#333", fontSize: 12 }}
            barSize={40} // Adjust bar size
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default Dashboard;

import { FunctionComponent, useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star"; // Import a star icon from MUI
import "./InstructionPendingPractice.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listExamID } from "../../Redux/Slices/Exam/ListExamIDSlice";
import { addStudentExam } from "../../Redux/Slices/StudentExam/AddStudentExamSlice";
import { decode } from "../../Utils/encodeDecode";
import { ILoginResponse } from "../Login/ILogin";
import { pendingExam } from "../../Redux/Slices/PendingExam/PendingExamSlice";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { pendingPracticeExam } from "../../Redux/Slices/PendingPracticeExam/PendingPracticeExamSlice";
import { EditStudentExamPractice } from "../../Redux/Slices/StudentExamPractice/EditStudentExamPracticeSlice";
import { Button } from "@mui/material";

interface InstructionPendingPracticeProps {}

const InstructionPendingPractice: FunctionComponent<
  InstructionPendingPracticeProps
> = () => {
  // Create an array of instruction objects
  const navigate = useNavigate();
  const { state: exam_id } = useLocation();

  const { data: listexamlist, isLoading: listexamlistLoading } = useAppSelector(
    (state) => state.PendingPracticeExamSlice
  );
  const InstructionPendingPracticelist: any = listexamlist?.data;
  const dispatch = useAppDispatch();

  const finalInstructionPendingPractice =
    InstructionPendingPracticelist?.test_instruction;

  const [details, setDetails] = useState<ILoginResponse>({});

  useEffect(() => {
    dispatch(pendingPracticeExam({ id: exam_id }));
  }, []);

  useEffect(() => {
    const userDetails = decode(localStorage.getItem("details"));
    setDetails(userDetails);
  }, []);
  const handleButtonClick = () => {
    // dispatch(
    //   addStudentExam({
    //     exam_id: InstructionPendingPracticelist?.exam_id,
    //     student_id: details?.id,
    //     closed: false,
    //     questions: InstructionPendingPracticelist?.questions,
    //   })
    // );
    localStorage.setItem("student_id", details?.id);
    navigate("/pendingexampractice", {
      state: { examID: exam_id, Practice: "test" },
    }); // Replace with your target route
  };

  const PendingTime = localStorage.getItem("pending_time");
  const Response = localStorage.getItem("response");
  const question_id = Number(localStorage.getItem("question_id"));
  const Question_Type = Number(localStorage.getItem("question_type"));
  const StudentExamId = Number(localStorage.getItem("student_exam_id"));
  useEffect(() => {
    dispatch(
      EditStudentExamPractice({
        pending_time: PendingTime,
        student_test_id: StudentExamId,
        question_type_id: Question_Type,
        question_id: question_id,
        response: Response,
      })
    );
    localStorage.removeItem("pending_time");
    localStorage.removeItem("response");
    localStorage.removeItem("question_id");
    localStorage.removeItem("question_type");
    localStorage.removeItem("student_exam_id");
  }, [dispatch]);
  return (
    <div className="instruction-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className="instructionheading">
          {InstructionPendingPracticelist?.test_name}
        </div>
      </div>

      <div className="instructiondescription">
        {InstructionPendingPracticelist?.test_description}
      </div>

      <div className="instruction-list-container">
        <div style={{ flex: 1 }}>
          <div className="instructionheadingbold">
            Please read instructions carefully
          </div>
          <div className="instruction-liststo">
            {finalInstructionPendingPractice?.map((item: any, index: any) => (
              <div key={index} className="instructionitem">
                <FiberManualRecordIcon
                  sx={{ fontSize: "10px", color: "black", marginTop: "3px" }}
                />

                <div style={{ fontSize: "15px" }}>{item?.instruction}</div>
              </div>
            ))}
          </div>
        </div>

        {!!!listexamlistLoading && (
          <div style={{ flex: 0.3 }}>
            <div className="instructionheadingbold">Test Includes</div>

            <div className="sections-type">
              <div className="section-items">
                <div className="centermultiple">
                  {
                    InstructionPendingPracticelist?.question_by_type[0]
                      ?.question_type_name
                  }
                </div>
                <div>
                  Question type :{" "}
                  {InstructionPendingPracticelist?.question_type_name ===
                  "Writing"
                    ? "Written"
                    : "MCQ"}
                </div>
                <div>
                  {" "}
                  Question count :{" "}
                  {InstructionPendingPracticelist?.question_by_type[0]
                    ?.question_count === null
                    ? "-"
                    : InstructionPendingPracticelist?.question_by_type[0]
                        ?.question_count}{" "}
                </div>
                <div>
                  {" "}
                  Duration :{" "}
                  {InstructionPendingPracticelist?.question_by_type[0]
                    ?.total_timing === null
                    ? "-"
                    : InstructionPendingPracticelist?.question_by_type[0]
                        ?.total_timing}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <button
          disabled={listexamlist?.status === false}
          className="proceed-button"
          style={{
            backgroundColor:
              listexamlist?.status === false ? "gray" : "#009DFF",
            cursor: listexamlist?.status === false ? "not-allowed" : "pointer",
          }}
          onClick={handleButtonClick}
        >
          Resume Test
        </button>
        <Button
          className="buttonnamenextresportwriteexam"
          sx={{ zIndex: 1111 }}
          onClick={() => {
            navigate("/home"); // Replace with your target route
          }}
        >
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default InstructionPendingPractice;

import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IDashboardCards } from "../../../Pages/Dashboard/IDashboard";

export const DashboardDataCardSlice = createSlice({
  name: "DashboardDataCard",
  initialState: {} as IReduxState<IDashboardCards>,
  reducers: {
    dashboardDataCard: (state) => {
      state.isLoading = true;
    },
    successDashboardDataCard: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureDashboardDataCard: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDashboardDataCard: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureDashboardDataCard,
  successDashboardDataCard,
  clearDashboardDataCard,
  dashboardDataCard,
} = DashboardDataCardSlice.actions;

export default DashboardDataCardSlice.reducer;

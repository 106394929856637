import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IQuestions } from "../../../Pages/Questions/IQuestions";

export const ListQuestionsSlice = createSlice({
  name: "ListQuestionsSlice",
  initialState: {} as IReduxState<IQuestions>,
  reducers: {
    listQuestions: (state, _: PayloadAction<IQuestions>) => {
      state.isLoading = true;
    },
    successlistQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failurelistQuestions, listQuestions, successlistQuestions } =
  ListQuestionsSlice.actions;

export default ListQuestionsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamHistory } from "../../../Pages/Exam_Histroy/IExam_History";

export const ListExamHistoryReWritingSlice = createSlice({
  name: "ListExamHistoryReWritingSlice",
  initialState: {} as IReduxState<IExamHistory[]>,
  reducers: {
    listExamHistoryReWritingSlice: (state) => {
      state.isLoading = true;
    },
    successlistExamHistoryReWritingSlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExamHistoryReWritingSlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistExamHistoryReWritingSlice,
  listExamHistoryReWritingSlice,
  successlistExamHistoryReWritingSlice,
} = ListExamHistoryReWritingSlice.actions;

export default ListExamHistoryReWritingSlice.reducer;

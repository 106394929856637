import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStudentPracticeExam } from "../../../Pages/PracticeExam/IPracticeExam";

export const ListStudentExamPracticeSlice = createSlice({
  name: "ListStudentExamPracticeSlice",
  initialState: {} as IReduxState<IStudentPracticeExam[]>,
  reducers: {
    listStudentExamPractice: (state) => {
      state.isLoading = true;
    },
    successlistStudentExamPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistStudentExamPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearlistStudentExamPractice: (state) => {
      state.data = {};
    },
  },
});

export const {
  failurelistStudentExamPractice,
  listStudentExamPractice,
  successlistStudentExamPractice,
  clearlistStudentExamPractice,
} = ListStudentExamPracticeSlice.actions;

export default ListStudentExamPracticeSlice.reducer;

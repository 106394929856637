import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IEvaluationPractice } from "../../../Pages/Exam/IExam";

export const ListEvaluationPracticeSlice = createSlice({
  name: "ListEvaluationPracticeSlice",
  initialState: {} as IReduxState<IEvaluationPractice[]>,
  reducers: {
    listEvaluationPractice: (state, _: PayloadAction<IEvaluationPractice>) => {
      state.isLoading = true;
    },
    successlistEvaluationPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistEvaluationPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistEvaluationPractice,
  listEvaluationPractice,
  successlistEvaluationPractice,
} = ListEvaluationPracticeSlice.actions;

export default ListEvaluationPracticeSlice.reducer;

import React from "react";
import {
  Button,
  Pagination,
  Typography,
  Grid,
  SvgIcon,
  Box,
  Card,
} from "@mui/material";
import "./OnlineExampageTamplete.scss"; // For additional styling
import { ReactComponent as Deleteicon } from "../../../Assets/Images/delete.svg";
// dialog message
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill CSS for styling
import InfoIcon from "@mui/icons-material/Info";
import AppBarnavTamplete from "../../../Components/AppBarTamplete/AppBarTamplete";
import { useLocation, useNavigate } from "react-router-dom";

interface Question {
  id: number;
  question_type: string;
  prompt: string;
  instruction: string;
  question: string;
  question_url?: string;
  question_type_id?: number;
  options: { optionname: string; option_id: number }[];
  optionsurl: { optionname: string; option_id: number }[];
}

// Add font size options dynamically
const FontSize = ReactQuill.Quill.import("formats/size");
FontSize.whitelist = ["small", false, "large", "huge"];
ReactQuill.Quill.register(FontSize, true);

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: ["small", false, "large", "huge"] }], // Added font size options
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    // ["link", "image", "video"],
    // ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
];

const OnlineExamTamplete: React.FC = () => {
  const navigate = useNavigate();
  const { state: examtamplete } = useLocation();
  console.log(examtamplete, "list");

  const renderQuestionOptions = (
    question_type: string,
    options: { optionname: string; option_id: number }[],
    optionsurl: {
      optionname: string;
      option_id: number;
    }[] // Assuming you want to access option_url
  ) => {
    const hasNullUrl = optionsurl.some((option) => option.optionname === null);

    return (
      <Grid
        container
        spacing={
          ["Reading Comprehension"].includes(examtamplete?.Question_type_name)
            ? 3
            : 1
        }
        sx={{
          ...(["Reading Comprehension", "Writing"].includes(
            examtamplete?.Question_type_name
          )
            ? {
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }
            : {
                backgroundColor: "white",
                marginBottom: "40px",
                position: "fixed",
                bottom: "0",
                padding: "0% 8% 4% 8%",
              }),
        }}
      >
        {["Writing"].includes(examtamplete?.Question_type_name) ? (
          <>
            <ReactQuill
              className="custom-quill"
              style={{
                width: "100%",
                padding: "20px 0px 20px 8px",
                height: "50vh",
              }}
              theme="snow"
              modules={modules}
              formats={formats}
              tabIndex={0} // Ensure it's focusable without enabling spellcheck
              placeholder="Write something here..."
              preserveWhitespace
              data-gramm="false"
              onFocus={() => {
                // Ensure the underlying contenteditable disables spellcheck and autocorrect
                const editorElement = document.querySelector(".ql-editor");
                if (editorElement) {
                  editorElement.setAttribute("spellcheck", "false");
                  editorElement.setAttribute("autocorrect", "off");
                  editorElement.setAttribute("autocapitalize", "off");
                  editorElement.setAttribute("data-gramm", "false"); // Disable Grammarly
                }
              }}
            />
          </>
        ) : hasNullUrl ? (
          options.map((option, index) => (
            <Grid item xs={6} key={index} className="gridwidth">
              <div className="Optionnameflex">
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    fontWeight: "400",
                    textTransform: "capitalize",
                    color: "blue",
                  }}
                >
                  {`${String.fromCharCode(97 + index)})`}
                </span>
                <Box
                  sx={{
                    border: "1px solid #CDCDCD",
                    borderRadius: "6px",
                    padding:
                      question_type === "Reading Comprehension"
                        ? "10px"
                        : "3px",
                    cursor: "pointer",
                    textAlign: "left",
                    fontSize: "13px",
                    width: "100%",
                    color: "black",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                    display: "flex", // Makes the content centered
                    alignItems: "center", // Vertically centers content
                    height: "100%", // Fills the available height
                  }}
                >
                  {option.optionname}
                </Box>
              </div>
            </Grid>
          ))
        ) : (
          optionsurl.map((option, index) => (
            <Grid item xs={6} key={index}>
              <Box
                sx={{
                  border: "1px solid #CDCDCD",
                  borderRadius: "6px",
                  padding: "3px",
                  cursor: "pointer",
                  textAlign: "justify",

                  color: "black",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },

                  display: "flex", // Makes the content centered
                  alignItems: "center", // Vertically centers content
                  justifyContent: "center", // Horizontally centers content
                  minHeight:
                    question_type === "Reading Comprehension" ? "100px" : "0px", // Ensures uniform height for all boxes
                  height: "100%", // Fills the available height
                }}
              >
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      fontWeight: "400",
                      textTransform: "capitalize",
                    }}
                  >
                    {String.fromCharCode(97 + index)}
                  </span>
                  <img
                    src={option.optionname}
                    alt={`Uploaded ${index}`}
                    style={{
                      maxWidth: "100%",
                      height: "50px", // Adjusts height proportionally
                    }}
                  />
                </div>
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    );
  };

  const options = examtamplete?.options?.map((opt: any) => ({
    optionname: opt?.option,
    option_id: opt?.id,
  }));
  const optionsurl = examtamplete?.options?.map((opt: any) => ({
    optionname: opt?.option_url === null ? null : opt?.option_url,
    option_id: opt?.id,
  }));

  return (
    <>
      <AppBarnavTamplete />
      <div className="unselectablereviewexam">
        <div className="onlineexamdiv">
          <div className="Questiontype">
            <div className="examtext">{examtamplete?.Question_type_name} </div>{" "}
            &nbsp; : &nbsp;
            <div className="Questiontypeheading">
              {examtamplete?.Question_type_name}
            </div>
            <div className="onlineexmcount">Q. 1 of {""}1 </div>
          </div>
          <div className="Questiontype">
            <div className="onlineexamtime">00:00</div>
          </div>
        </div>

        <div className="onlineexamdivs">
          <Pagination
            count={1} // Total number of questions
            variant="outlined"
            siblingCount={6} // Display 5 sibling pages
            boundaryCount={1} // Show 3 boundary pages
            page={1} // Current active page
            renderItem={(item: any) => {
              if (item.type === "previous" || item.type === "next") return null;

              return (
                <Button
                  key={item.page}
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    color: "white",
                    backgroundColor: "blue",
                    minWidth: "unset",
                    padding: 0,
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}
                >
                  {item.page}
                </Button>
              );
            }}
          />
        </div>
        {["Reading Comprehension", "Writing"].includes(
          examtamplete?.Question_type_name
        ) ? (
          <div className="overonlinediv">
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                width: "50%",
                borderRight: "1px solid #cecece",
              }}
            >
              <div className="examscrollreading">
                <Typography variant="h5" sx={{ paddingRight: "10px" }}>
                  {examtamplete?.Question_type_name === "Writing" ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          textAlign: "justify",
                          fontWeight: 600,
                        }}
                      >
                        Use this idea as the basis for a piece of writing. You
                        may write in any style.
                      </Typography>
                      {examtamplete?.question_url && (
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "left",
                            position: "relative", // Ensure proper positioning of the image
                            overflow: "visible", // Ensure the enlarged image is not clipped
                            "& img": {
                              maxWidth: "100%",
                              height:
                                examtamplete?.Question_type_name === "Writing"
                                  ? "50rem"
                                  : "100%",
                              cursor: "pointer",
                              transition: "transform 0.3s ease",
                              position: "relative",
                              transformOrigin: "left center", // Keep the hover scaling aligned to the left
                            },
                            "& img:hover": {
                              transform: "scale(1.5)",
                            },
                          }}
                        >
                          <img
                            src={examtamplete?.question_url}
                            alt="Uploaded preview"
                          />
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "justify",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {examtamplete?.prompt}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "justify",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {examtamplete?.instruction}
                      </Typography>
                    </>
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{ width: "50%", padding: "10px" }}>
              <div className="examscrollreading">
                <Typography variant="h5">
                  {examtamplete?.Question_type_name ===
                    "Reading Comprehension" && (
                    <>
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          textAlign: "justify",
                          fontWeight: 600,
                        }}
                      >
                        {examtamplete?.question}
                      </Typography>

                      {examtamplete?.question_url && (
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "left",
                            position: "relative", // Ensure proper positioning of the image
                            overflow: "visible", // Ensure the enlarged image is not clipped
                            "& img": {
                              maxWidth: "100%",
                              height:
                                examtamplete?.Question_type_name === "Writing"
                                  ? "30rem"
                                  : "100%",
                              cursor: "pointer",
                              transition: "transform 0.3s ease",
                              position: "relative",
                              transformOrigin: "left center", // Keep the hover scaling aligned to the left
                            },
                            "& img:hover": {
                              transform: "scale(1.5)",
                            },
                          }}
                        >
                          <img
                            src={examtamplete?.question_url}
                            alt="Uploaded preview"
                          />
                        </Typography>
                      )}
                    </>
                  )}

                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: "20px",
                      textAlign: "justify",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {renderQuestionOptions(
                      examtamplete?.Question_type_name,
                      options || [],
                      optionsurl || []
                    )}
                    {examtamplete?.Question_type_name === "Writing" && (
                      <div className="vaidationforwriting">
                        <InfoIcon />
                        <div>
                          {" "}
                          Please write a minimum of 120 words to submit the exam
                        </div>
                      </div>
                    )}
                    {examtamplete?.Question_type_name === "Writing" && (
                      <Box
                        sx={{ maxWidth: 600, mx: "auto", marginTop: "20px" }}
                      >
                        {/* Responsive Cards */}
                        <Grid container spacing={2}>
                          {[
                            { label: "Words", value: 0 },
                            { label: "Characters", value: 0 },
                            { label: "Sentences", value: 0 },
                            { label: "Paragraphs", value: 0 },
                          ]?.map((item, index) => (
                            <Grid item xs={6} sm={3} key={index}>
                              <Card
                                sx={{
                                  textAlign: "center",
                                  p: 2,
                                  borderRadius: "10px",
                                  boxShadow: 2,
                                  background: "#f8f9fa",
                                }}
                              >
                                <Typography variant="h5" fontWeight="bold">
                                  {item.value}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {item.label}
                                </Typography>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                  </Typography>
                </Typography>
              </div>
            </Grid>
          </div>
        ) : (
          <>
            <div className="overonlinediv">
              <Grid item xs={12} md={6} sx={{ width: "100%" }}>
                <div className="examscroll">
                  <Typography
                    variant="h5"
                    // className="examscroll"
                    // sx={{
                    //   height: "550px", // Fixed height
                    //   overflow: "auto", // Enable scrolling
                    //   overflowY: "scroll",
                    // }}
                  >
                    {examtamplete?.Question_type_name === "Writing" ? (
                      <Typography
                        sx={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          textAlign: "justify",
                          fontWeight: 600,
                        }}
                      >
                        Use this idea as the basis for a piece of writing. You
                        may write in any style.
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "justify",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {examtamplete.prompt}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            marginBottom: "20px",
                            textAlign: "justify",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {examtamplete?.instruction}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1.3rem",
                            marginBottom: "20px",
                            textAlign: "justify",
                            fontWeight: 600,
                          }}
                        >
                          {examtamplete?.question}
                        </Typography>
                      </>
                    )}

                    {examtamplete?.question_url && (
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: "20px",
                          textAlign: "left",
                          position: "relative", // Ensure proper positioning of the image
                          overflow: "visible", // Ensure the enlarged image is not clipped
                          "& img": {
                            maxWidth: "100%",
                            height:
                              examtamplete?.Question_type_name === "Writing"
                                ? "30rem"
                                : "100%",
                            cursor: "pointer",
                            transition: "transform 0.3s ease",
                            position: "relative",
                            transformOrigin: "left center", // Keep the hover scaling aligned to the left
                          },
                          "& img:hover": {
                            transform: "scale(1.5)",
                          },
                        }}
                      >
                        <img
                          src={examtamplete?.question_url}
                          alt="Uploaded preview"
                        />
                      </Typography>
                    )}
                  </Typography>
                </div>
              </Grid>
            </div>

            {renderQuestionOptions(
              examtamplete?.Question_type_name,
              options || [],
              optionsurl || []
            )}
          </>
        )}
        <div className="navigation-wrapper"></div>
        <div className="navigation-buttons">
          <div style={{ display: "flex" }}>
            <Button sx={{ textTransform: "capitalize", fontSize: "14px" }}>
              <SvgIcon
                component={Deleteicon}
                inheritViewBox
                sx={{ marginRight: "5px" }}
              />
              Clear
            </Button>
          </div>

          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Button
              className="buttonnamenextsreview"
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/masters/questions"); // Replace with your target route
              }}
            >
              Back to question page
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineExamTamplete;

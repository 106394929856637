import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IExamAnswerPractice } from "../../../Pages/Exam participation/IExam_participation";

export const ListExamAnswerPracticeSlice = createSlice({
  name: "ListExamAnswerPracticeSlice",
  initialState: {} as IReduxState<IExamAnswerPractice[]>,
  reducers: {
    listExamAnswerPractice: (state, _: PayloadAction<IExamAnswerPractice>) => {
      state.isLoading = true;
    },
    successlistExamAnswerPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistExamAnswerPractice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistExamAnswerPractice,
  listExamAnswerPractice,
  successlistExamAnswerPractice,
} = ListExamAnswerPracticeSlice.actions;

export default ListExamAnswerPracticeSlice.reducer;

import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";

import { PayloadAction } from "@reduxjs/toolkit";
import { IReViewExamlistIDPractice } from "../../../Pages/Exam/IExam";
import {
  failurelistReViewExamIDPractice,
  successlistReViewExamIDPractice,
} from "./ListReViewExamPracticeSlice";

// list exam id

const ListReViewExamIDPracticeApi = (
  action: PayloadAction<IReViewExamlistIDPractice>
) => {
  return api.get("/v1/api/exam/review", { params: action.payload });
};

export function* ListReViewExamIDPracticeCall(
  action: PayloadAction<IReViewExamlistIDPractice>
) {
  try {
    const response: AxiosResponse = yield call(
      ListReViewExamIDPracticeApi,
      action
    );
    yield put(successlistReViewExamIDPractice(response.data));
  } catch (error) {
    yield put(failurelistReViewExamIDPractice(error));
  }
}

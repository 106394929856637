import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureListWritingExamAISlice,
  successListWritingExamAISlice,
} from "./ListWriting_Exam_AI_Slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IWritingExamAI } from "../../../Pages/DashboardUser/IDashboardUser";

// list WRITING EXAM AI
const ListWritingExamAISliceApi = (action: PayloadAction<IWritingExamAI>) => {
  return api.post("/v1/api/exam/ai-reponse", action.payload);
};

export function* ListWritingExamAISliceCall(
  action: PayloadAction<IWritingExamAI>
) {
  try {
    const response: AxiosResponse = yield call(
      ListWritingExamAISliceApi,
      action
    );
    yield put(successListWritingExamAISlice(response.data));
  } catch (error) {
    yield put(failureListWritingExamAISlice(error));
  }
}

import { AxiosResponse } from "axios";
import { call, delay, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureaddPracticeExam,
  successaddPracticeExam,
} from "./AddPracticeExamSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IPracticeExam,
  IPracticeExamlistID,
} from "../../../Pages/PracticeExam/IPracticeExam";
import {
  failuredeletePracticeExam,
  successdeletePracticeExam,
} from "./DeletePracticeExamSlice";
import {
  failurelistPracticeExam,
  successlistPracticeExam,
} from "./ListPracticeExamSlice";
import {
  failureEditPracticeExam,
  successEditPracticeExam,
} from "./EditPracticeExamSlice";
import {
  failurelistInstructionPracticeExamID,
  successlistInstructionPracticeExamID,
} from "./ListinstructionPracticeExamIDSlice";
import { IAPIResponse } from "../../interfaces/IAPIResponse";
import {
  failurelistPracticeExamID,
  successlistPracticeExamID,
} from "./ListPracticeExamIDSlice";
import {
  IEvaluationPractice,
  IEvaluationPracticewritingExam,
} from "../../../Pages/Exam/IExam";
import {
  failurelistEvaluationPractice,
  successlistEvaluationPractice,
} from "./ListEvaluationPracticeSlice";
import {
  failurelistEvaluationPracticewritingExam,
  successlistEvaluationPracticewritingExam,
} from "./ListEvaluationPracticewritingExamSlice";

// list exam id

const ListPracticeExamIDApi = (action: PayloadAction<IPracticeExamlistID>) => {
  return api.get("/v1/api/exam/startTest", { params: action.payload });
};

export function* ListPracticeExamIDCall(
  action: PayloadAction<IPracticeExamlistID>
) {
  while (true) {
    try {
      const response: AxiosResponse<IAPIResponse<IPracticeExamlistID>> =
        yield call(ListPracticeExamIDApi, action);
      if (response.data.data) {
        yield put(successlistPracticeExamID(response.data));
        break;
      }
      yield delay(10000);
    } catch (error) {
      yield put(failurelistPracticeExamID(error));
    }
  }
}

// evaluation
const ListEvaluationPracticeApi = (
  action: PayloadAction<IEvaluationPractice>
) => {
  return api.get("/v1/api/exam/testevaluation", { params: action.payload });
};

export function* ListEvaluationPracticeCall(
  action: PayloadAction<IEvaluationPractice>
) {
  try {
    const response: AxiosResponse = yield call(
      ListEvaluationPracticeApi,
      action
    );
    yield put(successlistEvaluationPractice(response.data));
  } catch (error) {
    yield put(failurelistEvaluationPractice(error));
  }
}

// evaluation Writing exam
const ListEvaluationPracticewritingExamApi = (
  action: PayloadAction<IEvaluationPracticewritingExam>
) => {
  return api.post("/v1/api/exam/writing/evaluation", action.payload);
};

export function* ListEvaluationPracticewritingExamCall(
  action: PayloadAction<IEvaluationPracticewritingExam>
) {
  try {
    const response: AxiosResponse = yield call(
      ListEvaluationPracticewritingExamApi,
      action
    );
    yield put(successlistEvaluationPracticewritingExam(response.data));
  } catch (error) {
    yield put(failurelistEvaluationPracticewritingExam(error));
  }
}

// list instruction exam id
const ListInstructionPracticeExamIDApi = (
  action: PayloadAction<IPracticeExamlistID>
) => {
  return api.get("/v1/api/exam/testinstruction", { params: action.payload });
};

export function* ListInstructionPracticeExamIDCall(
  action: PayloadAction<IPracticeExamlistID>
) {
  try {
    const response: AxiosResponse = yield call(
      ListInstructionPracticeExamIDApi,
      action
    );
    yield put(successlistInstructionPracticeExamID(response.data));
  } catch (error) {
    yield put(failurelistInstructionPracticeExamID(error));
  }
}

// list
const ListPracticeExamApi = () => {
  return api.get("/v1/api/exam/");
};

export function* ListPracticeExamCall() {
  try {
    const response: AxiosResponse = yield call(ListPracticeExamApi);
    yield put(successlistPracticeExam(response.data));
  } catch (error) {
    yield put(failurelistPracticeExam(error));
  }
}

// add
const AddPracticeExamApi = (action: PayloadAction<IPracticeExam>) => {
  return api.post("/v1/api/exam/create", action.payload);
};

export function* AddPracticeExamCall(action: PayloadAction<IPracticeExam>) {
  try {
    const response: AxiosResponse = yield call(AddPracticeExamApi, action);
    yield put(successaddPracticeExam(response.data));
  } catch (error) {
    yield put(failureaddPracticeExam(error));
  }
}

// edit
const EditPracticeExamApi = (action: PayloadAction<IPracticeExam>) => {
  return api.put("/v1/api/exam/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditPracticeExamCall(action: PayloadAction<IPracticeExam>) {
  try {
    const response: AxiosResponse = yield call(EditPracticeExamApi, action);
    yield put(successEditPracticeExam(response.data));
  } catch (error) {
    yield put(failureEditPracticeExam(error));
  }
}

// delete
const DeletePracticeExamApi = (action: PayloadAction<number>) => {
  return api.delete("/v1/api/exam/delete", {
    params: { id: action.payload },
  });
};

export function* DeletePracticeExamCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeletePracticeExamApi, action);
    yield put(successdeletePracticeExam(response.data));
  } catch (error) {
    yield put(failuredeletePracticeExam(error));
  }
}

import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureListWritingExamAIPracticeSlice,
  successListWritingExamAIPracticeSlice,
} from "./ListWriting_Exam_AI_PracticeSlice";
import {
  failureListWritingExamPracticeSlice,
  successListWritingExamPracticeSlice,
} from "./ListWriting_Exam_PracticeSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IWritingExam } from "../../../Pages/DashboardUser/IDashboardUser";

// list WRITING EXAM AI
const ListWritingExamAIPracticeSliceApi = (
  action: PayloadAction<IWritingExam>
) => {
  return api.get("/v1/api/exam/writing/ai-reponse", { params: action.payload });
};

export function* ListWritingExamAIPracticeSliceCall(
  action: PayloadAction<IWritingExam>
) {
  try {
    const response: AxiosResponse = yield call(
      ListWritingExamAIPracticeSliceApi,
      action
    );
    yield put(successListWritingExamAIPracticeSlice(response.data));
  } catch (error) {
    yield put(failureListWritingExamAIPracticeSlice(error));
  }
}

// list  FOR WRITING
const ListWritingExamPracticeSliceApi = (
  action: PayloadAction<IWritingExam>
) => {
  return api.get("/v1/api/exam/writing/re-write", { params: action.payload });
};

export function* ListWritingExamPracticeSliceCall(
  action: PayloadAction<IWritingExam>
) {
  try {
    const response: AxiosResponse = yield call(
      ListWritingExamPracticeSliceApi,
      action
    );
    yield put(successListWritingExamPracticeSlice(response.data));
  } catch (error) {
    yield put(failureListWritingExamPracticeSlice(error));
  }
}

import { IMenuItem } from "./ISidnavbar";
import { IconButton, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as Dashboard } from "../../Assets/Images/Dashboard.svg";
import { ReactComponent as Settings } from "../../Assets/Images/Settings.svg";
import { ReactComponent as UserPermission } from "../../Assets/Images/UserPermission.svg";
import { ReactComponent as UserMaster } from "../../Assets/Images/UserMaster.svg";
import Avatars from "../Avatar/Avatars";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Exam } from "../../Assets/Images/Exam.svg";
import { ReactComponent as Menuicon } from "../../Assets/Images/Menu.svg";
import { ReactComponent as Instruction } from "../../Assets/Images/instruction.svg";
import { ReactComponent as Masters } from "../../Assets/Images/Masters.svg";
import { ReactComponent as QuestionType } from "../../Assets/Images/QuestionType.svg";
import { ReactComponent as question } from "../../Assets/Images/Questions.svg";
import { ReactComponent as ExamModel } from "../../Assets/Images/ExamModel.svg";
import { ReactComponent as options } from "../../Assets/Images/options.svg";
import { ReactComponent as Usericon } from "../../Assets/Images/Role.svg";
import { ReactComponent as Dashboardicon } from "../../Assets/Images/dashboard.svg";
import { ReactComponent as Cousres } from "../../Assets/Images/Courses.svg";
import { ReactComponent as Package } from "../../Assets/Images/PackageUser.svg";
import { ReactComponent as User } from "../../Assets/Images/Userpackage.svg";

// managment

/*    dummy menu     */
export const menu: { [key: string]: IMenuItem[] } = {
  Dashboard: [
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Dashboard",
      to: "dashboard",
      permissions: {
        add: true,
        view: true,
        show: true,
        edit: true,
        delete: true,
      },
    },

    {
      icon: <SvgIcon component={User} inheritViewBox />,
      title: "Users",
      to: "userprofile",
      permissions: {
        add: true,
        delete: true,
        edit: true,
        view: true,
        show: true,
      },
      items: [
        {
          icon: <SvgIcon component={Usericon} inheritViewBox />,
          title: "Student",
          to: "userprofile/student",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Masters} inheritViewBox />,
          title: "Staff",
          to: "userprofile/staff",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
      ],
    },

    {
      icon: <SvgIcon component={Masters} inheritViewBox />,
      title: "Masters",
      to: "masters",
      permissions: {
        add: true,
        delete: true,
        edit: true,
        view: true,
        show: true,
      },
      items: [
        {
          icon: <SvgIcon component={QuestionType} inheritViewBox />,
          title: "Question Type",
          to: "masters/questiontype",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={question} inheritViewBox />,
          title: "Questions",
          to: "masters/questions",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={ExamModel} inheritViewBox />,
          title: "Exam Model",
          to: "masters/exammodel",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Exam} inheritViewBox />,
          title: "Exam",
          to: "masters/exam",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Exam} inheritViewBox />,
          title: "Practice Exam",
          to: "masters/practiceexam",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Cousres} inheritViewBox />,
          title: "Courses",
          to: "masters/courses",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Package} inheritViewBox />,
          title: "Package",
          to: "masters/package",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
      ],
    },

    {
      icon: <SvgIcon component={Settings} inheritViewBox />,
      title: "Settings",
      to: "settings",
      permissions: {
        add: true,
        delete: true,
        edit: true,
        view: true,
        show: true,
      },
      items: [
        {
          icon: <SvgIcon component={Usericon} inheritViewBox />,
          title: "Role",
          to: "settings/role",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Menuicon} inheritViewBox />,
          title: "Menu",
          to: "settings/menu",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={UserPermission} inheritViewBox />,
          title: "User Permission",
          to: "settings/userPermission",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
      ],
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Instruction",
      to: "instruction",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Dashboarduser",
      to: "home",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Examhistory",
      to: "examhistory",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Exam Participation",
      to: "examparticipation",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Practice Exam",
      to: "practiceexam",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Submit Exam",
      to: "submitexam",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Submit practice Exam",
      to: "submitexampractice",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Feed Back",
      to: "feedback",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Feed Back Practice",
      to: "feedbackpractice",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Exam Result",
      to: "examresult",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Exam Result practice",
      to: "examresultpractice",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "ReView Exam ",
      to: "reviewexam",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "ReView Exam Practice ",
      to: "reviewexampractice",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },

    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Re Exam ",
      to: "reexam",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "instructionreExam",
      to: "instructionreExam",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "subscribedPages",
      to: "subscribedPages",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Total Exam",
      to: "totalexam",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Student Profile",
      to: "studentprofile",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Pending",
      to: "instructionpending",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "InstructionPendingPractice",
      to: "instructionpendingpractice",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },

    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Practice",
      to: "instructionpractice",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "PendingExam",
      to: "pendingexam",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Pendingexam Practice",
      to: "pendingexampractice",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Dashboardicon} inheritViewBox />,
      title: "Purchasedexam",
      to: "purchasedexam",
      permissions: {
        add: true,
        view: true,
        show: false,
        edit: true,
        delete: true,
      },
    },
  ],
};

export const testMenu: IMenuItem[] = [
  {
    title: "Dashboard",
    to: "dashboard",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Users",
    to: "userprofile",
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
      show: true,
    },
    items: [
      {
        title: "Student",
        to: "userprofile/student",
        permissions: {
          add: true,
          view: true,
          show: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Menu",
        to: "userprofile/staff",
        permissions: {
          add: true,
          view: true,
          show: true,
          edit: true,
          delete: true,
        },
      },
    ],
  },
  {
    title: "Exam",
    to: "masters/exam",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },

  {
    title: "Questions",
    to: "masters/questions",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Package",
    to: "masters/package",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Courses",
    to: "masters/courses",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Question Type",
    to: "masters/questiontype",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "ExamModel",
    to: "masters/exammodel",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "practiceexam",
    to: "masters/practiceexam",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },

  {
    title: "Role",
    to: "settings/role",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Menu",
    to: "settings/menu",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "User Permission",
    to: "settings/userPermission",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Dashboard",
    to: "instruction",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Dashboarduser",
    to: "home",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Dashboard",
    to: "examhistory",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Exam Participation",
    to: "examparticipation",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Practice Exam",
    to: "practiceexam",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Submit Exam",
    to: "submitexam",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Feed Back",
    to: "feedback",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Feed Back Practice",
    to: "feedbackpractice",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Exam Result",
    to: "examresult",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "ReView Exam ",
    to: "reviewexam",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "ReView Exam Practice ",
    to: "reviewexampractice",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Re Exam ",
    to: "reexam",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "instructionreExam",
    to: "instructionreExam",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },

  {
    title: "subscribedPages",
    to: "subscribedPages",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Total Exam",
    to: "totalexam",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Student Profile",
    to: "studentprofile",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Pending",
    to: "instructionpending",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "practice",
    to: "instructionpractice",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "InstructionPendingPractice",
    to: "instructionpendingpractice",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "PendingExam",
    to: "pendingexam",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Pendingexam Practice",
    to: "pendingexampractice",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
];

export const skipedMenu = [""];

export const notificationMenu = [
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
];

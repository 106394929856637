import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureaddFeedBackPractice,
  successaddFeedBackPractice,
} from "./AddFeedBackPracticeSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IFeedBackPractice } from "../../../Pages/FeedBackPractice/IFeedBackPractice";

// add
const AddFeedBackPracticeApi = (action: PayloadAction<IFeedBackPractice>) => {
  return api.put("/v1/api/exam/testfeedback", action.payload);
};

export function* AddFeedBackPracticeCall(
  action: PayloadAction<IFeedBackPractice>
) {
  try {
    const response: AxiosResponse = yield call(AddFeedBackPracticeApi, action);
    yield put(successaddFeedBackPractice(response.data));
  } catch (error) {
    yield put(failureaddFeedBackPractice(error));
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IWritingExam } from "../../../Pages/DashboardUser/IDashboardUser";

export const ListWritingExamPracticeSlice = createSlice({
  name: "ListWritingExamPracticeSlice",
  initialState: {} as IReduxState<IWritingExam[]>,
  reducers: {
    listListWritingExamPracticeSlice: (
      state,
      _: PayloadAction<IWritingExam>
    ) => {
      state.isLoading = true;
    },
    successListWritingExamPracticeSlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureListWritingExamPracticeSlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearListWritingExamPracticeSlice: (state) => {
      state.data = {};
    },
  },
});

export const {
  listListWritingExamPracticeSlice,
  successListWritingExamPracticeSlice,
  failureListWritingExamPracticeSlice,
  clearListWritingExamPracticeSlice,
} = ListWritingExamPracticeSlice.actions;

export default ListWritingExamPracticeSlice.reducer;

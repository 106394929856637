import { takeEvery, takeLatest } from "redux-saga/effects";
import { TestCall } from "../Slices/TestSlicers/TestApi";
import { EditUserPermissionCall } from "../Slices/UserPermission/UserPermissionAPI";
import { ListUserPermissionRowsCall } from "../Slices/UserPermission/UserPermissionAPI";
import { ForgotPasswordCall, GoogleLoginCall, LoginCall } from "../Slices/LoginSlicer/LoginApi";
import { ResetPasswordCall } from "../Slices/ResetPassword/ResetPasswordAPI";
import { SideMenuPermissionCall } from "../Slices/SideMenuPermission/ApiSideMenuPermission";
import {
  DashboardCardCall,
  
} from "../Slices/Dashboard/ApiDashboard";
import { AddExamHistoryCall, DeleteExamHistoryCall, EditExamHistoryCall, ListExamHistoryCall, ListExamHistoryReWritingSliceCall, ListMockExamHistoryCall } from "../Slices/ExamHistory/ApiExamHistory";
import { AddOptionsCall, DeleteOptionsCall, EditOptionsCall, ListOptionsCall } from "../Slices/Options/ApiOptions";
import { RegistrationCall } from "../Slices/Registration/RegistrationApi";
import { AddMenuCall, DeleteMenuCall, EditMenuCall, ListMenuCall } from "../Slices/Menu/ApiMenu";
import { AddInstructionCall, DeleteInstructionCall, EditInstructionCall, ListInstructionCall } from "../Slices/Instruction/ApiInstruction";
import { AddExamAnswerCall, DeleteExamAnswerCall, EditExamAnswerCall, ListExamAnswerCall, ListExamsubmitCall } from "../Slices/ExamAnswer/ApiExamAnswerSlice";
import { AddQuestionTypeCall, DeleteQuestionTypeCall, EditQuestionTypeCall, ListQuestionTypeCall } from "../Slices/QuestionType/ApiQuestionType";
import { AddExamModelCall, DeleteExamModelCall, EditExamModelCall, ListExamModelCall } from "../Slices/ExamModel/ApiExamModel";
import { AddCloseSectionCall, AddExamCall, DeleteExamCall, EditExamCall, ListEvaluationCall, ListExamCall, ListExamIDCall, ListInstructionExamIDCall } from "../Slices/Exam/ApiExam";
import { AddStudentExamCall, EditStudentExamCall, ListStudentExamCall } from "../Slices/StudentExam/ApiStudentExam";
import { AddFeedBackCall, DeleteFeedBackCall, EditFeedBackCall, ListFeedBackCall } from "../Slices/FeedBack/ApiFeedBack";
import { AddPackageCall, DeletePackageCall, EditPackageCall, ListPackageCall } from "../Slices/Package/ApiPackage";
import { AddUserCall, DeleteUserCall, EditUserCall, ListRegionCall, ListUserCall, ListYearGroupCall } from "../Slices/User/ApiUser";
import { AddQuestionsCall, DeleteQuestionsCall, EditQuestionsCall, ListQuestionsCall } from "../Slices/Questions/ApiQuestions";
import { ListReExamIDCall } from "../Slices/ReExam/ApiReExam";
import { ListReViewExamIDCall } from "../Slices/ReViewExam/ApiReViewExam";
import { ChatbotCall } from "../Slices/Chatbot/ChatbotApi";
import { AddRoleCall, DeleteRoleCall, EditRoleCall, ListRoleCall } from "../Slices/Role/ApiRole";
import { ListUserExamListCall, ListUserPackageCall, ListUserPackageDetailsCall, PendingExamListApiCall } from "../Slices/UserPackage/ApiPackage";
import { ListMockExamCall } from "../Slices/MockExam/ApiMockExam";
import { PendingExamCall } from "../Slices/PendingExam/ApiReExam";
import { AddCoursesCall, DeleteCoursesCall, EditCoursesCall, ListCoursesCall } from "../Slices/Courses/ApiPackage";
import { AddUserAdminCall, DeleteUserAdminCall, EditUserAdminCall, ListUserAdminCall } from "../Slices/UserAdmin/ApiUser";

import {AddPracticeExamCall, DeletePracticeExamCall, EditPracticeExamCall, ListEvaluationPracticeCall, ListEvaluationPracticewritingExamCall, ListInstructionPracticeExamIDCall, ListPracticeExamCall, ListPracticeExamIDCall, } from "../Slices/PracticeExam/ApiPracticeExam"
import { ListPracticeTestCall } from "../Slices/PracticeTest/ApiPracticeExam";
import { PendingPracticeExamCall } from "../Slices/PendingPracticeExam/ApiPendingPracticeExam";
import { AddStudentExamPracticeCall, EditStudentExamPracticeCall, ListStudentExamPracticeCall } from "../Slices/StudentExamPractice/ApiStudentExamPractice";
import { AddExamAnswerPracticeCall, ListExamAnswerPracticeCall, ListExamsubmitPracticeCall } from "../Slices/ExamAnswerPractice/ApiExamAnswerSlice";
import { ListReViewExamIDPracticeCall } from "../Slices/ReViewExamPractice/ApiReViewExamPractice";
import { AddFeedBackPracticeCall } from "../Slices/FeedBackPractice/ApiFeedBackPractice";
import { ListWritingExamAIPracticeSliceCall, ListWritingExamPracticeSliceCall } from "../Slices/Writing_Exam_Practice/ApiWritingExam";
import { ListReadingPromptCall } from "../Slices/ReadingPrompt/ApiReadingPrompt";
import { AddOpenSectionCall } from "../Slices/OpenSection/ApiOpenSection";
import {  ListWritingExamAISliceCall } from "../Slices/Writing_Exam/ApiWritingExamAI";
import { DashboardDataCardCall } from "../Slices/DashboardData/ApiDashboardData";
import { DashboardChartCall } from "../Slices/DashboardChart/ApiDashboardChart";

export default function* rootSage() {
  yield takeLatest("Test/testCall", TestCall);
  // yield takeLatest("pattern",worker)

  // Dashboard
  yield takeLatest("DashboardCard/dashboardCard", DashboardCardCall);
  yield takeLatest("DashboardChart/dashboardChart", DashboardChartCall);

    // DashboardData
    yield takeLatest("DashboardDataCard/dashboardDataCard", DashboardDataCardCall);
  // User Permission
  yield takeLatest(
    "EditUserPermission/editUserPermission",
    EditUserPermissionCall
  );
  yield takeLatest(
    "UserPermissionRows/userPermissionRowsList",
    ListUserPermissionRowsCall
  );
  // SideMenu Permission
  yield takeLatest(
    "SideMenuPermission/listSideMenuPermission",
    SideMenuPermissionCall
  );

  // Registration
  yield takeLatest("RegistrationSlicer/registrationStart", RegistrationCall);

  // Login
  yield takeLatest("GoogleLoginSlicer/GoogleloginStart", GoogleLoginCall);
  yield takeLatest("LoginSlicer/loginStart", LoginCall);
  yield takeLatest("ForgotPassword/ForgotPasswordStart", ForgotPasswordCall);
  // Reset Password
  yield takeLatest("ResetPassword/resetPasswordStart", ResetPasswordCall);

  // Menu
  yield takeLatest("ListMenuSlice/listMenu", ListMenuCall);
  yield takeEvery("AddMenuSlice/addMenu", AddMenuCall);
  yield takeEvery("EditMenuSlice/EditMenu", EditMenuCall);
  yield takeEvery("DeleteMenu/deleteMenu", DeleteMenuCall);
  // QuestionType
  yield takeLatest("ListQuestionTypeSlice/listQuestionType", ListQuestionTypeCall);
  yield takeEvery("AddQuestionTypeSlice/addQuestionType", AddQuestionTypeCall);
  yield takeEvery("EditQuestionTypeSlice/EditQuestionType", EditQuestionTypeCall);
  yield takeEvery("DeleteQuestionType/deleteQuestionType", DeleteQuestionTypeCall);
  
   // ExamHistory
   yield takeLatest("ListMockExamHistorySlice/listMockExamHistory", ListMockExamHistoryCall);
   yield takeLatest("ListExamHistoryReWritingSlice/listExamHistoryReWritingSlice", ListExamHistoryReWritingSliceCall);
   yield takeLatest("ListExamHistorySlice/listExamHistory", ListExamHistoryCall);
   yield takeEvery("AddExamHistorySlice/addExamHistory", AddExamHistoryCall);
   yield takeEvery("EditExamHistorySlice/EditExamHistory", EditExamHistoryCall);
   yield takeEvery("DeleteExamHistory/deleteExamHistory", DeleteExamHistoryCall);
    // Questions
 yield takeLatest("ListQuestionsSlice/listQuestions", ListQuestionsCall);
 yield takeEvery("AddQuestionsSlice/addQuestions", AddQuestionsCall);
 yield takeEvery("EditQuestionsSlice/EditQuestions", EditQuestionsCall);
 yield takeEvery("DeleteQuestions/deleteQuestions", DeleteQuestionsCall);
 

  // Options
  yield takeLatest("ListOptionsSlice/listOptions", ListOptionsCall);
  yield takeEvery("AddOptionsSlice/addOptions", AddOptionsCall);
  yield takeEvery("EditOptionsSlice/EditOptions", EditOptionsCall);
  yield takeEvery("DeleteOptions/deleteOptions", DeleteOptionsCall);
  

  // ExamModel
  yield takeLatest("ListExamModelSlice/listExamModel", ListExamModelCall);
  yield takeEvery("AddExamModelSlice/addExamModel", AddExamModelCall);
  yield takeEvery("EditExamModelSlice/EditExamModel", EditExamModelCall);
  yield takeEvery("DeleteExamModel/deleteExamModel", DeleteExamModelCall);
  
    // Exam
    yield takeLatest("ListEvaluationSlice/listEvaluation", ListEvaluationCall);
    yield takeLatest("ListExamsubmitSlice/listExamsubmit", ListExamsubmitCall);
    yield takeLatest("ListExamIDSlice/listExamID", ListExamIDCall);
    yield takeLatest("ListInstructionExamSlice/listInstructionExamID", ListInstructionExamIDCall);
  yield takeLatest("ListReExamIDSlice/listReExamID", ListReExamIDCall);
  yield takeLatest("PendingExamSlice/pendingExam", PendingExamCall);
  yield takeLatest("ListReViewExamIDSlice/listReViewExamID", ListReViewExamIDCall);
  yield takeLatest("ListExamSlice/listExam", ListExamCall);
  yield takeEvery("AddExamSlice/addExam", AddExamCall);
  yield takeEvery("AddCloseSectionSlice/addCloseSection",AddCloseSectionCall)
  yield takeEvery("EditExamSlice/EditExam", EditExamCall);
  yield takeEvery("DeleteExam/deleteExam", DeleteExamCall);
  yield takeEvery("AddOpenSectionSlice/addOpenSection",AddOpenSectionCall)

   // Practice Exam
   yield takeLatest("ListReViewExamIDPracticeSlice/listReViewExamIDPractice", ListReViewExamIDPracticeCall);
   yield takeLatest("ListEvaluationPracticeSlice/listEvaluationPractice", ListEvaluationPracticeCall);
   yield takeLatest("ListEvaluationPracticewritingExamSlice/listEvaluationPracticewritingExam", ListEvaluationPracticewritingExamCall);
   yield takeLatest("ListExamsubmitPracticeSlice/listExamsubmitPractice", ListExamsubmitPracticeCall);
   yield takeLatest("PendingPracticeExamSlice/pendingPracticeExam", PendingPracticeExamCall);
   yield takeLatest("ListPracticeExamIDSlice/listPracticeExamID", ListPracticeExamIDCall);
 yield takeLatest("ListInstructionPracticeExamSlice/listInstructionPracticeExamID", ListInstructionPracticeExamIDCall);
 yield takeLatest("ListPracticeExamSlice/listPracticeExam", ListPracticeExamCall);
 yield takeEvery("AddPracticeExamSlice/addPracticeExam", AddPracticeExamCall);
 yield takeEvery("EditPracticeExamSlice/EditPracticeExam", EditPracticeExamCall);
 yield takeEvery("DeletePracticeExam/deletePracticeExam", DeletePracticeExamCall);
 
//  Practice test Exam 
 yield takeLatest("ListPracticeTestSlice/listPracticeTest", ListPracticeTestCall);

  
  // Student Exam 
  yield takeLatest("ListStudentExamSlice/listStudentExam", ListStudentExamCall);
  yield takeEvery("AddStudentExamSlice/addStudentExam", AddStudentExamCall);
  yield takeEvery("EditStudentExamSlice/EditStudentExam", EditStudentExamCall);

     // StudentPractice Exam 
  yield takeLatest("ListStudentExamPracticeSlice/listStudentExamPractice", ListStudentExamPracticeCall);
  yield takeEvery("AddStudentExamPracticeSlice/addStudentExamPractice", AddStudentExamPracticeCall);
  yield takeEvery("EditStudentExamPracticeSlice/EditStudentExamPractice", EditStudentExamPracticeCall);


  // ExamAnswer Practice
  yield takeLatest("ListExamAnswerPracticeSlice/listExamAnswerPractice", ListExamAnswerPracticeCall);
  yield takeEvery("AddExamAnswerPracticeSlice/addExamAnswerPractice", AddExamAnswerPracticeCall);
 
     // ExamAnswer
     yield takeLatest("ListExamAnswerSlice/listExamAnswer", ListExamAnswerCall);
     yield takeEvery("AddExamAnswerSlice/addExamAnswer", AddExamAnswerCall);
     yield takeEvery("EditExamAnswerSlice/EditExamAnswer", EditExamAnswerCall);
     yield takeEvery("DeleteExamAnswer/deleteExamAnswer", DeleteExamAnswerCall);
    
 // Instruction
 yield takeLatest("ListInstructionSlice/listInstruction", ListInstructionCall);
 yield takeEvery("AddInstructionSlice/addInstruction", AddInstructionCall);
 yield takeEvery("EditInstructionSlice/EditInstruction", EditInstructionCall);
 yield takeEvery("DeleteInstruction/deleteInstruction", DeleteInstructionCall);

     // FeedBack
     yield takeLatest("ListFeedBackSlice/listFeedBack", ListFeedBackCall);
     yield takeEvery("AddFeedBackSlice/addFeedBack", AddFeedBackCall);
     yield takeEvery("EditFeedBackSlice/EditFeedBack", EditFeedBackCall);
     yield takeEvery("DeleteFeedBack/deleteFeedBack", DeleteFeedBackCall);
     yield takeEvery("AddFeedBackPracticeSlice/addFeedBackPractice", AddFeedBackPracticeCall);

       // Package
       yield takeLatest("ListPackageSlice/listPackage", ListPackageCall);
       yield takeEvery("AddPackageSlice/addPackage", AddPackageCall);
       yield takeEvery("EditPackageSlice/EditPackage", EditPackageCall);
       yield takeEvery("DeletePackage/deletePackage", DeletePackageCall);
    // Courses
    yield takeLatest("ListCoursesSlice/listCourses", ListCoursesCall);
    yield takeEvery("AddCoursesSlice/addCourses", AddCoursesCall);
    yield takeEvery("EditCoursesSlice/EditCourses", EditCoursesCall);
    yield takeEvery("DeleteCourses/deleteCourses", DeleteCoursesCall);

     // User Admin
     yield takeLatest("ListUserAdminSlice/listUserAdmin", ListUserAdminCall);
     yield takeEvery("AddUserAdminSlice/addUserAdmin", AddUserAdminCall);
     yield takeEvery("EditUserAdminSlice/EditUserAdmin", EditUserAdminCall);
     yield takeEvery("DeleteUserAdmin/deleteUserAdmin", DeleteUserAdminCall);



        // User
        yield takeLatest("ListUserSlice/listUser", ListUserCall);
        yield takeEvery("AddUserSlice/addUser", AddUserCall);
        yield takeEvery("EditUserSlice/EditUser", EditUserCall);
        yield takeEvery("DeleteUser/deleteUser", DeleteUserCall);
      //  list user 
      yield takeLatest("ListYearGroupSlice/listYearGroup", ListYearGroupCall);
      yield takeLatest("ListRegionSlice/listRegion", ListRegionCall);


      //  user package 
      yield takeLatest("ListUserPackageSlice/listUserPackage", ListUserPackageCall);
      yield takeLatest("ListUserPackageDetailsSlice/listUserPackageDetails", ListUserPackageDetailsCall);
      yield takeLatest("ListUserExamListSlice/listUserExamList", ListUserExamListCall);
      yield takeLatest("PendingExamList/pendingExamList", PendingExamListApiCall);

      //  chatbot
      yield takeEvery("ChatbotSlicer/chatbotStart", ChatbotCall);
      // Mock Exam 
      yield takeLatest("MockExamSlice/listMockExam", ListMockExamCall);

      // Reading prompt
      yield takeLatest("ReadingPromptSlice/listReadingPrompt", ListReadingPromptCall);

      // Writing exam 
      yield takeLatest("ListWritingExamAISlice/listListWritingExamAISlice",ListWritingExamAISliceCall)
      yield takeLatest("ListWritingExamPracticeSlice/listListWritingExamPracticeSlice",ListWritingExamPracticeSliceCall )
     yield takeLatest("ListWritingExamAIPracticeSlice/listListWritingExamAIPracticeSlice",ListWritingExamAIPracticeSliceCall)
               // Role
               yield takeLatest("ListRoleSlice/listRole", ListRoleCall);
               yield takeEvery("AddRoleSlice/addRole", AddRoleCall);
               yield takeEvery("EditRoleSlice/EditRole", EditRoleCall);
               yield takeEvery("DeleteRole/deleteRole", DeleteRoleCall);

}

import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStartPracticeExam } from "../../../Pages/Instruction/IInstruction";

export const ListPracticeTestSlice = createSlice({
  name: "ListPracticeTestSlice",
  initialState: {} as IReduxState<IStartPracticeExam>,
  reducers: {
    listPracticeTest: (state) => {
      state.isLoading = true;
    },
    successlistPracticeTest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failurelistPracticeTest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failurelistPracticeTest,
  listPracticeTest,
  successlistPracticeTest,
} = ListPracticeTestSlice.actions;

export default ListPracticeTestSlice.reducer;

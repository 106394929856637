import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import { failureDashboardChart, successDashboardChart } from "./DashboardChart";

// cards data
const DashboardChartApi = () => {
  return api.get("/v1/api/dashboard/dashboard-data");
};

export function* DashboardChartCall() {
  try {
    const response: AxiosResponse = yield call(DashboardChartApi);
    yield put(successDashboardChart(response.data));
  } catch (error) {
    yield put(failureDashboardChart(error));
  }
}
